import styled from "styled-components/macro";

export const Banner = styled.div`
  background: ${props => 
    props.bg
      ? `url(${props.bg})`
      : props.theme.colors.primary.base
  };
  display: flex;
  flex-direction: column;
  height: ${props => props.height}px;
  justify-content: space-between;
  padding: 55px 60px;
  position: relative;
`;

export const BannerLogo = styled.img`
  --height: 70px;
  background-color: ${props =>
    !props.src
      ? `${props.theme.colors.bitterLemon}`
      : ''
  };
  height: var(--height);
  width: calc(var(--height) / 3 * 4);
`

export const BannerText = styled.h3`
  color: ${props => props.theme.colors.white};
  width: 55%;
`