import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {MODAL_CLOSE, MODAL_OPEN} from 'redux/actions';
// import { ADD_WORKOUT } from 'redux/actions/workouts';
import useFileUpload from 'customHooks/useFileUpload';

import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import ProText from 'ui/typography/proText';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ContentWrapper,
  Icon,
  Head,
  Form,
  BtnContainer,
  Texts,
  InputFile,
  Label,
  IconUplaod,
  ButtonsWrapper,
} from '../NotesModal/style';
import {
  ErrorMsg,
  Text,
  UploadWrapper,
} from './style';

import { ModalGlobalContainer } from '../Modal.style';
import Modal, { stopEvent } from '../Modal';
import ModalErrors from "../ModalErrors";
import SuccessModal from "../SuccessModal";

const modalId = 'WorkoutModal';

const WorkoutModal = ({
  course,
  domainId,
  // addWorkout,
  onClose,
  acceptedFiles,
  format,
  maxFileSize,
  openModalError,
  openSuccessModal
}) => {
  const {
    acquiredFile,
    hasError: fileError,
    readFile,
    resetFile,
    uploadFile,
    uploadedFile,
  } = useFileUpload({ domainId });

  useEffect(() => {
    if (fileError) {
      // addWorkout();
      //closeAndReset();
      openModalError({
        errorText:
            "Si è verificato errore imprevisto. Ci scusiamo per il disagio"
      });
    } else {
      if (uploadedFile) {
        // addWorkout();
        //closeAndReset();

        if(uploadedFile.status === 200 && !!uploadedFile.success ) {
          openSuccessModal({
            description: "Caricamento effettuato con successo.",
          });
        } else {
          openModalError({
            errorText:
              "Si è verificato errore imprevisto. Ci scusiamo per il disagio"
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile, fileError]);


  const onSaveWorkout = () => {
    acquiredFile && uploadFile({
      endpoint:
        `learner_upload?courseItemId=${course.id}&lang=it&upload_type=${course.uploadType}`,
      withUserId: false,
    });
  };

  const closeAndReset = () => {
    resetFile();
    onClose();
  };

  return (
    <Modal id={modalId} onClose={closeAndReset}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="auto"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={closeAndReset} />
              <Head>
                <Texts>
                  <Title>
                    <ProDisplay type="modalTitle">
                      {course.uploadType === 'ASK_THE_EXPERT'
                        ? 'Ask The Expert'
                        : 'Practice'
                      }
                    </ProDisplay>
                  </Title>
                </Texts>
              </Head>
              <Text>
                <ProDisplay>
                  Carica il tuo documento compilato
                </ProDisplay>
              </Text>
              <Form minHeight="auto">
                {Boolean(acquiredFile?.name) && acquiredFile?.name?.indexOf('.', acquiredFile?.name?.indexOf('.') + 1) !== -1 &&
                  <ErrorMsg>
                    <ProText type="label" configuration={{ lineHeight: 1.56 }}>
                      Il nome del file da caricare non deve contenere altri punti oltre quello dell'estensione
                    </ProText>
                  </ErrorMsg>}
                {(acquiredFile?.size > maxFileSize) ? (
                  <ErrorMsg>
                    <ProText type="label" configuration={{ lineHeight: 1.56 }}>
                      Le estensioni permesse sono: {acceptedFiles.join(', ')}
                    </ProText>
                    <ProText type="label" configuration={{ lineHeight: 1.56 }}>
                      La dimensione massima permessa è: {maxFileSize / 1024 / 1024}MB
                    </ProText>
                  </ErrorMsg>
                ) : null}
                <UploadWrapper>
                  <Label htmlFor="uploadFile">
                    <IconUplaod className="icon-icona-allega" />
                    <ProText
                      type="label"
                      configuration={{
                        lineHeight: '1.14',
                        color: 'primary',
                        colorType: 'base'
                      }}
                    >
                      {acquiredFile?.size ? acquiredFile.name : 'Allega file'}
                    </ProText>
                  </Label>

                  <InputFile
                    type="file"
                    id="uploadFile"
                    accept={acceptedFiles.join(',')}
                    onChange={(event) => {
                      readFile({
                        file: event.currentTarget?.files[0],
                        format
                      });
                    }}
                  />
                </UploadWrapper>
              </Form>
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    text="Salva"
                    type="PRIMARY"
                    onClick={onSaveWorkout}
                    disabled={fileError || !acquiredFile?.size || acquiredFile.size > maxFileSize || (Boolean(acquiredFile?.name) && acquiredFile?.name?.indexOf('.', acquiredFile?.name?.indexOf('.') + 1) !== -1)}
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

WorkoutModal.FORMAT = {
  BINARY: 'BINARY',
  BASE64: 'BASE64'
};

WorkoutModal.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    title: PropTypes.string,
    uploadType: PropTypes.oneOf([
      'ASK_THE_EXPERT',
      'PRACTICE',
    ]),
  }),

  // HOC (connect, state)
  domainId: PropTypes.number,

  // HOC (connect, dispatch)
  // addWorkout: PropTypes.func,
  onClose: PropTypes.func.isRequired,

  // useFileUploader
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  format: PropTypes.string,
  maxFileSize: PropTypes.number
};

WorkoutModal.defaultProps = {
  acceptedFiles: ['.doc', '.docx', '.jpg', '.pdf', '.png', '.ppt', '.pptx', '.xls', '.xlsx'],
  format: WorkoutModal.FORMAT.BINARY,
  maxFileSize: 10 * 1024 * 1024, // 10 MB
};

WorkoutModal.id = modalId;
export const WorkoutModalId = WorkoutModal.id;

export default connect(
  state => {
    const { id: domainId } = state.domain;
    return {
      domainId,
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    openModalError: payload => dispatch({ type: MODAL_OPEN, id: ModalErrors.id, payload }),
    openSuccessModal: payload => dispatch({type: MODAL_OPEN, id: SuccessModal.id, payload})
    // addWorkout: payload => dispatch({ type: ADD_WORKOUT._REQUEST, payload }),
  })
)(WorkoutModal);
