import moment from 'moment';
import {
  apiCall,
  baseUrl,
  extendUrl,
  authPrefix,
  internalPrefix,
  bePrefix,
  clientId,
} from './config';
import {
  setAgreementsMock,
  shouldUseMock,
  signupCreateCartMock,
  signupGetLatestCartMock,
  getLandingProductsMock,
  signupManagerInfoMock,
  signupManagerPaymentInfoMock,
  getSectorMock,
  getWalletStatusMock,
  getProductDetailsMock,
  confirmUserMock,
  resendConfimCodeMock,
  cartConfirmMock,
  signupSuperManagerInfoMock,
  setDiscountMock, orderPreviewZuoraMock,
} from './mock';

const getWallet = ({ timestamp, userId }) =>
  new Promise(res => {
    if (shouldUseMock()) {
      const response = getWalletStatusMock();
      const { data: { last_updated } = {}, status } = response;
      if (status?.toString() === '200' || status?.toString() === '201') {
        if (Number(timestamp) < Number(moment(last_updated).valueOf())) {
          res(true);
        } else {
          res(false);
        }
      } else {
        res(false);
      }
    } else {
      apiCall(`${baseUrl}${extendUrl}resource/wallet/status`, 'GET', null, {
        isDrupal: true,
        isPrivate: true,
        userId,
      }).then((response = {}) => {
        const { data: { last_updated } = {}, status } = response;
        if (status?.toString() === '200' || status?.toString() === '201') {
          if (Number(timestamp) < Number(moment(last_updated).valueOf())) {
            res(true);
          } else {
            res(false);
          }
        } else {
          res(false);
        }
      });
    }
  });

export default {
  getSector: () => {
    if (shouldUseMock()) {
      return getSectorMock();
    }
    return apiCall(`${baseUrl}${extendUrl}settori`, 'GET', null, {
      isDrupal: true,
    });
  },
  setAgreements: body => {
    if (shouldUseMock()) {
      return setAgreementsMock();
    }
    return apiCall(`${bePrefix}/personaldatacceptance`, 'POST', {
      ...body,
      clientId,
    });
  },
  signupCreateCart: ({ userId, cartPrepared }) => {
    if (shouldUseMock()) {
      return signupCreateCartMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}resource/cart`,
      'POST',
      cartPrepared,
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  signupGetLatestCart: ({ userId }) => {
    if (shouldUseMock()) {
      return signupGetLatestCartMock();
    }
    return apiCall(`${baseUrl}${extendUrl}/cart/latest`, 'GET', null, {
      isDrupal: true,
      isPrivate: true,
      userId,
    });
  },
  getLandingProducts: () => {
    if (shouldUseMock()) {
      return getLandingProductsMock();
    }
    return apiCall(`${baseUrl}${extendUrl}landing_products`, 'GET', null, {
      isDrupal: true,
    });
  },

  getProductDetails: ({ id }) => {
    if (shouldUseMock(id)) {
      return getProductDetailsMock();
    }
    return apiCall(`${baseUrl}product?id=${id}`, 'GET', null, {
      isDrupal: true,
    });
  },
  signupManagerInfo: (body = {}) => {
    if (shouldUseMock()) {
      return signupManagerInfoMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signup/manager/firststep${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  signupManagerPaymentInfo: (body = {}) => {
    if (shouldUseMock()) {
      return signupManagerPaymentInfoMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}signup/manager/finalstep${internalPrefix}`,
      'POST',
      body,
      { encryptBody: true }
    );
  },
  getWalletStatus: ({ timestamp, userId }) =>
    new Promise(resolve => {
      let counter = 0;
      getWallet({ timestamp, userId }).then(status => {
        if (!status) {
          const interval = setInterval(() => {
            if (counter < 3) {
              getWallet({ timestamp, userId }).then(data => {
                if (data) {
                  resolve(true);
                  clearInterval(interval);
                }
              });
            } else if (counter >= 3) {
              resolve(false);
              clearInterval(interval);
            }
            counter += 1;
          }, 5000);
        } else {
          resolve(true);
        }
      });
    }),
  confirmUser: params => {
    if (shouldUseMock()) {
      return confirmUserMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}confirmuser${internalPrefix}`,
      'POST',
      params,
      { withXApiKey: true, encryptBody: true }
    );
  },
  resendConfimCode: params => {
    if (shouldUseMock()) {
      return resendConfimCodeMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}resendconfirmationcode${internalPrefix}`,
      'POST',
      params,
      { withXApiKey: true, encryptBody: true }
    );
  },
  signupConfirmCart: ({ userId, uuid }) => {
    if (shouldUseMock()) {
      return cartConfirmMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}resource/cart/${uuid}/payment`,
      'POST',
      {},
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  signupSuperManagerInfo: (body = {}) => {
    if (shouldUseMock()) {
      return signupSuperManagerInfoMock();
    }
    return apiCall(
      `${authPrefix}${baseUrl}${extendUrl}signup/supermanager${internalPrefix}`,
      'POST',
      body,
      { withXApiKey: true, encryptBody: true }
    );
  },
  signupSuperManagerPaymentInfo: (body = {}) => {
    if (shouldUseMock()) {
      return signupManagerPaymentInfoMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}signup/supermanager/finalstep${internalPrefix}`,
      'POST',
      body,
      {
        encryptBody: true,
      }
    );
  },
  setDiscount: code => {
    if (shouldUseMock()) {
      return setDiscountMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}coupon_detail?code=${code}`,
      'GET',
      null,
      { isDrupal: true }
    );
  },
  orderPreviewZuora: (body) => {
    if (shouldUseMock()) {
      return orderPreviewZuoraMock();
    }
    return apiCall('/ecommerce/api/frontend/secure/order/preview', 'POST', body, { encryptBody: true });
  },
  orderDraftSuperManager: (body) => {
    if (shouldUseMock()) {
      return orderPreviewZuoraMock();
    }
    return apiCall('/ecommerce/api/frontend/secure/order/draft', 'POST', body, { encryptBody: true });
  },
  retrieveDraftOrderZuora: ({ id }) => {
    if (shouldUseMock()) {
      return orderPreviewZuoraMock();
    }
    return apiCall(`/ecommerce/api/frontend/order/preview/${id}`, 'GET');
  },
  submitOrderZuora: (body) => {
    if (shouldUseMock()) {
      return orderPreviewZuoraMock();
    }
    return apiCall('/ecommerce/api/frontend/secure/order/submit', 'POST', body, { encryptBody: true });
  },
};
