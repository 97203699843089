import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import routes from 'routes';

import MainContainer from '../../../style';

import {
  NavWrapper,
  Icon,
  IconViews,
  Title,
  IntestationWrapper,
  Text,
  ContentWrapper,
  Content,
  ListContainer,
  ListNumber,
  ResultContainer,
  Number
} from './style';

import { Spinner } from 'ui/components';
import ProDisplay from 'ui/typography/proDisplay';

import { Container } from 'ui/gridSystem';
import { VIEWS_GET, POSITIVE_GET } from 'redux/actions/teams';
import { ViewsInterface, PositivesInterface } from 'interface/teams';
import ProText from 'ui/typography/proText';

const AllContent = ({ pushUrl, views, getViews, getPositive, positive }) => {
  const currentPath = window.location.pathname;
  let titolo = '';
  let text = '';
  let icon = '';
  let content = [];

  switch (currentPath) {
    case '/teams-activity/all-content/mostpopular':
      titolo = 'Contenuti più apprezzati';
      text = 'Like';
      icon = 'icon-star';
      content = (positive || []).map(item => ({
        id: item.idCourseType,
        title: item.title,
        count: item.positive
      }));
      break;
    case '/teams-activity/all-content/mostviewed':
    default:
      titolo = 'I più visti';
      text = 'Visualizzazioni';
      icon = 'icon-visualizzazioni';
      content = (views || []).map(item => ({
        id: item.idCourseType,
        title: item.title,
        count: item.views
      }));
      break;
  }

  const changeLocation = url => {
    pushUrl(url);
  };

  useEffect(() => {
    getViews();
    getPositive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <Container>
        <NavWrapper>
          <Icon
            className="icon-btn-arrow"
            onClick={() => changeLocation(routes.teamsActivity.path)}
          />
          <Title>
            <ProDisplay type="menutitle">{titolo}</ProDisplay>
          </Title>
        </NavWrapper>
        <IntestationWrapper>
          <Text>
            <ProDisplay configuration={{ fontSize: '14' }}>
              {content.length} Contenuti
            </ProDisplay>
          </Text>
          <Text>
            <ProDisplay configuration={{ fontSize: '14' }}>{text}</ProDisplay>
          </Text>
        </IntestationWrapper>
        <ContentWrapper>
          {content ? (
            (content.filter(views=>views.title != '' && views.title != null ) || []).map((item, index) => (
              <Content key={`mostviewed-${index}`}>
                <ListContainer>
                  <ListNumber>
                    <ProText type="list">{index + 1}</ProText>
                  </ListNumber>
                  <ProDisplay>{item.title}</ProDisplay>
                </ListContainer>
                <ResultContainer>
                  <IconViews className={icon} />
                  <Number>
                    <ProDisplay
                      type="label"
                      configuration={{ lineHeight: '18px' }}
                    >
                      {item.count}
                    </ProDisplay>
                  </Number>
                </ResultContainer>
              </Content>
            ))
          ) : (
            <Spinner />
          )}
        </ContentWrapper>
      </Container>
    </MainContainer>
  );
};

AllContent.propTypes = {
  pushUrl: PropTypes.func.isRequired,
  views: ViewsInterface,
  positive: PositivesInterface,
  getPositive: PropTypes.func.isRequired,
  getViews: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { views, positive } = state.teams;
    return {
      views,
      positive
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getViews: () =>
      dispatch({
        type: VIEWS_GET._REQUEST
      }),
    getPositive: () =>
      dispatch({
        type: POSITIVE_GET._REQUEST
      })
  })
)(AllContent);
