import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { Container } from 'ui/gridSystem';

import { ProfilePicture } from 'ui/components';
import ProDisplay from 'ui/typography/proDisplay';
import {
  Followers,
  FollowersContainer,
  Following,
  Head,
  Name,
  Number,
  ProfileInfo,
  ProgressCardsWrapper,
  ProgressesContainer,
  ProgressesTitle,
} from 'pages/Profile/style';
import ProText from 'ui/typography/proText';
import ProfileProgressCard from 'ui/components/ProfileProgressCard';
import { GET_FOLLOWED_INFO } from 'redux/actions/social';
import { GET_ALL_USERS } from 'redux/actions/notes';

const FollowProfileUser = ({
  getFollowedInfo,
  getAllUsers,
  users,
  profile,
  followCount,
  pathProgress,
  mediaIsPhone,
  userId,
  isSelection,
}) => {
  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  useEffect(() => {
    if (userId && users.length > 0) {
      getFollowedInfo(userId);
    }
  }, [getFollowedInfo, users, userId]);

  if (!profile || !followCount || !pathProgress) return <></>;

  return (
    <Container>
      <Head>
        <ProfileInfo>
          <ProfilePicture profile={profile} />
          {mediaIsPhone ? (
            <div>
              <Name>
                <ProText type="name">
                  {profile.firstname} {profile.lastname}
                </ProText>
              </Name>
              {mediaIsPhone && (
                <FollowersContainer>
                  <Followers className="follow">
                    <Number>
                      <ProDisplay
                        type="userName"
                        configuration={{
                          color: 'primary',
                          colorType: 'base',
                        }}
                      >
                        {followCount?.followers}
                      </ProDisplay>
                    </Number>
                    <ProDisplay
                      type="1"
                      configuration={{
                        letterSpapcing: '0.19px',
                        color: 'davysGray',
                      }}
                    >
                      Followers
                    </ProDisplay>
                  </Followers>
                  <Following className="follow">
                    <Number>
                      <ProDisplay
                        type="userName"
                        configuration={{
                          color: 'primary',
                          colorType: 'base',
                        }}
                      >
                        {followCount?.followed}
                      </ProDisplay>
                    </Number>
                    <ProDisplay
                      type="1"
                      configuration={{
                        letterSpapcing: '0.19px',
                        color: 'davysGray',
                      }}
                    >
                      Following
                    </ProDisplay>
                  </Following>
                </FollowersContainer>
              )}
            </div>
          ) : (
            <Name>
              <ProText type="name">
                {profile.firstname} {profile.lastname}
              </ProText>
            </Name>
          )}
        </ProfileInfo>
        {!mediaIsPhone && (
          <FollowersContainer>
            <Followers className="follow">
              <Number>
                <ProDisplay
                  type="userName"
                  configuration={{ color: 'primary', colorType: 'base' }}
                >
                  {followCount?.followers}
                </ProDisplay>
              </Number>
              <ProDisplay
                type="1"
                configuration={{
                  letterSpapcing: '0.19px',
                  color: 'davysGray',
                }}
              >
                Followers
              </ProDisplay>
            </Followers>
            <Following className="follow">
              <Number>
                <ProDisplay
                  type="userName"
                  configuration={{ color: 'primary', colorType: 'base' }}
                >
                  {followCount?.followed}
                </ProDisplay>
              </Number>
              <ProDisplay
                type="1"
                configuration={{
                  letterSpapcing: '0.19px',
                  color: 'davysGray',
                }}
              >
                Following
              </ProDisplay>
            </Following>
          </FollowersContainer>
        )}
      </Head>
      <ProgressesContainer>
        <ProgressesTitle>
          <ProDisplay
            type="menutitle"
            configuration={{
              fontSize: '20',
              color: 'darkCharcoal',
            }}
          >
            I Progressi di {profile.firstname} {profile.lastname}
          </ProDisplay>
        </ProgressesTitle>
        {pathProgress && (
          <ProgressCardsWrapper>
            <ProfileProgressCard
              data={pathProgress?.collection}
              title="Il tuo piano​"
            />
            {pathProgress?.selection?.items?.length > 0 ? (
              <ProfileProgressCard
                data={pathProgress?.selection}
                title="Formazione Obbligatoria​"
                bgColorLighter
                isSelection={isSelection}
              />
            ) : null}
          </ProgressCardsWrapper>
        )}
      </ProgressesContainer>
    </Container>
  );
};

FollowProfileUser.propTypes = {
  mediaIsPhone: PropTypes.bool,
  userId: PropTypes.string,
  isSelection: PropTypes.bool,
  //HOC (connect, state)
  profile: PropTypes.object,
  followCount: PropTypes.object,
  pathProgress: PropTypes.object,
  users: PropTypes.array,

  //HOC (connect, dispatch)
  getFollowedInfo: PropTypes.func,
  getAllUsers: PropTypes.func,

  //HOC withMediaQueries
};

export default connect(
  state => {
    const {
      followUser: { profile, followCount, pathProgress },
      users,
    } = state.social;
    return { profile, followCount, pathProgress, users };
  },
  dispatch => ({
    getAllUsers: () => dispatch({ type: GET_ALL_USERS._REQUEST }),
    getFollowedInfo: userId =>
      dispatch({ type: GET_FOLLOWED_INFO._REQUEST, userId }),
  })
)(withMediaQueries(FollowProfileUser));
