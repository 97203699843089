import styled, { css } from 'styled-components/macro';

const ProductSubscriptionContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display:flex;
  overflow:hidden;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;

  
  @media ${props => props.theme.device.tablet} {
    justify-content: space-between;
  }

  @media ${props => props.theme.device.mobile} {
    justify-content: center;
    padding:0px;
  }
`;

const DescriptionContainer = styled.div`
  width: 100%;
  padding: 10px 10px 0px 10px;
`;

const Question = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  display: -webkit-box;  
  -webkit-line-clamp: 3;  
  -webkit-box-orient: vertical;     
  overflow: hidden;
  color: ${props => props.theme.colors.davysGray};
  margin-bottom:auto
`;
const TextSection = styled.span`
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;
  margin:auto;
  display: -webkit-box;  
  -webkit-line-clamp: 3;  
  -webkit-box-orient: vertical;     
  overflow: hidden;
  display: flex;
  max-width:100%;

  @media ${props => props.theme.device.mobile} {
    display:flex;
    justify-content: start;
  }
`;

const Title = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top:auto;
  display: -webkit-box;  
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical;     
  overflow: hidden;

`;

const IconInfo = styled.span`
  font-size: 30px;
  margin-left: 3px;
  color: ${props => props.theme.colors.primary.base};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.43;
  display: -webkit-box;  
  -webkit-line-clamp: 2;  
  -webkit-box-orient: vertical;     
  overflow: hidden;
  margin-top:auto;
`;

const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: rgba(62, 107, 126, 0.05);
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
`;

const Price = styled.div`
  margin: auto;
`;

const IVA = styled.div``;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
`;

const QuantityLabel = styled.div`
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
  color: ${props => props.theme.colors.americanSilver};
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(11, 58, 78, 0.1);
  min-height:480px;
  position: relative;
  background: white;
  margin:10px;
  align-self:normal;
  padding-bottom:20px;

  ${props =>
    props.isChild &&
    css`
      background-color: white;
      border-radius: 6px;
      position: relative;
      margin-bottom: 5px;
      border-top: none;

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      ${PriceContainer} {
        background-color: rgba(62, 107, 126, 0.1);
      }
  `}
  
  @media ${props => props.theme.device.mobile} {
    padding:10px;
    margin:10px 0 10px 0;
  }

    ${DescriptionContainer} {
      width: 100%;
      text-align: left;
    }
    
    ${PriceContainer} {
      width: 100%;
      margin: 0;
      height: 60px;
      margin-bottom: 0px;
    }
    
    ${QuantityContainer} {
      width: 100%;
    }
  }  
`;

const Span = styled.span``;
const PriceAndQuantityContainer =styled.div`
  margin-top: auto`;

const PriceRow = styled.div`
  display: flex;
  align-items:center;
`;

const VatRow = styled.div``;

const Period = styled.span`
  color: ${props => props.theme.colors.americanSilver};
  font-size: 16px;
  font-weight: 300;
`;

const Image = styled.div`
  height: 120px;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  min-height: 120px;
`;

export {
  ProductSubscriptionContainer,
  DescriptionContainer,
  QuantityContainer,
  ProductContainer,
  Title,
  Description,
  QuantityLabel,
  PriceContainer,
  Span,
  IVA,
  Price,
  Question,
  Period,
  PriceRow,
  VatRow,
  IconInfo,
  TextSection,
  Image,
  PriceAndQuantityContainer
};
