import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {Col} from 'ui/gridSystem'  
import { ProductVariation } from 'interface/signup';
import Product from './Product';

const ProductSubscription = ({
  product,
  onChangeQuantity,
  cart,
  isCatalog,
  maxQuantity
}) => {
  const [parentVal, setParentVal] = useState(
    cart?.products[Object.keys(cart?.products).find(k => k === product.sku)]
      ?.num || 0
  );

  const onChange = (num, selProd) => {
    setParentVal(num);
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  const onChangeChild = (num, selProd) => {
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  return (
  <>
      <Col  lg={4} md={6} sm={12}>
        <Product 
          product={product} 
          onChange={onChange} isCatalog={isCatalog} maxQuantity={maxQuantity}/>
      </Col>
    
        {product?.child_products?.length > 0 &&
          product.child_products.map(child_product => (<Col lg={4} md={6} sm={12}>
          <Product
            isChild
            key={child_product.product_id + child_product.variation_id}
            product={child_product}
            onChange={onChangeChild}
            disabled={!parentVal}
            isCatalog={isCatalog}
            cart={cart}
            maxQuantity={maxQuantity}
          />
      </Col>))}
    </>
  );
};

ProductSubscription.propTypes = {
  product: ProductVariation,
  onChangeQuantity: PropTypes.func.isRequired,
  cart: PropTypes.object,
  isCatalog: PropTypes.bool,
};

export default connect(({ signup: { cart } }) => ({ cart }))(
  ProductSubscription
);
