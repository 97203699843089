import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  CustomCarouselContainer,
  CustomCarouselContainer2 as CustomCarouselContainerAlt,
} from './style';

const CustomCarousel = ({
  children,
  config,
  onSlideChange,
  onAfterChange,
  isAutoPlay,
  autoPlay,
  isSDS,
  slideType,
  clickCounter,
  goToSlideIndex,
  alt = false,
}) => {
  //const [newConfig, setNewConfig] = useState();
  const [load, setLoad] = useState(false);

  setTimeout(() => {
    setLoad(false);
  }, 500);

  const slider = useRef(null);

 /*useEffect(() => {
      if (isAutoPlay) slider.current.slickPlay();
      else slider.current.slickPause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoPlay]);*/

  useEffect(() => {
    if (slider.current && goToSlideIndex !== undefined) {
      slider.current.slickGoTo(goToSlideIndex);
    }
  }, [goToSlideIndex, slider]);

  useEffect(() => {
    if (isSDS) {
      if (slideType === 'next') slider.current.slickNext();
      if (slideType === 'prev') slider.current.slickPrev();
    }
  }, [isSDS, slideType, clickCounter]);

  return (
    !alt ? (
      <CustomCarouselContainer load={load}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading*/}
        <Slider
          {...config}
          autoplay={isAutoPlay}
          autoplaySpeed={5000}
          beforeChange={onSlideChange}
          afterChange={onAfterChange}
          ref={slider}
        >
          {children}
        </Slider>
      </CustomCarouselContainer>
    ) : (
      <CustomCarouselContainerAlt load={load}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading*/}
        <Slider
          {...config}
          autoplay={isAutoPlay}
          autoplaySpeed={5000}
          beforeChange={onSlideChange}
          afterChange={onAfterChange}
          ref={slider}
        >
          {children}
        </Slider>
      </CustomCarouselContainerAlt>
    )
  );
};

CustomCarousel.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({}),
  onSlideChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  isAutoPlay: PropTypes.bool,
  autoPlay: PropTypes.bool,
  isSDS: PropTypes.bool,
  slideType: PropTypes.string,
  clickCounter: PropTypes.number,
  goToSlideIndex: PropTypes.number,
  alt: PropTypes.bool,
};

export default connect(
  /*state => {
    const { autoPlay } = state.landingPage;
    return { autoPlay };
  },
  () => ({})*/
)(CustomCarousel);
