import produce from 'immer';
import { GET_DOMAIN } from 'redux/actions/domain';

const initialState = {};

const domainReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_DOMAIN._SUCCESS: {
        draft = {
          ...action.response,
          brand: action.response.brand[0],
          policy: action.response.policy.data[0],
          contract: action.response.contract.data[0],
          consents: action.response?.consents?.data
            ? action.response.consents.data[0]
            : []
        };
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default domainReducer;
