import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { withMediaQueries } from 'hoc/withMediaQueries';
import PropTypes from 'prop-types';

import { Container } from 'ui/gridSystem';
import { Footer } from 'ui/components';
import {
    CLEAR_PACKAGE_BREADCRUMB,
} from 'redux/actions/playlist';
import routes from 'routes';
import useSections from './useSections';
import HomepageSection from 'ui/components/Homepage/Section';
import NotesModal from 'ui/components/Modals/NotesModal';
import MainContainer from '../style';

const Homepage = ({
  // HOC, state
  notConfigured,
  // HOC, dispatch
  clearPackageBreacrumb,
  pushUrl,
  // HOC, media queries
  currentViewport,
  viewportTypes,
}) => {
  const sections = useSections(currentViewport, viewportTypes); //pass domain here

  useEffect(() => { clearPackageBreacrumb() }, [clearPackageBreacrumb])

  useEffect(() => {
    if (notConfigured) {
      if (notConfigured.length) {
        pushUrl(routes.drawProfile.path);
      }
    }
  }, [notConfigured, pushUrl]);

  // const handleBookmark = data => {
  //   const body = {
  //     itemId: data.itemId,
  //     type: data.type,
  //     title: data.title,
  //     imageUrl: data.cover,
  //     duration: 0,
  //     format: '',
  //     completed: data.completed,
  //     // eslint-disable-next-line radix
  //     coursesCount: parseInt(data.progress.split('/')[1]),
  //   };
  //   if (bookmarks.findIndex(b => b.itemId === data.itemId) >= 0) {
  //     removeBookmark({ itemId: body.itemId, itemType: body.type });
  //   } else addBookmark(body);
  // };

  return (
    <>
      <MainContainer>
        <Container>
          {sections.map((section, idx) => (
            <HomepageSection
              key={`home-section-${idx}`}
              {...section}
            />
          ))}
        </Container>
      </MainContainer>
      <Footer />
      <NotesModal />
    </>
  );
};

Homepage.propTypes = {
  // HOC withMediaQueries
  currentViewport: PropTypes.string.isRequired,
  viewportTypes: PropTypes.shape({
      IsPhone: PropTypes.oneOf([0]),
      IsTablet: PropTypes.oneOf([1]),
      IsLaptop: PropTypes.oneOf([2]),
      IsDesktop: PropTypes.oneOf([3]),
      IsLargeDesktop: PropTypes.oneOf([4])
  }),

  // HOC (connect, state)
  notConfigured: PropTypes.array,

  // HOC (connect, dispatch)
  clearPackageBreacrumb: PropTypes.func,
  pushUrl: PropTypes.func,
};

export default withMediaQueries(
  connect(
    state => {
      const { notConfigured } = state.onBoarding;
      return {
        notConfigured,
      };
    },
    dispatch => ({
      clearPackageBreacrumb: () => dispatch({ type: CLEAR_PACKAGE_BREADCRUMB }),
      pushUrl: url => dispatch(push(url)),
    })
  )(Homepage)
);
