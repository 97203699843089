import produce from 'immer';
import { categories } from 'utils/default-filters';
import {
  GET_PATH,
  GET_PLAYLIST,
  GET_COURSE,
  SET_CATEGORIES_FILTER,
  GET_RELATED,
  GET_QUESTIONS,
  SET_RECOMMENDED,
  GET_CATEGORIES_USER,
  ADD_REVIEW,
  GET_KEEP_WATCHING,
  SET_SECTION,
  // UPDATE_PLAYLIST
  PLAYLISTS_INITIALSTATE,
  GET_PACKAGES,
  GET_PACKAGE,
  GET_EVENTS,
  GET_EVENT,
  CLEAR_PACKAGE_BREADCRUMB,
  SET_PLAYLIST_HISTORY,
  GET_SELECTIONS,
  GET_COLLECTIONS,
  GET_CATEGORIES_PILLS,
  GET_CATEGORY_PLAYLIST,
  RESET_PLAYLIST
} from 'redux/actions/playlist';
import { GET_ALL_COL } from 'redux/actions/onBoarding';
import { UPDATE_SOCIAL, UPDATE_SOCIAL_EVENT } from 'redux/actions/social';
import { detailType } from 'utils/common';
// import { detailType } from 'utils/common';

const initialState = {
  playlists: {
    yourpathprogress: {
      collection: {
        items: [],
      },
      selection: {
        items: [],
      },
    },
    allCollections: [],
    playlistsHistory: '',
  },
  filters: {
    type: categories.all.value,
    search: '',
  },
  categories: [],
  categoriesPills: [],
  playlistSelected: {},
  course: {},
  related: {},
  survey: {},
  keepWatching: [],
  section: '',
  packages: {
    packageCount: 0,
    packageList: [],
    packageSelected: {
      packageInfo: {},
      assetDetail: {
        collection: {
          percentage: "0",
          progress: "0/0",
          items: [],
        },
        selection: {
          percentage: "0",
          progress: "0/0",
          items: []
        },
        event: {
          percentage: "0",
          progress: "0/0",
          items: [],
        }
      },
    },
    packageBreadcrumb: {
      title: '',
      description: '',
    },
  },
  events: {
    plannedEvents: [],
    selectedEvent: {
      itemId: null,
      title: '',
      description: '',
      eventType: '',
      cover: '',
      session: {
        sessionId: null,
        eventLiveId: null,
        location: null,
        link: '',
        theachers: null, // Da concordare con drupal
        startDate: '',
        endDate: ''
      },
      askTheExpert: false,
      teachingMaterial: {
        documents: [], // from drupal api
        links: [] // from drupal api
      },
      creationDate: ''
    },
  },
  selections: [],
  collections: [],
  recommendedCollections: [],
  categoryPlaylist: {
    items: [],
    nextPage: null,
    previousPage: null,
  },
};

const playlistReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PATH._SUCCESS: {
        const { collection, selection } = action.yourpathprogress;
        collection.items = collection.items.map(i => ({ ...i, asset: true }));
        selection.items = selection.items.map(i => ({ ...i, asset: true }));
        draft.playlists.yourpathprogress = {
          collection,
          selection,
        };
        break;
      }
      case SET_PLAYLIST_HISTORY:
        draft.playlists.playlistsHistory = action.source;
        break;
      case SET_CATEGORIES_FILTER:
        draft.filters = action.filters;
        break;
      case GET_PLAYLIST._SUCCESS: {
        const newPlaylistSelected = {};
        Object.keys(action.data).forEach(key => (
          newPlaylistSelected[key.toLowerCase()] = action.data[key]
        ));
        draft.playlistSelected = newPlaylistSelected;
        break;
      }
      case GET_COURSE._SUCCESS: {
        draft.course = action.data;
        break;
      }
      case GET_RELATED._SUCCESS: {
        draft.related = action.data;
        break;
      }
      case UPDATE_SOCIAL: {
        if (state.course?.itemid === action.itemId) {
          draft.course = { ...state.course, ...action.data };
        }
        break;
      }
      case UPDATE_SOCIAL_EVENT: {
        if (state.events?.selectedEvent?.itemId === action.itemId) {
          draft.events.selectedEvent = { ...state.events?.selectedEvent, ...action.data };
        }
        break;
      }
      case GET_QUESTIONS._SUCCESS: {
        draft.survey = action.data.survey;
        break;
      }
      case GET_ALL_COL._SUCCESS: {
        const cols = action.data.needs.map(need => need.items).flat();
        draft.playlists.allCollections = cols;
        break;
      }
      case GET_CATEGORIES_USER._SUCCESS: {
        draft.categories = action.data.needs;
        break;
      }
      case GET_CATEGORIES_PILLS._SUCCESS: {
        draft.categoriesPills = action.data.needs;
        break;
      }
      case SET_RECOMMENDED._SUCCESS: {
        draft.playlistSelected.recommended = true;
        break;
      }
      case ADD_REVIEW._SUCCESS: {
        draft.course.reviewed = true;
        break;
      }
      case GET_KEEP_WATCHING._SUCCESS: {
        draft.keepWatching = action.data.listofloinprogress;
        break;
      }
      case SET_SECTION: {
        draft.section = action.section;
        break;
      }
      // case UPDATE_PLAYLIST: {
      //   draft.playlistSelected.listoflo = state.playlistSelected?.listoflo.map(
      //     lo => {
      //       if (lo.itemId === action.id) return { ...lo, completed: true };
      //       return lo;
      //     }
      //   );
      //   break;
      // }
      case PLAYLISTS_INITIALSTATE: {
        draft = initialState;
        break;
      }
      case GET_PACKAGES._SUCCESS: {
        const { packagecount, packagelist } = action.allPackages;
        // packageList.map(p => ({ ...p, asset: true }));
        draft.packages.packageCount = packagecount;
        function compare( a, b ) {
          if ( a.weight < b.weight ){
            return -1;
          }
          if ( a.weight > b.weight ){
            return 1;
          }
          return 0;
        }

        packagelist.sort( compare );
        draft.packages.packageList = packagelist;
        break;
      }
      case GET_PACKAGE._SUCCESS: {
        const { packageinfo, assetdetail } = action.package;
        assetdetail.collection = assetdetail.collection ? ({
          ...assetdetail.collection,
          items: assetdetail.collection.items.map(c => ({
            ...c,
            eventType: c.type,
            type: detailType.PLAYLIST
          }))
        }) : null;
        assetdetail.selection = assetdetail.selection ? ({
          ...assetdetail.selection,
          items: assetdetail.selection.items.map(c => ({
            ...c,
            eventType: c.type,
            type: detailType.PLAYLIST
          }))
        }) : null;
        draft.packages.packageSelected = {
          packageInfo: packageinfo,
          assetDetail: assetdetail,
        };
        draft.packages.packageBreadcrumb = {
          title: packageinfo.title,
          description: packageinfo.body,
        }
        break;
      }
      case CLEAR_PACKAGE_BREADCRUMB: {
        draft.packages.packageBreadcrumb = initialState.packages.packageBreadcrumb
        break;
      }
      case GET_EVENTS._SUCCESS: {
        const { plannedevents } = action.events;
        // const plannedEvents = plannedevents.map(e => ({ ...e, type: detailType.EVENT }));
        draft.events.plannedEvents = plannedevents;
        break;
      }
      case GET_EVENT._SUCCESS: {
        const { eventdetail } = action.event;
        // const selectedevent = { ...eventdetail, type: detailType.EVENT };
        draft.events.selectedEvent = eventdetail;
        break;
      }
      case GET_SELECTIONS._SUCCESS: {
        const { items } = action.asset;
        draft.selections = items;
        break;
      }
      case GET_COLLECTIONS._SUCCESS: {
        const { crossContent, asset: { items } } = action;
        if (crossContent) {
          draft.collections = items;
        } else {
          draft.recommendedCollections = items;
        }
        break;
      }
      case GET_CATEGORY_PLAYLIST._SUCCESS: {
        const { catalog, nextPage } = action;
        const updateItems = Boolean(state.categoryPlaylist.items) ? [...state.categoryPlaylist.items, ...catalog] : catalog;
        draft.categoryPlaylist = {items: updateItems, nextPage: !!nextPage ? nextPage : null, previousPage: state.categoryPlaylist.nextPage};
        break;
      }
      case RESET_PLAYLIST._SUCCESS: {
        draft = initialState;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default playlistReducer;
