import styled from 'styled-components/macro';

export const CustomCarouselContainer = styled.div`
  .slick-slider {
    .slick-track{
      display: flex;
    }
    .slick-list {
      padding: ${props => (!props.load ? '0 20px' : '0 50px')};
      /* padding: 0 20px; */
      .slick-track {
        margin: 0;
        margin-left: -20px;
      }
    }
    
    .slick-slide {
      position: relative;
      z-index: 1;
    }
    
    .slick-active {
      z-index: 999;
    }

    .slick-slide {
      @media ${props => props.theme.device.mobile} {
       /*  width: unset !important; */
      }
    }

    .slick-slide > div {
      padding: 0 25px 0 0;
      @media ${props => props.theme.device.mobile} {
        padding: 0;
      }
    }

    .slick-next,
    .slick-prev {
      border-radius: 50%;
      z-index: 2;
      width: 38px;
      height: 38px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      background-color: ${props => props.theme.colors.white};
      
      &:before {
        content: '\\e941';
        font-family: 's4b';
        font-size: 26px;
        color: ${props => props.theme.colors.primary.darker};
      }

      &.slick-disabled {
        display: none !important;
      }

      &:hover,
      &:active,
      &:focus {
        color: none;
        outline: none;

        &::before {
          opacity: 0.8;
        }
      }
    }

    .slick-prev {
      top: 50%;
      left: -2%;
      transform: translateY(-100%);
      &::before {
        display: block;
        transform: rotate(180deg);
      }
    }

    .slick-next {
      top: 50%;
      right: -2%;
      transform: translateY(-100%);
    }
  }
  .slick-dots {
    & li.slick-active button:before {
      color: ${props => props.theme.colors.primary.darker};
      opacity: 1;
      font-size: 12px;
    }
  }
`;

export const CustomCarouselContainer2 = styled.div`
  .slick-slider {
    overflow: hidden;

    .slick-list {
      max-width: 1440px;
      margin: 0 auto;

      .slick-track {
      }
    }

    .slick-list {
    }

    .slick-slide {
      position: relative;
      z-index: 1;
    }
    
    .slick-active {
      z-index: 999;
    }

    .slick-slide > div {
    }

    .slick-next,
    .slick-prev {
      z-index: 1;
      width: 70px;
      height: 70px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 60%;
      transform: translateY(-100%) rotate(45deg);
      &:before {
        content: '\\e940';
        font-family: 's4b';
        font-size: 25px;
        color: ${props => props.theme.colors.black};
      }
      &.slick-disabled {
        display: none !important;
      }
      &:hover,
      &:active,
      &:focus {
        color: none;
        outline: none;
        &::before {
          opacity: 0.8;
        }
      }
      
      @media ${props => props.theme.device.mobile} {
        width: 50px;
        height: 50px;
        
        &:before {
          font-size: 15px;
        }
      }
    }
    .slick-prev {
      left: -35px;

      &::before {
        display: block;
        transform: rotate(-225deg);
        margin-right: -35px;
        margin-bottom: 35px;
      }
      
      @media ${props => props.theme.device.mobile} {
        &::before {
          margin-right: -25px;
          margin-bottom: 25px;
        }
      }
    }
    
    .slick-dots {
      z-index: 1;
    }
    .slick-next {
      right: -35px;

      &::before {
        display: block;
        margin-left: -35px;
        margin-top: 35px;
        transform: rotate(-45deg);
      }
      
      @media ${props => props.theme.device.mobile} {
        &::before {
          margin-left: -25px;
          margin-top: 25px;
        }
      }
    }
  }
  
  .slick-dots {
    position: relative;
    bottom: 40px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
    & li {
      margin: 0 5px;
      @media ${props => props.theme.device.mobile} {
        margin: 0;
        width: 12px;
      }
    }
    & li button:before {
      background-color: transparent;
      border: 1px solid white;
      font-size: 0px;
      opacity: 1;
      width: 11px;
      height: 11px;
      transform: rotate(45deg);
      @media ${props => props.theme.device.mobile} {
        width: 6px;
        height: 6px;
        border: 1px solid white;
      }
    }
    & li.slick-active button:before {
      border: 0;
      background-color: white;
      opacity: 1;
      color: ${props => props.theme.colors.white};
    }
    @media ${props => props.theme.device.mobile} {
      bottom: 25px;
    }
  }
`;
