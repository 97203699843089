import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import 'react-multi-carousel/lib/styles.css';

import ProDisplay from 'ui/typography/proDisplay';
import { Footer } from 'ui/components';
import { Container } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import compose from 'utils/compose';
import routes from 'routes';
import {
  Title,
  TitleWrapper,
  Body,
  Logo,
  HeadWrapper,
  Head,
  MaintenanceContainer,
} from './style';

const Maintenance = ({
  pushUrl,
  brand
}) => {

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={brand?.logo_white} />
          </Head>
        </Container>
      </HeadWrapper>
      <MaintenanceContainer>
        <Body>
          <Container>
            <TitleWrapper>
              <ProDisplay
                type="categories"
                configuration={{
                  fontWeight: 900,
                  // fontSize: 36
                }}
              >
              <Title>
                Il sito è attualmente in manutenzione.
              </Title>
              </ProDisplay>
            </TitleWrapper>
          </Container>
        </Body>
      </MaintenanceContainer>
      <Footer />
    </>
  );
};

Maintenance.propTypes = {
  // HOC (connect, state)
  brand: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
};

const composed = compose(
  connect(
    state => {
      const { brand } = state.domain;
      return {
        brand
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
    })
  ),
  withMediaQueries
)(Maintenance);
export default composed;
