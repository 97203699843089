import { withMediaQueries } from 'hoc/withMediaQueries';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { mappingVariations, removeDuplicates } from '.';
import { TableCardEmpty } from '..';

const EmptyCards = ({
  subscription,
  allSubscriptions,
  calendars,
  email = null,
  options,
  parsedProspects,
  handleOnChangeVariation,
  handleSelectCalendar,
  mediaIsPhone,
  handleOnChangeEmail
}) => {
  const [cardOptions, setCardOptions] = useState(options);

  useEffect(() => {
    const checkedVariations = subscription.variations.map(mappingVariations);

    const checkVariation = subscription.variations.find(va => {
      if (!email) return false;
      const prospect = parsedProspects.find(p => p.email === email);
      if (!prospect) return false;
      const offert = (prospect.offerts || []).find(
        o => va.variationId === o.variationId && o.status.toLowerCase() !== 'inactive'
      );
      if (!offert) return false;
      return true;
    });

    if (checkVariation)
      handleOnChangeVariation(subscription.id, subscription.variations, []);

    const allOptions = [
      ...options.filter(va => {
        /* check if variation is already assigned to email in a different card */
        if (!email) return true;
        const row = allSubscriptions.find(sub => (
          sub.email?.toLowerCase() === email?.toLowerCase() && sub.variations[0]?.variationId === va.value
        ));
        if (row) return false;
        /* check of variation is already assigned to email in the back end */
        const prospect = parsedProspects.find(p => p.email === email);
        if (!prospect) return true;
        const offert = (prospect.offerts || []).find(
          o => va.value === o.variationId && o.status.toLowerCase() !== 'inactive'
        );
        if (!offert) return true;
        /* otherwise let the option be selectable */
        return false;
      }),
      ...checkedVariations
    ];

    setCardOptions(
      removeDuplicates(allOptions, it => it.value).sort((a, b) =>
        a.label.localeCompare(b.label)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, options, subscription]);

    return (
      <TableCardEmpty
        handleOnChangeEmail={handleOnChangeEmail}
        handleOnChangeVariation={handleOnChangeVariation}
        handleSelectCalendar={handleSelectCalendar}
        subscription={subscription}
        emailName={email}
        options={cardOptions}
        calendarDates={
          /* requires single select on variations  */
          subscription?.variations[0]?.productId
          ? calendars[subscription?.variations[0]?.productId]
          : []
        }
      />
    );

};

EmptyCards.propTypes = {
  subscription: PropTypes.object,
  allSubscriptions: PropTypes.array,
  email: PropTypes.string,
  handleOnChangeEmail: PropTypes.func,
  handleOnChangeVariation: PropTypes.func,
  mediaIsPhone: PropTypes.bool.isRequired,
  options: PropTypes.array,
  parsedProspects: PropTypes.array
};

EmptyCards.defaultProps = {
  allSubscriptions: []
}

export default withMediaQueries(EmptyCards);
