export const formatPlaylist = pls => {
  return Object.values(pls)
    .map(pl => pl.items)
    .flat();
};

export const getAuthorization = (type, id, rawPlaylists) => {
  if (type !== 'playlist') return true;
  const playlists = formatPlaylist(rawPlaylists);
  return playlists.findIndex(pl => pl.itemId === id) >= 0;
};
