import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import { Title } from 'pages/Signup/steps/UserAccount/style';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_UPDATE_PRODUCTS_CART,
} from 'redux/actions/signup';

import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  LandingPageInterface,
  ProductVariationInterface,
} from 'interface/signup';
import ProductSubscription from 'ui/components/ProductSubscription';
import { HtmlRaw } from 'ui/components';
import { TitleContainer, SubTitle } from './style';
import { ProductSubscriptionContainer } from 'ui/components/ProductSubscription/style'


const Subscriptions = ({
  updateCart,
  mediaIsPhone,
  landingProducts,
  products,
  step,
  setStepSubscriptions,
  cart,
  maxQuantity
}) => {
  const [mappedProds, setMappedProds] = useState([]);

  const updateVal = (num, pack) => {
    updateCart({ ...pack, num });
  };

  useEffect(() => {
    const flatProduct = ({ variations, ...props }) =>
      variations.map(v => ({ ...props, ...v }));
    const allProducts = products.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        ...flatProduct(currentValue),
      ],
      []
    );
    const outArray = [];

    allProducts.forEach(product => {
      if (Array.isArray(product.cards)) {
        const ref = product.cards.find(
          c => c.card_type === 'card_product_variation_relation'
        );
        if (ref) {
          const father = allProducts.find(
            f => f.variation_id.toString() === ref.product_variation_ref
          );
          if (father) {
            const fatherIndex = outArray.findIndex(
              o =>
                o.product_id === father.product_id &&
                o.variation_id === father.variation_id
            );
            if (fatherIndex > -1) {
              outArray[fatherIndex].child_products = [
                ...(outArray[fatherIndex].child_products || []),
                product,
              ];
            } else {
              father.child_products = [product];
              outArray.push(father);
            }
          }
        } else {
          outArray.push(product);
        }
      } else {
        const fatherIndex = outArray.findIndex(
          o =>
            o.product_id === product.product_id &&
            o.variation_id === product.variation_id
        );
        if (!(fatherIndex > -1)) outArray.push(product);
      }
    });
    setMappedProds(outArray);
  }, [products]);

  useEffect(() => {
    if (step.num !== 1) {
      setStepSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12}>
          &nbsp;
        </Col>
        <Col lg={12} md={12} sm={12}>
          <TitleContainer>
            <Title>
              <ProDisplay
                type="modalTitle"
                configuration={
                  mediaIsPhone
                    ? { letterSpacing: 'normal' }
                    : { fontWeight: 'normal' }
                }
              >
                {landingProducts?.title}
              </ProDisplay>
            </Title>
            <SubTitle>
              <ProText type="description">
                <HtmlRaw html={landingProducts?.subtitle} />
              </ProText>
            </SubTitle>
          </TitleContainer>
        </Col>
        <ProductSubscriptionContainer>
          {mappedProds.map(pack => (
              <ProductSubscription
                key={pack.product_id}
                product={pack}
                onChangeQuantity={(num, product) => updateVal(num, product)}
                cart={cart}
                maxQuantity={maxQuantity}
              />
            
          ))} 
        </ProductSubscriptionContainer>         
      </Row>
    </Container>
  );
};

Subscriptions.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
  cart: PropTypes.object,

  // HOC (connect, state)
  products: ProductVariationInterface,
  landingProducts: LandingPageInterface,
  step: PropTypes.object,
  // HOC (connect, dispatch)
  updateCart: PropTypes.func.isRequired,
  setStepSubscriptions: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { products, step } = state.signup;
    const { landingProducts } = state.landingPage;
    return {
      landingProducts,
      products,
      step,
    };
  },
  dispatch => ({
    updateCart: product =>
      dispatch({ type: SIGNUP_UPDATE_PRODUCTS_CART, product }),
    setStepSubscriptions: () =>
      dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 }),
  })
)(withMediaQueries(Subscriptions));
