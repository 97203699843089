import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import Checkbox from 'ui/atoms/Checkbox';
import { styles as defaultStyles } from 'utils/multi-select';
import { SelectContainer, Icon } from './style';

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <components.Placeholder
      {...props}
      isFocused={props.selectProps.inputValue.length}
    >
      {props.selectProps.placeholder}
    </components.Placeholder>
    {React.Children.map(children, child =>
      child && child.type !== components.Placeholder ? child : null
    )}
  </components.ValueContainer>
);

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon className="icon-btn-arrow" />
    </components.DropdownIndicator>
  );
};
const CustomSelect = ({
  options = [],
  onChange,
  onBlur,
  selected,
  disabled,
  placeholder = 'Seleziona...',
  styles = {},
  readonly = false,
  formatOptionLabel,
  className,
  isSearchType = false,
  isClearable = false,
  isMulti = false,
  closeOnSelect = true,
  openMenuOnClick = true,
  onInputChange,
  hideSelectedOptions
}) => {
  const [selectedOption, setSelectedOption] = useState(selected);
  const [searchInput, setSearchInput] = useState('');

  const handleOnInputChange = value => {
    setSearchInput(value);
    if (onInputChange) onInputChange(value.toString());
  };

  const onchangeSelected = value => {
    setSelectedOption(value);
    if (onChange) onChange(value);
  };

  const handleOnBlur = e => {
    if (e) e.preventDefault();
    if (onBlur) onBlur(selectedOption);
  };

  useEffect(() => {
    setSelectedOption(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  let customProps = {};
  if (isSearchType) {
    customProps = {
      menuIsOpen: searchInput.length >= 2,
      isClearable: true
    };
  }

  let selectComponents = {};
  const animatedComponents = makeAnimated();

  const Option = props => (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected}
        label={props.label}
        onChange={() => {}}
      />
    </components.Option>
  );

  if (isMulti) {
    selectComponents = {
      animatedComponents,
      Option
    };
  }

  return (
    <SelectContainer readonly={readonly} isSearchType={isSearchType}>
      <Select
        className={className}
        styles={{ ...defaultStyles, ...styles }}
        value={selectedOption}
        onChange={onchangeSelected}
        onBlur={handleOnBlur}
        isDisabled={disabled}
        options={options}
        isOptionDisabled={(option) => option.unavailable}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        onInputChange={handleOnInputChange}
        noOptionsMessage={() => <>Nessun risultato</>}
        isClearable={isClearable}
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        components={{
          ...selectComponents,
          DropdownIndicator,
          ValueContainer
        }}
        // controlShouldRenderValue={!isMulti}
        closeMenuOnSelect={closeOnSelect}
        blurInputOnSelect={closeOnSelect}
        classNamePrefix={className}
        openMenuOnClick={openMenuOnClick}
        onMenuClose={handleOnBlur}
        {...customProps}
      />
    </SelectContainer>
  );
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array
  ]),
  styles: PropTypes.object,
  hideSelectedOptions: PropTypes.bool,
  customProps: PropTypes.object,
  onInputChange: PropTypes.func
};

export default CustomSelect;
