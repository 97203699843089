import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Footer, Grid } from 'ui/components';
import { push } from 'redux-first-history';
import ProDisplay from 'ui/typography/proDisplay';
import routes from 'routes';
import { GET_KEEP_WATCHING } from 'redux/actions/playlist';
import { detailType } from 'utils/common';
import { Head, IconBack, Title } from './style';
import MainContainer from '../style';

const AllCourses = ({ pushUrl, getKeepWatching, keepWatching }) => {
  useEffect(() => {
    getKeepWatching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">Continua a guardare</ProDisplay>
            </Title>
          </Head>
          <Grid
            elements={keepWatching}
            location={detailType.COURSE}
            section="keepWatching"
            ellipsis={2}
          />
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

AllCourses.propTypes = {
  // HOC (connect, state)
  keepWatching: PropTypes.array,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func,
  getKeepWatching: PropTypes.func
};

export default withMediaQueries(
  connect(
    state => {
      const { keepWatching } = state.playlist;
      return {
        keepWatching
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getKeepWatching: () => dispatch({ type: GET_KEEP_WATCHING._REQUEST })
    })
  )(AllCourses)
);
