// import React from 'react';
// import { CustomPrevArrow, CustomNextArrow } from 'ui/atoms/CarouselArrows';
import { breakpoint } from 'ui/theme/partials/device';

// export const Settings = {
//   dots: true,
//   infinite: false,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   arrows: true,
//   responsive: [
//     {
//       breakpoint: breakpoint.laptop,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         infinite: true,
//         dots: true,
//       },
//     },
//     {
//       breakpoint: breakpoint.tablet,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

export const SliderSettingsCentered = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  dotsClass: 'slick-dots container',
  responsive: [
  ],
};
export const SliderSettingsAside = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  responsive: [],
};

// export const CollectionsSettings = {
//   dots: false,
//   infinite: true,
//   speed: 250,
//   autoplay: true,
//   autoplaySpeed: 0,
//   slide: 'div',
//   cssEase: 'linear',
//   slidesToShow: 4.2,
//   slidesToScroll: 1,
//   initialSlide: -1.8,
//   pauseOnFocus: false,
//   pauseOnHover: false,
//   arrows: false,
//   responsive: [
//     {
//       breakpoint: breakpoint.laptop,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: breakpoint.tablet,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//   ],
// };

export const SDSSettings = {
  dots: false,
  infinite: true,
  className: 'center',
  centerMode: true,
  centerPadding: '0px',
  speed: 1000,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  initialSlide: 0,
  pauseOnFocus: false,
  pauseOnHover: false,
  arrows: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: breakpoint.tablet,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        swipe: true,
      },
    },
  ],
};

// export const NewsEventsSettings = size => ({
//   rows: 1,
//   dots: false,
//   infinite: false,
//   speed: 1000,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   arrows: true,
//   nextArrow: <CustomNextArrow size={size} />,
//   prevArrow: <CustomPrevArrow size={size} />,
//   responsive: [
//     {
//       breakpoint: breakpoint.tablet,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//     {
//       breakpoint: breakpoint.mobile,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//   ],
// });

// export const NewsEventsMobileSettings = {
//   dots: false,
//   infinite: false,
//   className: 'center',
//   centerMode: true,
//   speed: 1000,
//   autoplay: false,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   initialSlide: 0,
//   pauseOnFocus: false,
//   pauseOnHover: false,
//   arrows: false,
//   responsive: [
//     {
//       breakpoint: breakpoint.tablet,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         initialSlide: 0,
//       },
//     },
//   ],
// };

export const CarouselSettings = {
  dots: false,
  infinite: false,
  initialSlide: 0,
  arrows: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1479,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3.8
      }
    },
    {
      breakpoint: 1219,
      settings: {
        slidesToShow: 3.3
      }
    },
    {
      breakpoint: 1011,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 3.8
      }
    },
    {
      breakpoint: 724,
      settings: {
        slidesToShow: 3.4
      }
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 619,
      settings: {
        slidesToShow: 2.9
      }
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2.8
      }
    },
    {
      breakpoint: 543,
      settings: {
        slidesToShow: 2.5
      }
    },
    {
      breakpoint: 489,
      settings: {
        slidesToShow: 2.4
      }
    },
    {
      breakpoint: 476,
      settings: {
        slidesToShow: 2.2
      }
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.9
      }
    },
    {
      breakpoint: 385,
      settings: {
        slidesToShow: 1.8
      }
    },
    {
      breakpoint: 366,
      settings: {
        slidesToShow: 1.6
      }
    },
    {
      breakpoint: 336,
      settings: {
        slidesToShow: 1.5
      }
    },
  ]
};


export const NewsCarouselSettings = {
  dots: false,
  infinite: false,
  initialSlide: 0,
  arrows: true,
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 1479,
      settings: {
        slidesToShow: 5.5
      }
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5.3
      }
    },
    {
      breakpoint: 1221,
      settings: {
        slidesToShow: 4.8
      }
    },
    {
      breakpoint: 1219,
      settings: {
        slidesToShow: 4.4
      }
    },
    {
      breakpoint: 1011,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 788,
      settings: {
        slidesToShow: 3.8
      }
    },
    {
      breakpoint: 714,
      settings: {
        slidesToShow: 3.5
      }
    },
    {
      breakpoint: 688,
      settings: {
        slidesToShow: 3.4
      }
    },
    {
      breakpoint: 643,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 610,
      settings: {
        slidesToShow: 3.1
      }
    },
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 577,
      settings: {
        slidesToShow: 2.9
      }
    },
    {
      breakpoint: 541,
      settings: {
        slidesToShow: 2.6
      }
    },
    {
      breakpoint: 503,
      settings: {
        slidesToShow: 2.3
      }
    },
    {
      breakpoint: 452,
      settings: {
        slidesToShow: 2.2
      }
    },
    {
      breakpoint: 421,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.9
      }
    },
    {
      breakpoint: 379,
      settings: {
        slidesToShow: 1.8
      }
    },
    {
      breakpoint: 358,
      settings: {
        slidesToShow: 1.7
      }
    },
    {
      breakpoint: 342,
      settings: {
        slidesToShow: 1.6
      }
    },
  ]
};

export const CarouselSettingsPackages = {
  dots: false,
  infinite: false,
  initialSlide: 0,
  arrows: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1478,
      settings: {
        slidesToShow: 2.8
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.8
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 2.5
      }
    },
    {
      breakpoint: 764,
      settings: {
        slidesToShow: 2.3
      }
    },
    {
      breakpoint: 714,
      settings: {
        slidesToShow: 2.2
      }
    },
    {
      breakpoint: 661,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 598,
      settings: {
        slidesToShow: 1.8
      }
    },
    {
      breakpoint: 544,
      settings: {
        slidesToShow: 1.7
      }
    },
    {
      breakpoint: 512,
      settings: {
        slidesToShow: 1.6
      }
    },
    {
      breakpoint: 487,
      settings: {
        slidesToShow: 1.5
      }
    },
    {
      breakpoint: 458,
      settings: {
        slidesToShow: 1.4
      }
    },
    {
      breakpoint: 433,
      settings: {
        slidesToShow: 1.2
      }
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1.1
      }
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

export const CarouselSettingsCourses = {
  dots: false,
  infinite: false,
  initialSlide: 0,
  arrows: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1479,
      settings: {
        slidesToShow: 3.4
      }
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 2.6
      }
    },
    {
      breakpoint: 1110,
      settings: {
        slidesToShow: 2.6
      }
    },
    {
      breakpoint: 1084,
      settings: {
        slidesToShow: 2.8
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 3.6
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 3.4
      }
    },
    {
      breakpoint: 711,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 626,
      settings: {
        slidesToShow: 2.9
      }
    },
    {
      breakpoint: 605,
      settings: {
        slidesToShow: 2.7
      }
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 2.5
      }
    },
    {
      breakpoint: 515,
      settings: {
        slidesToShow: 2.2
      }
    },
    {
      breakpoint: 470,
      settings: {
        slidesToShow: 2.1
      }
    },
    {
      breakpoint: 443,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1.9
      }
    },
    {
      breakpoint: 409,
      settings: {
        slidesToShow: 1.8
      }
    },
    {
      breakpoint: 387,
      settings: {
        slidesToShow: 1.7
      }
    },
    {
      breakpoint: 370,
      settings: {
        slidesToShow: 1.6
      }
    },
    {
      breakpoint: 347,
      settings: {
        slidesToShow: 1.5
      }
    },
    {
      breakpoint: 328,
      settings: {
        slidesToShow: 1.4
      }
    },
  ]
};

export const CarouselSettingsEvents = {
  dots: false,
  infinite: false,
  initialSlide: 0,
  arrows: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1479,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1219,
      settings: {
        slidesToShow: 1.6
      }
    },
    {
      breakpoint: 1011,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 787,
      settings: {
        slidesToShow: 3.8
      }
    },
    {
      breakpoint: 724,
      settings: {
        slidesToShow: 3.4
      }
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 3.2
      }
    },
    {
      breakpoint: 619,
      settings: {
        slidesToShow: 2.9
      }
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2.8
      }
    },
    {
      breakpoint: 543,
      settings: {
        slidesToShow: 2.5
      }
    },
    {
      breakpoint: 489,
      settings: {
        slidesToShow: 2.4
      }
    },
    {
      breakpoint: 476,
      settings: {
        slidesToShow: 2.2
      }
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.9
      }
    },
    {
      breakpoint: 385,
      settings: {
        slidesToShow: 1.8
      }
    },
    {
      breakpoint: 366,
      settings: {
        slidesToShow: 1.6
      }
    },
    {
      breakpoint: 336,
      settings: {
        slidesToShow: 1.5
      }
    },
  ]
}

