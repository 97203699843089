import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { push } from 'redux-first-history';
import { useParams } from 'react-router-dom';
import { isIOS, isMobile } from 'react-device-detect';

import useBookmarks from 'customHooks/useBookmarks';
import { Container } from 'ui/gridSystem';
import { ContentCardUser, Footer, Spinner } from 'ui/components';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { CardsContainer, Section } from 'ui/components/Homepage/Section/style';
import { responsiveSmallCards } from 'utils/carousel';
import {
  GET_PLAYLIST,
  GET_COURSE,
  GET_RELATED,
  GET_PATH
} from 'redux/actions/playlist';
import {
  ADD_BOOKMARK,
  HANDLE_FEEDBACK,
  REMOVE_BOOKMARK
} from 'redux/actions/social';
import { CustomRightArrow, CustomLeftArrow } from 'ui/atoms/CarouselArrows';
import { Button, Tooltip } from 'ui/atoms';
import { MODAL_OPEN } from 'redux/actions';
import NotesModal, { NotesModalId } from 'ui/components/Modals/NotesModal';
import WorkoutModal, { WorkoutModalId } from 'ui/components/Modals/WorkoutModal';
import AssessmentModal from 'ui/components/Modals/AssessmentModal';
import DownloadAppModal from 'ui/components/Modals/DownloadAppModal';
import ReviewModal from 'ui/components/Modals/ReviewModal';
import routes from 'routes';
import { GET_NEWS } from 'redux/actions/fiveMinutes';
import { detailType } from 'utils/common';
import ProText from 'ui/typography/proText';
import VideoPlayer from 'ui/components/VideoPlayer';
import { GET_ACTIVITY } from 'redux/actions/tracking';
import { formatPlaylist, getAuthorization } from 'utils/playlists';
import { getPreviousPath } from 'utils/router';
import app from 'api/app';
import { getToken } from 'utils/token';
import {
  CollectionContainer,
  HeaderWrapper,
  Header,
  HeaderIcon,
  IconInfo,
  Main,
  SecTitle,
  Text,
  Title,
  VideoContainer,
  Video,
  VideoTitle,
  IconBookmark,
  VideoRow,
  BtnContainer,
  VideoCol,
  IconPlay,
  IconClock,
  Completed,
  Like,
  Dislike,
  CollectionItem,
  Index,
  Image,
  Description,
  LikeWrapper,
  SurveyContainer,
  ButtonWrapper,
  IconCheckCustom,
  InnerIcon,
  FirstHalf,
  FirstHalfWrapper,
  ExternalResource,
  Link,
  SharedWrapper,
  HtmlWrapper
} from './style';
import MainContainer from '../style';

const CollectionDetails = ({
  // HOC (connect, state)
  allPlaylists,
  course,
  fiveMinuteCourse,
  idsurvey,
  needid,
  news,
  playlist,
  playlistBody,
  playlistId,
  playlistTitle,
  playlistType,
  previousLocation,
  related,
  router,
  section,
  startFrom,
  survey,
  user,

  // HOC (connect, dispatch)
  addBookmark,
  cleanPlaylist,
  getCourseDetails,
  getNews,
  getPlaylist,
  getPlaylists,
  getRelated,
  getSeconds,
  handleFeedback,
  openModal,
  pushUrl,
  removeBookmark,

  // HOC media queries
  mediaIsTablet,
  mediaIsPhone,
}) => {
  const [pageType, setPageType] = useState(false);
  const [selectedItem, setItem] = useState({});
  const [plCompleted, setPlCompleted] = useState(false);
  const [size, setSize] = useState();
  const [exitFullscreen, setExitFullscreen] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);


  document.addEventListener("webkitfullscreenchange",function() {
    setExitFullscreen(true);
  });

  const { handleBookmark, isBookmarked } = useBookmarks();
  const { type, id, itemId } = useParams();

  useLayoutEffect(() => {
    function updateSize() {
      setSize(
        window.innerWidth -
          document.getElementsByClassName('container')[0]?.offsetWidth
      );
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    const refreshPlaylist = () => {
      if (
        document.visibilityState === 'visible' &&
        type === detailType.PLAYLIST && !document.webkitRequestFullscreen && exitFullscreen
      ) {
        getPlaylist(id);
      }
    };

    document.addEventListener('visibilitychange', refreshPlaylist);
    return () => {
      window.removeEventListener('resize', updateSize);
      document.removeEventListener('visibilitychange', refreshPlaylist);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    getPlaylists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  useEffect(() => {
    if (isMobile && isIOS) openModal(DownloadAppModal.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cleanPlaylist();
    setPageType(type);
    const authorized = getAuthorization(type, id, allPlaylists);
    if (id && ((formatPlaylist(allPlaylists).length > 0) || (type === detailType.NEWS))) { // per le news entro anche se non ho playlist associate
      switch (type) {
        case detailType.PLAYLIST:
          authorized
            ? getPlaylist(id)
            : pushUrl(`${routes.lockedDetails.path}/${id}`);
          break;
        case detailType.NEWS:
          getNews(id);
          break;
        case detailType.COURSE:
          getCourseDetails(id, true);
          break;
        default:
          break;
      }
    }
    return () => {
      setPageType(false);
      cleanPlaylist();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, allPlaylists]);

  useEffect(() => {
    if (news && Object.keys(news).length > 0) {
      setItem(news);
      setLoadingContent(false);
    }
  }, [news]);

  useEffect(() => {
    if (course && Object.keys(course).length > 0) {
      setItem(course);
      setLoadingContent(false);
    }
  }, [course]);

  useEffect(() => {
    if (fiveMinuteCourse && Object.keys(fiveMinuteCourse).length > 0) {
      setItem(fiveMinuteCourse);
      setLoadingContent(false);
    }
  }, [fiveMinuteCourse]);

  useEffect(() => {
    if (selectedItem?.itemid && type !== detailType.NEWS) {
      getSeconds({ itemId: selectedItem?.itemid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeconds, selectedItem]);

  const checkPlCompleted = pl => {
    if (Array.isArray(pl)) {
      const uncompletedItems = pl.filter(item => item.completed === false);
      setPlCompleted(uncompletedItems?.length <= 0);
    }
  };

  useEffect(() => {
    setLoadingContent(true);
    if (type === detailType.PLAYLIST) {
      let activeItem;
      const firstNotCompleted = playlist.find(item => item.completed === false);
      if (playlist.length > 0) {
        if (!itemId) {
          activeItem = firstNotCompleted;
          if (!activeItem) {
            activeItem = playlist[playlist.length - 1];
          }
          pushUrl(
            `${routes.detail.path}/${detailType.PLAYLIST}/${id}/${activeItem?.itemId}`
          );
        } else {
          activeItem = playlist.find(item => item.itemId === itemId);
          if (
            playlistType === 'selection' &&
            !activeItem.completed &&
            activeItem.itemId !== firstNotCompleted.itemId
          ) {
            pushUrl(
              `${routes.detail.path}/${detailType.PLAYLIST}/${id}/${firstNotCompleted.itemId}`
            );
          }
        }
        if (activeItem?.itemId) {
          getCourseDetails(activeItem?.itemId);
          getRelated(needid, activeItem?.itemId);
        }
        checkPlCompleted(playlist);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, itemId, type, playlist]);

  const handleLike = value => {
    const payload = {
      itemType: selectedItem.type,
      itemId: selectedItem.itemid,
      feedback: false,
      reset: false
    };
    if (selectedItem.feedback.currentUserVote >= 0) {
      selectedItem.feedback.currentUserVote === value
        ? (payload.reset = true)
        : (payload.feedback = value !== 0);
    } else {
      payload.feedback = value !== 0;
    }
    handleFeedback(payload);
  };

  const duration = `${Math.round(selectedItem?.duration / 60)} min`;

  const redirectTo = (typeRed, itemIdRed) => {
    let goToType;
    switch (typeRed) {
      case 'learning_object':
        goToType = 'course';
        break;
      case 'news':
        goToType = 'news';
        break;
      default:
        break;
    }

    return `${routes.detail.path}/${goToType}/${itemIdRed}`;
  };

  const showSurvey = () => {
    const bool =
      plCompleted &&
      survey === null &&
      !!idsurvey &&
      idsurvey?.toString() !== '0';
    return bool;
  };

  const showFinalTest = pl => {
    let showTest = true;
    pl.filter(item => !item.finalTest).forEach(item => {
      showTest = showTest && item.completed;
    });
    return showTest;
  };

  const getLabelBySection = sectionLabel => {
    switch (sectionLabel) {
      case 'fiveMinutes':
        return '5 minuti per Te';
      case 'playlist':
        return 'Playlist';
      case 'keepWatching':
        return 'Continua a guardare';
      default:
        return '';
    }
  };

  const onClickCollectionItem = (item) => {
    if (
      item.type !== 'training' ||
      (item.type === 'training' && item.completed === true)
    ) {
      pushUrl(
        `${routes.detail.path}/${detailType.PLAYLIST}/${id}/${item.itemId}`
      );
    }
    if (
      item.type === 'training' &&
      item.completed === false
    ) {
      pushUrl(
        `${routes.detail.path}/${detailType.PLAYLIST}/${id}`
      );
    }
  };

  const relatedData = [
    ...(related.itemsuncompleted || []),
    ...(related.itemscompleted || []),
    ...(related.itemsuntracked || [])
  ];

  if (loadingContent) return <Spinner />;

  return (
    <>
      <HeaderWrapper showSurvey={showSurvey()}>
        <Container>
          <Header showSurvey={showSurvey()}>
            <FirstHalfWrapper>
              <Container>
                <FirstHalf>
                  <HeaderIcon
                    className="icon-arrow-right first"
                    onClick={() => pushUrl(
                      (previousLocation && previousLocation !== router.location.pathname)
                        ? previousLocation
                        : getPreviousPath(router, playlist))
                    }
                  />
                  <Title id="playlist-title">
                    <Text>
                      <ProText
                        type="playlistOvertitle"
                        configuration={{ color: 'white' }}
                      >
                        {getLabelBySection(section || type)}
                        {/* {pageType === 'news' ? '5 MINUTI PER TE' : pageType} */}
                      </ProText>
                    </Text>
                    <Text className="flex-start">
                      <ProText
                        type="playlistTitle"
                        configuration={{ color: 'white' }}
                      >
                        {pageType === detailType.PLAYLIST
                          ? playlistTitle
                          : selectedItem.title}
                      </ProText>
                      {pageType === detailType.PLAYLIST && playlistBody && (
                        <Tooltip text={playlistBody}>
                          <IconInfo className="icon-uniE940" />
                        </Tooltip>
                      )}
                      {plCompleted && !mediaIsPhone && (
                        <Completed>
                          <ProText type="completed">COMPLETATO</ProText>
                        </Completed>
                      )}
                    </Text>
                  </Title>
                </FirstHalf>
              </Container>
            </FirstHalfWrapper>
            {showSurvey() && (
              <SurveyContainer>
                <Text>
                  <ProText
                    type="detailAction"
                    configuration={{ color: 'white' }}
                  >
                    Completa la survey di gradimento
                  </ProText>
                </Text>
                <ButtonWrapper>
                  <Button
                    type="SECONDARY"
                    text="INIZIA"
                    height={35}
                    fontSize={10}
                    onClick={() => openModal(AssessmentModal.id)}
                  />
                </ButtonWrapper>
              </SurveyContainer>
            )}
          </Header>
        </Container>
      </HeaderWrapper>
      <MainContainer
        className={
          pageType === detailType.NEWS || pageType === detailType.COURSE
            ? ''
            : 'p-0'
        }
      >
        <Container>
          <Main>
            <VideoContainer
              className={
                pageType === detailType.NEWS ||
                pageType === detailType.COURSE ||
                mediaIsPhone ||
                mediaIsTablet
                  ? 'col-12 no-padding'
                  : playlist?.length > 1
                    ? 'col-7 no-padding'
                    : 'col-8 no-padding'
              }
            >
              <Video cover={selectedItem?.cover}>
                {selectedItem?.videoid &&
                  selectedItem.videoid !== '0' &&
                  !(isMobile && isIOS) && (
                    <VideoPlayer
                      {...selectedItem}
                      videoId={
                        selectedItem.videoid !== '0'
                          ? selectedItem.videoid
                          : null
                      }
                      startFrom={startFrom}
                    />
                  )}
                {selectedItem?.resourcetype &&
                  selectedItem?.resourcetype.toLowerCase() === 'web' &&
                  selectedItem.videourl && (
                    <ExternalResource>
                      <Button
                        text="CLICCA PER ACCEDERE AL CONTENUTO"
                        type="PRIMARY"
                        hover
                        height={35}
                        fontSize={14}
                        onClick={() =>
                          app.getResource({
                            resource: selectedItem.videourl,
                            name: `${user.firstname} ${user.lastname}`,
                            email: user.email,
                            id: selectedItem?.itemid
                          })
                        }
                      />
                    </ExternalResource>
                  )}
              </Video>

              <VideoTitle>
                <Text>
                  <ProText type="title">{selectedItem?.title}</ProText>
                </Text>
                <IconBookmark
                  className={
                    () => isBookmarked(selectedItem)
                      ? 'icon-icona-salva-attivo selected'
                      : 'icon-icona-salva-active '
                  }
                  onClick={() => { handleBookmark(selectedItem) }}
                />
              </VideoTitle>
              {(pageType === detailType.PLAYLIST ||
                pageType === detailType.COURSE) && (
                <Text>
                  <ProText type="label" configuration={{ color: 'mineShaft' }}>
                  <HtmlWrapper
                     dangerouslySetInnerHTML={{ __html: selectedItem?.body }}/>
                  </ProText>
                </Text>
              )}
              <VideoRow noBorder={pageType === detailType.NEWS}>
                <VideoCol className="flex">
                  {pageType === detailType.NEWS ? (
                    <IconClock className="icon-clock" />
                  ) : (
                    <IconPlay className="icon-play" />
                  )}
                  <Text>
                    <ProDisplay type="label">{duration}</ProDisplay>
                  </Text>
                  {pageType === detailType.PLAYLIST && selectedItem?.completed && (
                    <Completed>
                      <ProText type="completed">COMPLETATO</ProText>
                    </Completed>
                  )}
                </VideoCol>
                <VideoCol className="flex">
                  <Like
                    className={
                      selectedItem?.feedback?.currentUserVote === 1
                        ? 'icon-icona-like selected'
                        : 'icon-icona-like'
                    }
                    onClick={() => handleLike(1)}
                  />
                  <LikeWrapper>
                    <ProText type="label" configuration={{ lineHeight: 1.43 }}>
                      {selectedItem?.feedback?.positive}
                    </ProText>
                  </LikeWrapper>
                  <Dislike
                    className={
                      selectedItem?.feedback?.currentUserVote === 0
                        ? 'icon-icona-like selected selected'
                        : 'icon-icona-like'
                    }
                    onClick={() => handleLike(0)}
                  />
                  <LikeWrapper>
                    <ProText type="label" configuration={{ lineHeight: 1.43 }}>
                      {selectedItem?.feedback?.negative}
                    </ProText>
                  </LikeWrapper>
                </VideoCol>
              </VideoRow>
              {pageType === detailType.NEWS && (
                <Text>
                  <ProText
                    type="label"
                    configuration={{ letterSpacing: '-0.34px' }}>
                    <HtmlWrapper
                        dangerouslySetInnerHTML={{ __html: selectedItem?.body }}/>
                  </ProText>
                </Text>
              )}
              {course?.canuploadexercise ? (
                <VideoRow>
                  <VideoCol>
                    <Text>
                      <ProText type="detailAction">Practice</ProText>
                    </Text>
                    <Text>
                      <ProText
                        type="label"
                        configuration={{ letterSpacing: '-0.34px' }}
                      >
                        Carica il tuo documento compilato
                      </ProText>
                    </Text>
                  </VideoCol>
                  <BtnContainer>
                    <Button
                      text="CARICA"
                      type="UPLOAD"
                      hover
                      height={35}
                      fontSize={10}
                      onClick={() => openModal(WorkoutModalId)}
                    />
                  </BtnContainer>
                </VideoRow>
              ) : null}
              {pageType === detailType.PLAYLIST && (
                <>
                  {selectedItem?.completed && (
                    <VideoRow>
                      <Text>
                        <ProText type="detailAction">
                          Lascia una recensione
                        </ProText>
                      </Text>
                      <ButtonWrapper>
                        {selectedItem.reviewed ? (
                          <Button
                            text="INIZIA"
                            type="UPLOAD"
                            hover
                            height={35}
                            fontSize={10}
                            disabled
                          />
                        ) : (
                          <Button
                            text="INIZIA"
                            type="UPLOAD"
                            hover
                            height={35}
                            fontSize={10}
                            onClick={() => openModal(ReviewModal.id)}
                          />
                        )}
                      </ButtonWrapper>
                    </VideoRow>
                  )}
                  <VideoRow>
                    <VideoCol>
                      <Text>
                        <ProText type="detailAction">Note</ProText>
                      </Text>
                      <Text>
                        <ProText
                          type="label"
                          configuration={{ letterSpacing: '-0.34px' }}
                        >
                          Cattura i tuoi pensieri, idee e domande
                        </ProText>
                      </Text>
                    </VideoCol>
                    <BtnContainer>
                      <Button
                        text="AGGIUNGI"
                        type="UPLOAD"
                        hover
                        height={35}
                        fontSize={10}
                        onClick={() => openModal(NotesModalId)}
                      />
                    </BtnContainer>
                  </VideoRow>
                </>
              )}
              {pageType === detailType.COURSE && (
                <VideoRow>
                  <Text>
                    <ProDisplay type="link">Aggiungi una nota</ProDisplay>
                  </Text>
                  <BtnContainer>
                    <Button
                      text="AGGIUNGI"
                      type="UPLOAD"
                      hover
                      height={35}
                      fontSize={10}
                      onClick={() => openModal(NotesModalId)}
                    />
                  </BtnContainer>
                </VideoRow>
              )}
            </VideoContainer>
            {pageType === detailType.PLAYLIST && playlist?.length > 1 && (
              <CollectionContainer
                className={
                  mediaIsPhone || mediaIsTablet ? 'col-12 p-0 mt-3' : 'col-5'
                }
              >
                {playlist?.map((item, index) => (
                  (!item.finalTest || showFinalTest(playlist)) && (
                    <CollectionItem
                      key={item.itemId}
                      className={
                        item.itemId === selectedItem?.itemid ? 'active' : ''
                      }
                      onClick={() => onClickCollectionItem(item)}
                    >
                      <Index>
                        <ProText
                          type="playlistOvertitle"
                          configuration={{ color: 'slateGray' }}
                        >
                          {index + 1}
                        </ProText>
                      </Index>
                      <Image src={item.cover} />
                      <Description>
                        <Text>
                          <ProDisplay type="titleCollectionItem">
                            {item.title}
                          </ProDisplay>
                        </Text>
                        <Text className="flex">
                          <IconPlay className="icon-play small" />
                          <ProDisplay type="tableLabel">
                            {Math.round(item.duration / 60)} min
                          </ProDisplay>
                        </Text>
                      </Description>
                      {item.completed && (
                        <IconCheckCustom>
                          <InnerIcon className="icon-check" />
                        </IconCheckCustom>
                      )}
                    </CollectionItem>
                  )
                ))}
              </CollectionContainer>
            )}
          </Main>

          {pageType === detailType.PLAYLIST && relatedData?.length > 0 && (
            <Section>
              <SecTitle>
                <Text>
                  <ProText
                    type="playlistTitle"
                    configuration={{ lineHeight: 1.11, fontWeight: 'bold' }}
                  >
                    Potrebbero interessarti anche
                  </ProText>
                </Text>
              </SecTitle>
              {Object.keys(related).length && (
                <CardsContainer>
                  <Carousel
                    swipeable
                    draggable={!!(mediaIsPhone || mediaIsTablet)}
                    responsive={responsiveSmallCards}
                    customRightArrow={
                      mediaIsPhone || mediaIsTablet ? (
                        <></>
                      ) : (
                        <CustomRightArrow size={size} />
                      )
                    }
                    customLeftArrow={
                      mediaIsPhone || mediaIsTablet ? (
                        <></>
                      ) : (
                        <CustomLeftArrow size={size} />
                      )
                    }
                    containerClass="container react-multi-carousel-list pl-0 pb-4"
                  >
                    {relatedData.map(item => (
                      <ContentCardUser
                        key={item.itemId}
                        type={detailType.NEWS}
                        data={item}
                        onClick={() =>
                          pushUrl(redirectTo(item.type, item.itemId))
                        }
                      />
                    ))}
                  </Carousel>
                </CardsContainer>
              )}
            </Section>
          )}
          {pageType === detailType.PLAYLIST && (
            <>
              {showSurvey() ? (
                <AssessmentModal
                  itemId={itemId}
                  surveyId={idsurvey}
                  playlist={playlist}
                  playlistId={playlistId}
                />
              ) : null}
              <ReviewModal itemId={selectedItem.itemid} />
            </>
          )}
          <NotesModal />
          <WorkoutModal course={{
            id: course?.itemid,
            title: course?.title,
            uploadType: 'PRACTICE',
          }} />
          <DownloadAppModal />
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

CollectionDetails.defaultProps = {
  playlist: [],
  course: {},
  news: {}
};

CollectionDetails.propTypes = {
  // HOC (connect, state)
  allPlaylists: PropTypes.object,
  course: PropTypes.object,
  fiveMinuteCourse: PropTypes.object,
  idsurvey: PropTypes.number,
  needid: PropTypes.number,
  news: PropTypes.object,
  playlist: PropTypes.array,
  playlistBody: PropTypes.string,
  playlistId: PropTypes.number,
  playlistTitle: PropTypes.string,
  playlistType: PropTypes.string,
  previousLocation: PropTypes.string,
  related: PropTypes.object,
  router: PropTypes.object,
  section: PropTypes.string,
  startFrom: PropTypes.string,
  survey: PropTypes.object,
  user: PropTypes.object,

  // HOC (connect, dispatch)
  addBookmark: PropTypes.func,
  cleanPlaylist: PropTypes.func,
  getCourseDetails: PropTypes.func,
  getNews: PropTypes.func,
  getPlaylist: PropTypes.func,
  getPlaylists: PropTypes.func,
  getRelated: PropTypes.func,
  getSeconds: PropTypes.func,
  handleFeedback: PropTypes.func,
  openModal: PropTypes.func,
  pushUrl: PropTypes.func,
  removeBookmark: PropTypes.func,

  // HOC media queries
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsPhone: PropTypes.bool.isRequired,
};

export default withMediaQueries(
  connect(
    state => {
      const { news, course: fiveMinuteCourse } = state.fiveMinutes;
      const {
        listoflo: playlist,
        needid,
        idsurvey,
        survey,
        itemid: playlistId,
        title: playlistTitle,
        body: playlistBody,
        type: playlistType
      } = state.playlist.playlistSelected;
      const {
        course,
        related,
        section,
        playlists: {
          playlistsHistory: previousLocation,
          yourpathprogress: allPlaylists
        }
      } = state.playlist;
      const { startFrom } = state.tracking;

      const { user } = state.authentication;

      const { router } = state;

      return {
        allPlaylists,
        course,
        fiveMinuteCourse,
        idsurvey,
        needid,
        news,
        playlist,
        playlistBody,
        playlistId,
        playlistTitle,
        playlistType,
        previousLocation,
        related,
        router,
        section,
        startFrom,
        survey,
        user,
      };
    },
    dispatch => ({
      addBookmark: payload =>
        dispatch({ type: ADD_BOOKMARK._REQUEST, payload }),
      cleanPlaylist: () =>
        dispatch({ type: GET_PLAYLIST._SUCCESS, data: {} }),
      getCourseDetails: (id, isFiveMinute = false) =>
        dispatch({ type: GET_COURSE._REQUEST, id, isFiveMinute }),
      getNews: id =>
        dispatch({ type: GET_NEWS._REQUEST, id }),
      getPlaylist: id =>
        dispatch({ type: GET_PLAYLIST._REQUEST, id }),
      getPlaylists: () =>
        dispatch({ type: GET_PATH._REQUEST }),
      getSeconds: payload =>
        dispatch({ type: GET_ACTIVITY._REQUEST, payload }),
      getRelated: (needId, itemId) =>
        dispatch({ type: GET_RELATED._REQUEST, needId, itemId }),
      handleFeedback: payload =>
        dispatch({ type: HANDLE_FEEDBACK._REQUEST, payload }),
      openModal: id =>
        dispatch({ type: MODAL_OPEN, id }),
      pushUrl: url =>
        dispatch(push(url)),
      removeBookmark: payload =>
        dispatch({ type: REMOVE_BOOKMARK._REQUEST, payload }),
      
    })
  )(CollectionDetails)
);
