import { takeLatest, put, call, select } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_CLOSE,
  MODAL_OPEN,
} from 'redux/actions';
import {
  PROFILE_ASSOC_SUBSCRIPTION_PAYMENT,
  PROFILE_DATA_ACCOUNT_GET,
  PROFILE_DATA_COMPANY_GET,
  PROFILE_DATA_PAYMENTS_GET,
  PROFILE_DATA_COMPANY_UPDATE,
  DELETE_PAYMENT,
  DELETE_PAYMENT_CONFIRM_ACTION,
  PROFILE_GET_DATA_PRIVACY,
  PROFILE_POST_DATA_PRIVACY,
} from 'redux/actions/profile';

import profile from 'api/profile';
import routes from 'routes';
import {
  AGREEMENTS_UPDATE, SIGNUP_ORDER_PREVIEW_ORDER_ZUORA,
} from 'redux/actions/signup';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import subscriptions from 'api/subscriptions';
import signup from 'api/signup';
import billings from 'api/billings';
import { BILLING_GET_PAYMENT_METHODS } from 'redux/actions/billings';
import PaymentUpdateModal from 'ui/components/Modals/PaymentUpdateModal';
import DeletePaymentConfirmActionModal from 'ui/components/Modals/DeletePaymentConfirmActionModal';
import SuccessModal from 'ui/components/Modals/SuccessModal';
import { isManager, isSimpleUser } from 'utils/roles';
import { AUTHENTICATION_SIGNOUT, REGISTER_PROSPECT } from 'redux/actions/authentication';
import onBoarding from 'api/onBoarding';
import { ONBOARDING_GET_NOT_CONFIGURED_ASSETS } from 'redux/actions/onBoarding';
import { createCartPayloadFromLocalCart } from '../../utils/common';

function* profileDataAccountRedirect(login = false) {
  try {
    yield put({ type: LOADER_OPEN });
    const { clientEnabled, email } = yield select(state => state.authentication.user);
    try {
      // eslint-disable-next-line no-unused-vars
      const registerProspectResponse = yield call(onBoarding.registerProspect, email);
      if (registerProspectResponse.status === 200) {
        yield put({ type: ONBOARDING_GET_NOT_CONFIGURED_ASSETS._REQUEST });
      }
    } catch (e) {
      yield put({ type: ONBOARDING_GET_NOT_CONFIGURED_ASSETS._REQUEST });
    } finally {
      yield put({ type: REGISTER_PROSPECT._SUCCESS });
      yield put({ type: PROFILE_GET_DATA_PRIVACY._REQUEST });

      if (
        window.location.pathname.split('/').at(-1)
        === routes.setFirstPasswordFromBank.path.split('/').at(-1)
      ) {
        yield put(push(routes.confirmFromBank.path));
      } else if (isManager(clientEnabled)) {
        yield put(push(routes.mySubscription.path));
      } else if (isSimpleUser(clientEnabled)) {
        yield put(push(routes.homepage.path));
      }
    }
  } catch (err) {
    // yield put({
    //   type: MODAL_OPEN,
    //   id: ModalErrors.id,
    //   payload: { errorText: err?.message }
    // });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > profileDataAccountRedirect', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
    if (login) yield put({ type: AUTH_LOGIN._SUCCESS });
  }
}

function* profileDataCompanyGet({ orderPreview = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: stateCompany } = yield select(state => state.profile);
    if (!stateCompany) {
      const { user: { userId, organizationId: orgId } = {} } = yield select(state => state.authentication);
      if (userId && orgId) {
        const { data: company = {} } = yield call(
          profile.getProfileDataCompany,
          { orgId, userId }
        );
        yield put({ type: PROFILE_DATA_COMPANY_GET._SUCCESS, company });
        const { cart: { products = {} } = {} } = yield select(state => state.signup);
        if (Object.keys(products).length && orderPreview) {
          const payload = createCartPayloadFromLocalCart(products);
          yield put({ type: SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._REQUEST, payload: { items: payload } });
        }
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > profileDataCompanyGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileDataCompanyGetWatch() {
  yield takeLatest(PROFILE_DATA_COMPANY_GET._REQUEST, profileDataCompanyGet);
}

function* profileDataCompanyUpdate({ form, goToBillings = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company } = yield select(state => state.profile);
    const body = {
      //organizationId: company.organizationid,
      title: company.title,
      vatNumber: company.vatnumber,
      sdiNumber: company.sdinumber,
      street: company.street,
      city: company.city,
      district: company.district,
      zipCode: company.zipcode,
      industryId: company.industryId || company.industryid,
      ...form,
    };
    const response = yield call(profile.updateProfileCompany, body);
    const { data: { response: newCompany } = {} } = response;
    yield put({
      type: PROFILE_DATA_COMPANY_UPDATE._SUCCESS,
      // eslint-disable-next-line no-sequences
      company: Object.keys(newCompany).reduce((c, k) => ((c[k.toLowerCase()] = newCompany[k]), c), {}),
    });
    goToBillings ? yield put(push(routes.billings.path)) : yield put(push(routes.managerProfile.path));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('sagas > profile > profileDataCompanyUpdate', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileDataCompanyUpdateWatch() {
  yield takeLatest(
    PROFILE_DATA_COMPANY_UPDATE._REQUEST,
    profileDataCompanyUpdate
  );
}

function* profileDataAccountGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    if (usrId) {
      const {
        data: {
          username,
          userid,
          givenname,
          familyname,
          email,
          role,
          pictureid,
          organizationid,
          prospectid,
          industryid,
          clientenabled,
          domainlist,
          imageurl,
        } = {},
      } = yield call(profile.getProfileDataAccount, { userId: usrId });
      yield put({
        type: PROFILE_DATA_ACCOUNT_GET._SUCCESS,
        response: {
          userId: username || userid, //workaround
          firstname: givenname,
          lastname: familyname,
          email,
          role,
          pictureId: pictureid,
          organizationId: organizationid,
          prospectId: prospectid,
          industry: industryid,
          clientEnabled: clientenabled,
          domainList: domainlist,
          imageurl,
        },
      });
      if (!clientenabled) {
        yield put({ type: AUTH_LOGIN._SUCCESS });
        yield put(push(routes.choosePortal.path));
      } else {
        yield profileDataAccountRedirect(true);
      }
    } else {
      yield put({
        type: AUTHENTICATION_SIGNOUT._REQUEST,
        goTo: routes.access.path,
      });
    }
  } catch (err) {
    if (err?.response?.data?.errorMessage?.indexOf('Missing fields') >= 0) {
      yield put({ type: AUTH_LOGIN._SUCCESS });
      yield put(push(routes.compileManagerProfile.path));
    }
    // eslint-disable-next-line no-console
    console.log('sagas > profile > profileDataAccountGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileDataAccountGetWatch() {
  yield takeLatest(PROFILE_DATA_ACCOUNT_GET._REQUEST, profileDataAccountGet);
}

function* profileDataPaymentsGet({ loadMore = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { company: { zuoraaccountnumber: zuoraAccountNumber } = {}, billings: { orders: list_orders = [], nextpage: nextPage } = {} } = yield select(state => state.profile);
    if (zuoraAccountNumber && !list_orders.length) {
      const { data: { payments: orders, nextpage, nextpagepresent } = {} } = yield call(billings.getCommerceOrdersZuora, { zuoraAccountNumber });
      yield put({ type: PROFILE_DATA_PAYMENTS_GET._SUCCESS, payments: { orders, nextpage, nextpagepresent } });
    } else if (zuoraAccountNumber && loadMore) {
      const { data: { payments: orders, nextpage, nextpagepresent } = {} } = yield call(billings.getCommerceOrdersZuora, { zuoraAccountNumber, nextPageId: nextPage.id });
      yield put({ type: PROFILE_DATA_PAYMENTS_GET._SUCCESS, payments: { orders, nextpage, nextpagepresent } });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > profileDataPaymentsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileDataPaymentsGetWatch() {
  yield takeLatest(PROFILE_DATA_PAYMENTS_GET._REQUEST, profileDataPaymentsGet);
}

function* replacePaymentMethod({ paymentId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { selected_payment: { id: subscriptionId } = {} } = yield select(
      state => state.profile
    );
    if (userId && paymentId) {
      yield call(subscriptions.replacePaymentMethod, {
        paymentId,
        userId,
        subscriptionId,
      });
      yield put({ type: MODAL_OPEN, id: PaymentUpdateModal.id });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > associatePaymentMethod', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getNewestPaymentMethod() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { data: { data: payment_methods = [] } = {} } = yield call(billings.getPaymentMethods, { userId });
    yield put({ type: BILLING_GET_PAYMENT_METHODS._SUCCESS, payment_methods });
    let newPaymentMethod = 0;
    (payment_methods || []).forEach(({ id } = {}) => {
      if (Number(id) > newPaymentMethod) newPaymentMethod = id;
    });
    if (Number(newPaymentMethod)) {
      yield replacePaymentMethod({ paymentId: newPaymentMethod });
    } else {
      console.log('No new payment method found', newPaymentMethod, payment_methods);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > getNewestPaymentMethod', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* checkWalletStatus() {
  try {
    yield put({ type: LOADER_OPEN });

    const { timestamp } = yield select(state => state.app);
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const walletStatus = yield call(signup.getWalletStatus, {
      timestamp,
      userId,
    });

    if (walletStatus) {
      yield getNewestPaymentMethod();
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > checkWalletStatus', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileAssociateSubscriptionPayment({ isNewPayment = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      selected_gateway: { type: gateway_type = '', id: gateway_id = '' } = {},
    } = yield select(state => state.billings);

    if (gateway_type === 'user_payment_gateway' && !isNewPayment) {
      // user payment method flow
      yield replacePaymentMethod({ paymentId: gateway_id });
    } else {
      // new payment method flow
      yield checkWalletStatus();
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > setNewUserSubscriptionsPayment', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* profileAssociateSubscriptionPaymentWatch() {
  yield takeLatest(PROFILE_ASSOC_SUBSCRIPTION_PAYMENT._REQUEST, profileAssociateSubscriptionPayment);
}

function* deletePaymentMethod({ paymentId = '' }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (paymentId) {
      yield put({
        type: MODAL_OPEN,
        id: DeletePaymentConfirmActionModal.id,
        payload: { paymentId },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > deletePaymentMethod', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deletePaymentMethodWatch() {
  yield takeLatest(DELETE_PAYMENT, deletePaymentMethod);
}

function* deletePaymentMethodConfirmAction({ paymentId = '' }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (paymentId) {
      yield put({ type: MODAL_CLOSE });
      const { status } = yield call(profile.deletePaymentMethod, { paymentId });
      if (status.toString() === '202' || status.toString() === '204') {
        yield put({
          type: MODAL_OPEN,
          id: SuccessModal.id,
          payload: {
            title: 'Metodo di pagamento rimosso',
            description:
              'Il metodo di pagamento selezionato è stato rimosso correttamente',
          },
        });
        yield put({ type: BILLING_GET_PAYMENT_METHODS._REQUEST });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > deletePaymentMethodConfirmAction', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deletePaymentMethodConfirmActionWatch() {
  yield takeLatest(DELETE_PAYMENT_CONFIRM_ACTION, deletePaymentMethodConfirmAction);
}

function* getDataPrivacy() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(state => state.authentication);
    if (!userId) return;
    const { data: { dataprivacy: payload }, status } = yield call(profile.getDataPrivacy, { userId });
    if (status === 200 && payload && Object.keys(payload).length > 0) {
      yield put({ type: PROFILE_GET_DATA_PRIVACY._SUCCESS, payload });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > getDataPrivacy', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getDataPrivacyWatch() {
  yield takeLatest(PROFILE_GET_DATA_PRIVACY._REQUEST, getDataPrivacy);
}

function* postDataPrivacy({ agreements, privacyData }) {
  try {
    if (privacyData) {
      yield put({ type: LOADER_OPEN });

      const { privacy } = yield select(state => state.profile);

      const newPrivacies = (
        Object.values(agreements) || []
      ).map(({ valid, ...data }) => ({ ...data }));

      const body = {
        id: privacy.id,
        dataPrivacylist: [
          ...privacy.dataPrivacylist.filter(
            p => !newPrivacies.find(np => np.type === p.type)
          ),
          ...newPrivacies.map(({ authorize, ...np }) => ({
            ...np,
            authorized: authorize,
          })),
        ],
      };

      const { status } = yield call(profile.postDataPrivacy, { body });
      if (status === 204) {
        yield put({
          type: PROFILE_POST_DATA_PRIVACY._SUCCESS,
          payload: { ...body, creationDate: privacy.creationDate },
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > profile > postDataPrivacy', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* postDataPrivacyWatch() {
  yield takeLatest(AGREEMENTS_UPDATE, postDataPrivacy);
}

export default [
  profileDataAccountGetWatch(),
  profileDataCompanyGetWatch(),
  profileDataPaymentsGetWatch(),
  profileAssociateSubscriptionPaymentWatch(),
  profileDataCompanyUpdateWatch(),
  deletePaymentMethodWatch(),
  deletePaymentMethodConfirmActionWatch(),
  getDataPrivacyWatch(),
  postDataPrivacyWatch(),
];
