import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Footer, Grid } from 'ui/components';
import ProDisplay from 'ui/typography/proDisplay';
import routes from 'routes';
import { GET_FIVE_MINUTES } from 'redux/actions/fiveMinutes';
import { detailType } from 'utils/common';
import { Head, IconBack, Title, Text } from './style';
import MainContainer from '../style';

const AllFiveMinutes = ({ pushUrl, fiveMinutes, getFiveMinutes }) => {
  useEffect(() => {
    getFiveMinutes();
  }, [getFiveMinutes]);

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">News</ProDisplay>
              <Text>
                <ProDisplay
                  type="tableLabel"
                  configuration={{ color: 'placeholder' }}
                >
                  Resta aggiornato con le più importanti novità
                </ProDisplay>
              </Text>
            </Title>
          </Head>
          <Grid
            elements={fiveMinutes}
            isNews
            location={detailType.NEWS}
            section="fiveMinutes"
          />
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

AllFiveMinutes.propTypes = {
  // HOC (connect, state)
  pushUrl: PropTypes.func,
  getFiveMinutes: PropTypes.func,
  news: PropTypes.array,
  relatedlo: PropTypes.array

  // HOC (connect, dispatch)
};

export default withMediaQueries(
  connect(
    state => {
      const { fiveMinutes } = state.fiveMinutes;
      return {
        fiveMinutes,
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getFiveMinutes: () => dispatch({ type: GET_FIVE_MINUTES._REQUEST })
    })
  )(AllFiveMinutes)
);
