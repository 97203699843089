import { getDomainMock, shouldUseMock } from './mock';
import { apiCall, baseUrl, extendUrl } from './config';

export default {
  getDomain: () => {
    if (shouldUseMock()) {
      return getDomainMock();
    }
    return apiCall(`${baseUrl}${extendUrl}filtered_domain`, 'GET', null, {
      isDrupal: true
    });
  }
};
