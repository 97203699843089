import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { SubscriptionPlanInterface } from 'interface/subscriptions';
import { SET_SELECTED_SUBSCRIPTION } from 'redux/actions/subscriptions';
import routes from 'routes';

import { Button, PlaceholderImage } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { calcPeriod, priceFormat } from 'utils/price-format';
import {
  ContentCardContainer,
  Header,
  Contents,
  Title,
  Icon,
  Image,
  Span,
  TotalPrice,
  CardFooter,
  Price,
  SocialContainer,
  ProfilePictures,
  FirstPic,
  SecondPic,
  Text,
  BtnWrapper
} from './style';
import { ProfilePicture } from '..';

const ContentCard = ({
  social,
  mediaIsTablet,
  mediaIsPhone,
  plan,
  pushUrl,
  setSelectedSubscription,
  mediaIsLaptop,
  cards,
  logo
}) => {
  const { name, cover_image: image, price, num_content, learning_contents, users, product_title } = plan;
  const buySubscription = () => {
    setSelectedSubscription(plan);
    pushUrl(routes.premiumSubscriptions.path);
  };

  const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);


  const getSocialUsers = () => (
    <SocialContainer>
      <ProfilePictures>
        {users?.length > 0 && (
          <>
            <FirstPic>
              <ProfilePicture profile={users[0]} fontSizeInitials={12} />
            </FirstPic>
            {users[1] && (
              <SecondPic>
                <ProfilePicture profile={users[0]} fontSizeInitials={12} />
              </SecondPic>
            )}
          </>
        )}
      </ProfilePictures>
      <Text>
        <ProDisplay type="label" configuration={{ letterSpacing: '0.22px' }}>
          {users?.length > 1 ? (
            <>
              <b>{`${capitalize(users[0]?.firstName)} ${capitalize(
                users[0]?.lastName
              )}`}</b>
              e <b>altri {users?.length - 1}</b>&nbsp;  hanno segnalato interesse per
              questa playlist
            </>
          ) : (
            <>
              <b>{`${capitalize(users[0]?.firstName)} ${capitalize(
                users[0]?.lastName
              )}`}</b>{' '}&nbsp;
              ha segnalato interesse per questa playlist
            </>
          )}
        </ProDisplay>
      </Text>
    </SocialContainer>
  );

  return (
    <ContentCardContainer className={social ? 'social' : ''}>
      <Header>
        {social && getSocialUsers()}
        {image ? <Image src={image} /> : <PlaceholderImage logo={logo} />}

          <Title>
            <ProDisplay
              type="titleContentCard"
              ellipsis={2}
            >{
              product_title ? product_title : name
            }</ProDisplay>
          </Title>

        {name !== 'Base' && (
          <Contents>
            <Icon className="icon-collection" />
            <Span>
              <ProText
                type="label"
                configuration={{ letterSpacing: '-0.34px' }}
              >
                {num_content || learning_contents.length ||  '0'} Playlist
              </ProText>
            </Span>
          </Contents>
        )}
      </Header>
      <CardFooter>
        {price ? (
          <TotalPrice>
            <Price>
              <ProDisplay type="title">
                {Math.trunc(priceFormat(price))}
                <small> €</small>
              </ProDisplay>
            </Price>
            <ProDisplay
              type="1"
              configuration={{
                fontSize:
                  mediaIsTablet || mediaIsPhone || mediaIsLaptop ? '16' : '22'
              }}
            >
              {' '}
              {calcPeriod(cards || [])}
            </ProDisplay>
          </TotalPrice>
        ) : (
          <div />
        )}

        <BtnWrapper>
          <Button
            text="Acquista"
            type={Button.TYPE.PRIMARY}
            onClick={buySubscription}
          />
        </BtnWrapper>
      </CardFooter>
    </ContentCardContainer>
  );
};

ContentCard.propTypes = {
  plan: SubscriptionPlanInterface,
  social: PropTypes.bool,
  logo: PropTypes.string,

  //HOC (connect, dispatch)
  setSelectedSubscription: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,
  cards: PropTypes.array
};

export default connect(
  state => {
    const { logo_white: logo } = state.domain.brand;
    return { logo };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    setSelectedSubscription: plan =>
      dispatch({ type: SET_SELECTED_SUBSCRIPTION, plan })
  })
)(withMediaQueries(ContentCard));
