import styled from 'styled-components/macro';

export const RightArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary.base};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  }
`;
export const LeftArrow = styled(RightArrow)`
  transform: rotate(180deg);
`;

export const Icon = styled.div`
  font-size: 30px;
  line-height: 38px;
  color: ${props => props.theme.colors.primary.base};
`;

export const RightArrowWrapper = styled.div`
  height: fit-content;
  position: absolute;
  right: ${props => `calc(-20px + ${props.offset})`};
  display: flex;
  justify-content: flex-start;
`;
export const LeftArrowWrapper = styled(RightArrowWrapper)`
  right: auto;
  left: ${props => `calc(-20px + ${props.offset})`};
  justify-content: flex-end;
`;
