import {
  addNoteMock,
  editNoteMock,
  getNotesMock,
  shouldUseMock,
  getUsersMock,
  uploadFileMock,
  deleteNoteMock,
  addPartecipantsMock,
  addCommentMock
} from './mock';
import { apiCall, baseUrl, extendUrl, socialPrefix } from './config';

export default {
  getNotes: userId => {
    if (shouldUseMock()) {
      return getNotesMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}notes?comments=true`,
      'GET'
    );
  },
  addNote: payload => {
    if (shouldUseMock()) {
      return addNoteMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}note`,
      'POST',
      payload
    );
  },
  editNote: payload => {
    if (shouldUseMock()) {
      return editNoteMock();
    }
    return apiCall(`${socialPrefix}${baseUrl}${extendUrl}note`, 'PUT', payload);
  },
  getUsers: (userId, organizationId, nextPage) => {
    if (shouldUseMock()) {
      return getUsersMock(nextPage);
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}users/getall?orgId=${organizationId}&items=10&userId=${userId}&nextPage=${nextPage}`,
      'GET'
    );
  },
  uploadFile: (userId, payload) => {
    if (shouldUseMock()) {
      return uploadFileMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}uploadFile?profile=false`,
      'POST',
      payload
    );
  },
  deleteNote: (id, userId) => {
    if (shouldUseMock()) {
      return deleteNoteMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}note?idNote=${id}`,
      'DELETE',
      {
        headers: { 'Content-Type': 'application/json' },
        data: {}
      }
    );
  },
  addPartecipants: (noteId, userId, body) => {
    if (shouldUseMock()) {
      return addPartecipantsMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}note/v2/partecipants?idNote=${noteId}`,
      'POST',
      body
    );
  },
  addComment: payload => {
    if (shouldUseMock()) {
      return addCommentMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}note/comment`,
      'POST',
      payload
    );
  }
};
