import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import { ContentCard } from 'ui/components/index';
import ProText from 'ui/typography/proText';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  CATALOG_PRODUCT_GET,
  TEAMS_INTERESTS_GET,
} from 'redux/actions/subscriptions';
import { TeamsInterestContainer } from './style';
import MainContainer from '../../../style';
import { SubTitle } from '../Recommended/style';

const TeamsInterest = ({
  teams_interests,
  mediaIsPhone,
  catalog,
  getTeamsInterests,
  getProductCatalog,
}) => {
  const [iterests, setInterests] = useState([]);

  useEffect(() => {
    getProductCatalog();
    getTeamsInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const out = [];
    if (teams_interests && catalog) {
      Object.keys(teams_interests).forEach((int, id) => {
        const prod = catalog.find(c =>
          c.learning_contents.length >= 1
          && (
            c.learning_contents.find(lc => (
                (lc.type === 'collection' || lc.type === 'selection')
              && lc?.nid?.toString() === int?.toString()
            ))
          )
        );
        if (prod){
          prod.users = teams_interests[int].users;
          out.push(prod);
        }
      });
    }
    setInterests(out.filter(o => o && o.price));
  }, [teams_interests, catalog]);

  return (
    <MainContainer noMenu>
      <Container>
        <SubTitle>
          <ProText configuration={{ fontSize: mediaIsPhone ? 16 : 18 }}>
            Sblocca le playlist premium segnalate dal tuo team
          </ProText>
        </SubTitle>
        <TeamsInterestContainer>
          {(iterests || []).map((plan = {}, i) => (
            <ContentCard
              key={`content-card-${plan.content_id}-${i}`}
              plan={{
                ...plan,
                num_content: plan.count_content,
                cover_image: plan.cover,
                name: plan.title,
                users: plan.users,
                price: plan.price_number || plan.price || 0.0,
              }}
              social
            />
          ))}
        </TeamsInterestContainer>
      </Container>
    </MainContainer>
  );
};

TeamsInterest.propTypes = {
  //HOC (connect, dispatch)
  teams_interests: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  mediaIsPhone: PropTypes.bool.isRequired,
  catalog: PropTypes.array,
  getTeamsInterests: PropTypes.func,
  getProductCatalog: PropTypes.func,
};

export default connect(
  state => {
    const { teams_interests, catalog } = state.subscriptions;
    return {
      teams_interests,
      catalog,
    };
  },
  dispatch => ({
    getTeamsInterests: () =>
      dispatch({
        type: TEAMS_INTERESTS_GET._REQUEST,
      }),
    getProductCatalog: () =>
      dispatch({
        type: CATALOG_PRODUCT_GET._REQUEST,
      }),
  })
)(withMediaQueries(TeamsInterest));
