  import React, { useEffect } from 'react';
  import { useParams } from 'react-router-dom';
  import { connect } from 'react-redux';
  import { push } from 'redux-first-history';
  import PropTypes from 'prop-types';
  import { withMediaQueries } from 'hoc/withMediaQueries';

  import Carousel from 'react-multi-carousel';
  import 'react-multi-carousel/lib/styles.css';

  import { Col, Row } from 'ui/gridSystem';
  import { CustomRightArrow, CustomLeftArrow } from 'ui/atoms/CarouselArrows';
  import { ContentCardUser, Footer } from 'ui/components';
  // import AssessmentModal from 'ui/components/Modals/AssessmentModal';

  import { MODAL_OPEN } from 'redux/actions';
  import { GET_PACKAGE, SET_PLAYLIST_HISTORY, SET_SECTION } from 'redux/actions/playlist';
  import routes from 'routes';

  import ProDisplay from 'ui/typography/proDisplay';
  import ProText from 'ui/typography/proText';
  import TYPES from 'ui/typography/pro.types';
  import { Spinner } from 'ui/components';
  import PlaceholderImg from 'ui/assets/img/S4C_THUMBNAIL.png';

  import { responsiveLargeCards } from 'utils/carousel';
  import {secsToTime} from 'utils/duration-format';
  import { detailType, unescape } from 'utils/common';

  import Pdf from 'ui/atoms/IconFiles/Pdf';
  import Excel from 'ui/atoms/IconFiles/Excel';
  import Word from 'ui/atoms/IconFiles/Word';
  import Powerpoint from 'ui/atoms/IconFiles/Powerpoint';

  import MainContainer from '../style';
  import {
  PackageHeader,
  Section,
  Container,
  Link,
  PackageProgresss,
  PackageFooterCard, NoMaterials
} from './style';
  import IconBigDownload from "../../ui/atoms/IconBigDownload";
  import {
  Document,
  Image as ImagePdf,
  Page, PDFDownloadLink, StyleSheet,
  Text as TextPdf,
  View
} from "@react-pdf/renderer";

  import DigitedLogo from "../../ui/assets/img/Logo_Digited.png";
  import IntesaLogo from "../../ui/assets/img/Logo_Intesa.png";
  import SfondoPDF from "../../ui/assets/img/SfondoPDF.jpeg";
  import {ButtonWrapper, SurveyContainer} from "../CollectionDetails/style";
  import {Button, Tooltip} from "../../ui/atoms";
  import AssessmentModal from "../../ui/components/Modals/AssessmentModal";

import {
  HeaderWrapper,
  FirstHalfWrapper,
  FirstHalf,
  HeaderIcon,
  Title,
  Text,
  IconInfo,
} from 'pages/CollectionDetails/style';

  const PackageDetails = ({
    // HOC store
    assetDetail,
    packageInfo,
    profile,
    router,
    // HOC dispatch
    openModal,
    getPackage,
    pushUrl,
    setPlaylistHistory,
    setSection,
    // HOC media queries
    currentViewport,
    viewportTypes,
  }) => {
    const { id } = useParams();

    useEffect(() => {
      // TODO: prefetch from home?
      getPackage(id);
    }, [id, getPackage])

    const mergeContents = (...contents) => [].concat(...contents.filter(Array.isArray));

    const contents = mergeContents(
      assetDetail.collection?.items,
      assetDetail.event?.items,
      assetDetail.selection?.items
    );

    contents.sort((a, b) => a.weight < b.weight ? -1 : 1);

    // const parseFraction = (fracString, position=0) => {
    //   if (!fracString) return 0;
    //   return parseInt(fracString.split('/')[position])
    // };
    // const progress = (
    //   (
    //     parseFraction(assetDetail.collection?.progress, 0)
    //     + parseFraction(assetDetail.selection?.progress, 0)
    //     + parseFraction(assetDetail.event?.progress, 0)
    //   ) / (
    //     parseFraction(assetDetail.collection?.progress, 1)
    //     + parseFraction(assetDetail.selection?.progress, 1)
    //     + parseFraction(assetDetail.event?.progress, 1)
    //   )
    // );

    const openDetails = ({ type, itemId }) => {
      if (type === detailType.PLAYLIST) {
        setSection('playlist');
        setPlaylistHistory(router.location.pathname);
        pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
      } else {
        pushUrl(`${routes.event.path}/${itemId}`)
      }
    }

// Create styles
    const stylespdf = StyleSheet.create({
      page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        display: 'flex',
        position: 'relative',
        fontWeight: 'light'
      },
      section: {
        margin: '60px 50px',
        padding: 20,
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        height: 410,
        backgroundColor: 'rgba(30,30,27, 0.9)'
      },
      bgImg: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0
      },
      cornerRightTop:{
        position: 'absolute',
        width: 80,
        height: 80,
        top: 0,
        right: 0,
        borderTop: '30px solid #f2284f',
        borderRight: '30px solid #f2284f',
      },
      cornerLeftBottom:{
        position: 'absolute',
        width: 80,
        height: 80,
        bottom: 67,
        left: 0,
        borderBottom: '30px solid #f2284f',
        borderLeft: '30px solid #f2284f',
      },
      footer: {
        backgroundColor: '#ffffff',
        height: 70,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0 40px'
      },
      logoFooter: {
        height: 50,
        padding: 10
      },
      logoFooterDx: {
        height: 60,
        padding: 10
      },
      textSmall:{
        color: '#ffffff',
        fontSize: 12
      },
      textMedium:{
        color: '#ffffff',
        fontSize: 25
      },
      textBig:{
        color: '#ffffff',
        fontStyle: 'normal',
        fontSize: 50,
        fontWeight: 'bold',
        margin: 10
      },
      textBigItalic:{
        color: '#ffffff',
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: 40,
        margin: '10px 50px',
        borderBottom: '1px solid #ffffff',
        width: '100%',
        display: 'block',
        textAlign: 'center'
      },
      sectionLeadershipContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '50px 0 20px'
      },
      sectionLeadership: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }
    });

    const guestUsers = ['alessandro.battiston@intesasanpaolo.com', 'giovanni.martino2@intesasanpaolo.com', 'maurizio.negri2@intesasanpaolo.com', 'test-inibizione@yopmail.com'];

    const Certificate = () => (
        <Document>
          <Page size="A4" style={stylespdf.page} orientation={'landscape'}>
            <ImagePdf style={stylespdf.bgImg} src={SfondoPDF}/>
            <View style={stylespdf.cornerRightTop}></View>
            <View style={stylespdf.section}>
              <TextPdf style={stylespdf.textMedium}>Attestato di partecipazione al corso:</TextPdf>
              <TextPdf  style={stylespdf.textBig}>"{packageInfo.title}"</TextPdf>
              <TextPdf  style={stylespdf.textMedium}>rilasciato a</TextPdf>
              <TextPdf  style={stylespdf.textBigItalic}>{profile.firstname} {profile.lastname}</TextPdf>
              <View style={stylespdf.sectionLeadershipContainer}>
                <View style={stylespdf.sectionLeadership}>
                  <TextPdf  style={stylespdf.textSmall}>Dott.ssa Anna Maria Roscio</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Responsabile</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Direzione Sales & Marketing Imprese</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Divisione Banca dei Territori</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Intesa Sanpaolo</TextPdf>
                </View>
                <View style={stylespdf.sectionLeadership}>
                  <TextPdf  style={stylespdf.textSmall}>Ing. Gianandrea Edoardo De Bernardis</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Presidente del Consiglio di Amministrazione</TextPdf>
                  <TextPdf  style={stylespdf.textSmall}>Digit'Ed S.p.A.</TextPdf>
                </View>
              </View>

            </View>
            <View style={stylespdf.cornerLeftBottom}></View>
            <View style={stylespdf.footer}>
              <ImagePdf style={stylespdf.logoFooter} src={IntesaLogo}/>
              <ImagePdf style={stylespdf.logoFooterDx} src={DigitedLogo}/>
            </View>
          </Page>
        </Document>
    );

    if (Object.keys(packageInfo) < 1) return <Spinner />;

    return (
      <>
        <MainContainer>
          <HeaderWrapper>
            <FirstHalfWrapper>
              <Container>
                <FirstHalf>
                  <HeaderIcon
                    className="icon-arrow-right first"
                    onClick={() => pushUrl(routes.homepage.path)}
                  />
                  <Title id="playlist-title">
                    <Text>
                      <ProText
                        type="playlistOvertitle"
                        configuration={{ color: 'white' }}
                      >
                        Pacchetto
                      </ProText>
                    </Text>
                    <Text className="flex-start">
                      <ProText
                        type="playlistTitle"
                        configuration={{ color: 'white' }}
                      >
                        {packageInfo.title}
                      </ProText>
                      {packageInfo.body ? (
                        <Tooltip text={packageInfo.body}>
                          <IconInfo className="icon-uniE940" />
                        </Tooltip>
                      ) : null}
                    </Text>
                  </Title>
                </FirstHalf>
              </Container>
            </FirstHalfWrapper>
          </HeaderWrapper>
          <PackageHeader bg={packageInfo.cover ?? PlaceholderImg} className={'py-5 py-lg-4'}>
            <Container>
              {/* in linea con box in dettaglio evento */}
              <ProDisplay
                type={TYPES.DISPLAY.PACKAGE_DETAIL_TITLE}
                currentViewport={currentViewport}
              >
                {packageInfo.title}
              </ProDisplay>
              <ProText
                type={TYPES.TEXT.PACKAGE_DETAIL_SUBTITLE}
                currentViewport={currentViewport}
                className={'mt-3'}
              >
                {packageInfo.body}
              </ProText>
              <ProText
                type={TYPES.TEXT.PACKAGE_DETAIL_HERO}
                currentViewport={currentViewport}
                className={'mt-5'}
              >
                Durata: <b>{secsToTime(packageInfo.totalDuration)}</b>
              </ProText>
              <PackageProgresss
                progress={
                  packageInfo.numberOfAssetContentsCompleted ?
                      (packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents)
                      : 0
                }
                className='mb-4'
              >
                <ProText
                  type={TYPES.TEXT.PACKAGE_DETAIL_HERO}
                  currentViewport={currentViewport}
                >
                  Avanzamento: <span className='bold'>{
                  packageInfo.numberOfAssetContentsCompleted ?
                    Math.round((packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents) * 100)
                  : 0}%</span>
                </ProText>
                <span className="bar bg ml-4">
                  <span className="bar fg"></span>
                </span>
              </PackageProgresss>
            </Container>
          </PackageHeader>
          <Container>
          <Carousel
              swipeable
              responsive={responsiveLargeCards}
              draggable={viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop}
              customRightArrow={<CustomRightArrow offset={'20px'}/>}
              customLeftArrow={<CustomLeftArrow offset={'20px'} />}
              containerClass="react-multi-carousel-list container pl-0 py-4 mt-5 mb-4"
            >
              {contents?.length > 0 &&
                contents.map((card, index) => (
                  <ContentCardUser
                    homepage
                    key={card.itemId ?? card.packageId}
                    type={card.type}
                    ellipsis={1}
                    data={card}
                    onClick={() => openDetails(card)}
                    index={index}
                  />
                ))}
            </Carousel>
          </Container>
          <Section className='gray-bg'>
            <Container>
              <Title>
                <Text>
                  <ProDisplay
                    type="5"
                    configuration={{
                      fontSize: 20,
                      lineHeight: 1.86
                    }}
                  >
                    {'Per approfondire'}
                  </ProDisplay>
                </Text>
              </Title>
              {(packageInfo?.links && packageInfo.links[0].linkUri !== null) || packageInfo?.documents.length > 0 ?
              <Row className='link-doc-row'>
                {(packageInfo?.links && packageInfo.links[0].linkUri!== null) ?
                <Col xs={12} sm={6} className="link-doc-row__links">
                  {
                    packageInfo.links.map((item, idx) => {
                      const linkName = item.linkName ? unescape(item.linkName) : '';
                      return (
                        <div key={`${linkName}-${idx}`} className='item'>
                          <span className='icon icon-icona-allega'></span>
                          <div className='value'>
                            <Link href={item.linkUri} target="_blank">
                              <ProText
                                  type="playlistTitle"
                                  configuration={{ fontWeight: 'bold' }}
                              >
                                {linkName}
                              </ProText>
                            </Link>
                          </div>
                        </div>
                      )
                    })
                  }
                </Col>
                  : null}
                <Col xs={12} sm={(packageInfo?.links && packageInfo.links[0].linkUri !== null) ? 6 : 12} className="link-doc-row__docs">
                  {
                    packageInfo.documents.map((item, idx) => {
                      const fileName = item.fileName ? unescape(item.fileName) : `file-${idx}`
                      return (
                        <div key={`${fileName}-${idx}`} className='item'>
                          <span className='icon'>
                              {item.fileExtension === 'pdf' ? <Pdf/>
                              : item.fileExtension === 'xls' || item.fileExtension === 'xlsx' || item.fileExtension === 'csv'  ? <Excel/>
                              : item.fileExtension === 'doc' || item.fileExtension === 'docx' ? <Word/>
                              : item.fileExtension === 'ppt' || item.fileExtension === 'pptx' ? <Powerpoint/>
                              : <span className='icon-icona-allega'></span>}
                          </span>
                          <div className='value'>
                            <Link href={item.fileUri} target="_blank">
                              <ProText
                                type="playlistTitle"
                                configuration={{ fontWeight: 'bold' }}
                              >
                                {new DOMParser().parseFromString(fileName, "text/html").documentElement.textContent}
                              </ProText>
                            </Link>
                          </div>
                          <Link href={item.fileUri} target="_blank" className='icon-download-link'>
                            <span className='icon-download'></span>
                          </Link>
                        </div>
                      )
                    })
                  }
                </Col>
              </Row>
              :
              <Row>
                <Col>
                  <NoMaterials>
                    <ProText>Non sono al momento disponibili materiali di approfondimento.</ProText>
                  </NoMaterials>
                </Col>
              </Row>
              }
            </Container>
            <Container>

            </Container>
          </Section>
          {(!guestUsers.includes(profile.email) && packageInfo.surveyId !== null) ?
              <Container>
                <PackageFooterCard bg={packageInfo.cover ?? PlaceholderImg}>
                  <Row>
                    <Col xs={12} md={8}>
                        <Text>
                          <ProDisplay
                              type="5"
                              configuration={{
                                fontSize: 40,
                                lineHeight: 1.2

                              }}
                          >
                            {'Compila la survey di gradimento e scarica il tuo attestato'}
                          </ProDisplay>
                        </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={8}>
                      {/*{(packageInfo.packageType === 'base' && progress < 1) || (packageInfo.packageType === 'premium' && progress < 0.8) ? (*/}
                      {!packageInfo.surveyCompleted ? (
                        <>
                          {!packageInfo.surveyEnabled ? (
                            <>
                              {packageInfo.packageType === 'premium' ?
                                    <ProText className='mt-1'
                                    >La Survey sarà disponibile al completamento dell'<b>80% del
                                  journey</b>
                                    </ProText>
                                 :
                                <ProText>La Survey sarà disponibile al completamento del <b>100% del
                                  journey</b></ProText>
                              }
                              <PackageProgresss
                                  progress={
                                    packageInfo.numberOfAssetContentsCompleted ?
                                        (packageInfo.numberOfAssetContentsCompleted / packageInfo.numberOfAssetContents)
                                        : 0
                                  }
                                  className='mt-4'
                              >
                                <span className='icon-lock-reverse'></span>
                                  <span className="bar bg ml-4">
                                  <span className="bar fg"></span>
                                </span>
                              </PackageProgresss>
                            </>
                          ) : (
                            <>
                              <AssessmentModal
                                itemId={packageInfo.itemId}
                                surveyId={packageInfo.surveyId}
                                packageId={id}
                                playlist={packageInfo}
                                playlistId={packageInfo.itemId}
                                type={'package'}
                              />
                              <SurveyContainer className={'package'}>
                                <Text>
                                  <ProText
                                      type="detailAction"
                                      configuration={{color: 'white'}}
                                  >
                                    Completa la survey di gradimento
                                  </ProText>
                                </Text>
                                <ButtonWrapper>
                                  <Button
                                      type="PRIMARY"
                                      text="INIZIA"
                                      height={35}
                                      fontSize={10}
                                      onClick={() => openModal(AssessmentModal.id)}
                                  />
                                </ButtonWrapper>
                              </SurveyContainer>
                            </>
                          )}
                        </>
                      ) : (
                        <PDFDownloadLink
                          style={{color: 'transparent'}}
                          document={<Certificate/>}
                          fileName="AttestatoDiPartecipazione.pdf"
                        >
                          {/* {({blob, url, loading, error}) =>
                            loading
                              ? <IconBigDownload></IconBigDownload>
                              : <IconBigDownload></IconBigDownload>
                          } */}
                          <IconBigDownload />
                        </PDFDownloadLink>
                      )}
                    </Col>
                  </Row>
                </PackageFooterCard>
              </Container>
          : ''}
        </MainContainer>
        <Footer />
      </>
    );
  };

  PackageDetails.defaultProps = {
    plackageSelected: {},
  };

  PackageDetails.propTypes = {
    // HOC (connect, state)
    assetDetail: PropTypes.object,
    packageInfo: PropTypes.object,
    profile: PropTypes.object,
    router: PropTypes.object,

    // HOC (connect, dispatch)
    getPackage: PropTypes.func,
    openModal: PropTypes.func,
    pushUrl: PropTypes.func,
    setPlaylistHistory: PropTypes.func,
    setSection: PropTypes.func,
  };

  export default withMediaQueries(
    connect(
      state => {
        const { user: profile = {} } = state.authentication;
        const { packageSelected: { packageInfo, assetDetail } } = state.playlist.packages;
        const { router } = state;
        return {
          assetDetail,
          packageInfo,
          profile,
          router,
        };
      },
      dispatch => ({
        getPackage: itemId => dispatch({ type: GET_PACKAGE._REQUEST, itemId }),
        openModal: id => dispatch({ type: MODAL_OPEN, id }),
        pushUrl: url => dispatch(push(url)),
        setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
        setSection: section => dispatch({ type: SET_SECTION, section }),
      })
    )(PackageDetails)
  );
