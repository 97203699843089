import React, { useState } from "react";
import SectionBanner from "../SectionBanner";
import SectionCalendar from "../SectionCalendar";
import SectionCarousel from 'ui/components/Homepage/SectionCarousel';
import SectionTitle from 'ui/components/Homepage/SectionTitle';
import { Button } from 'ui/atoms';
import {
  CardsContainer,
  CategoryWrapper,
  FiltersWrapper,
  Section,
} from "./style";
import { unescape } from 'utils/common.js';

const HomepageSection = ({
  bannerImage,
  bannerText,
  carouselCardClickHandler,
  carouselType,
  data,
  description,
  link,
  getData,
  grayBg,
  pillsClickHandler,
  shouldHide,
  showCalendar,
  title,
  type,
  vertical,
}) => {
  const [showCalendarOnMobile, setShowCalendarOnMobile] = useState(false);
  switch(type) {
    case 'banner':
      return (
        <SectionBanner
          bannerImage={bannerImage}
          bannerText={bannerText}
        />
      );
    case 'calendar':
      return (
        !shouldHide ? (
          <Section className={grayBg ? 'gray-bg' : ''}>
            <SectionTitle
              description={description}
              showCalendarOnMobile={showCalendarOnMobile}
              toggle={showCalendar === false
                ? () => setShowCalendarOnMobile(show => !show)
                : undefined
              }
            >
              {title}
            </SectionTitle>
            <SectionCalendar
              events={data}
              getEvents={getData}
              showCalendar={showCalendar || showCalendarOnMobile}
              vertical={vertical}
            />
          </Section>
        ) : null
      );
    case 'carousel':
      return (
        !shouldHide ? (
          <Section className={grayBg ? 'gray-bg' : ''}>
            <SectionTitle
              description={description}
              link= {link}
            >
              {title}
            </SectionTitle>
            <CardsContainer>
              <SectionCarousel
                data={data}
                type={carouselType}
                onClick={carouselCardClickHandler}
              />
            </CardsContainer>
          </Section>
        ) : null
      )
    case "pills": {
      const buttonFilters = (categories) =>
        categories
          .sort((a, b) =>
            Number(a.order) - Number(b.order)
          )
          .map((category) => (
            <Button
              key={category.id}
              type={Button.TYPE.FILTER}
              onClick={() => pillsClickHandler(category.needId)}
            >
              {unescape(`${category.title}`)}<span className='num'>{ category.itemSize }</span>
            </Button>
          ));
      return !shouldHide ? (
        <Section className={grayBg ? 'gray-bg' : ''}>
          <CategoryWrapper>
            <SectionTitle>
              Categorie
            </SectionTitle>
            <FiltersWrapper>
              {(Array.isArray(data) && data.length > 0) ? (
                buttonFilters(data)
              ) : null}
            </FiltersWrapper>
          </CategoryWrapper>
        </Section>
      ) : null;
    }
    default:
      return null;
  }
};

export default HomepageSection;