import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Img from 'ui/assets/img/placeholder.png';
import { ContentCard } from 'ui/components';
import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { CATALOG_PRODUCT_GET } from 'redux/actions/subscriptions';
import MainContainer from '../../../style';
import { RecommendedContainer, SubTitle } from './style';

const Recommended = ({ catalog, mediaIsPhone, getProductCatalog }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const productVariations = catalog.filter(
      currValue => currValue.promoted === 'True'
    );

    setProducts(productVariations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalog]);

  return (
    <MainContainer noMenu>
      <Container>
        <SubTitle>
          <ProText configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
            Scopri le nuove playlist premium che abbiamo pensato per te
          </ProText>
        </SubTitle>

        <RecommendedContainer>
          {(products || []).map((plan = {}) => (
            <ContentCard
              key={`content-card-${plan.content_id}`}
              plan={{
                ...plan,
                num_content: plan.learning_contents.length,
                cover_image: plan.cover || Img,
                price: plan.price_number || plan.price || 0.0,
                name: plan.title,
              }}
            />
          ))}
        </RecommendedContainer>
      </Container>
    </MainContainer>
  );
};

Recommended.propTypes = {
  //HOC (connect, state)
  catalog: PropTypes.array,
  getProductCatalog: PropTypes.func,
  //HOC (connect, dispatch)
  mediaIsPhone: PropTypes.bool.isRequired,
};

export default connect(
  state => {
    const { catalog } = state.subscriptions;
    return {
      catalog,
    };
  },
  dispatch => ({
    getProductCatalog: () =>
      dispatch({
        type: CATALOG_PRODUCT_GET._REQUEST,
      }),
  })
)(withMediaQueries(Recommended));
