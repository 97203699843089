import styled from 'styled-components/macro';

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0;

  &:checked ~ span {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary.base};
    border: 1px solid ${props => props.theme.colors.primary.base};
  }

  &:checked ~ span:after {
    display: block;
    border-color: ${props => props.theme.colors.white};
  }
`;

const CustomCheckbox = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => (props.rounded ? '23px' : '20px')};
  height: ${props => (props.rounded ? '23px' : '20px')};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.mineShaft};
  border-radius: ${props => (props.rounded ? '50%' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    display: none;
    width: 6px;
    height: 12px;
    border: solid ${props => props.theme.colors.white};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;

    position: absolute;
    top: ${props => (props.rounded ? '3px' : '2px')};
    left: ${props => (props.rounded ? '7px' : '6px')};
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.mineShaft};
  margin: 0;
  padding-left: 30px;

  a {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary.darker};
  }
`;

export { Label, Input, CustomCheckbox, TextWrapper };
