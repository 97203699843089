import React from 'react';
import PropTypes from 'prop-types';

import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import {
  AddBackSection,
  BackButton,
  BackButtonWrapper,
  ButtonWrapper,
  ContentItem,
  ContentItemDuration,
  ContentItemTitle,
  ContentsDetailSection,
  CoverSection,
  DescriptionSection,
  FlexContentSection,
  IconBack,
  IconPlay,
  ImageOverlay,
  SelectedWrapper,
  TitleContentsDetail,
  TitleDetail,
  TitleDetailWrapper
} from './style';
import routes from "../../../routes";
import compose from "../../../utils/compose";
import {connect} from "react-redux";
import {push} from "redux-first-history";
import {withMediaQueries} from "../../../hoc/withMediaQueries";
import { HtmlWrapper } from 'pages/CollectionDetails/style';


const DetailSection = ({
  item,
  onClose,
  onAdd,
  isLocked = false,
  catalogModal = false,
  pushUrl
}) => {

  const handleClickOnSignup = () => {
    // pushUrl(routes.signup.path); //feat redirect nuova piattaforma 20240524
    window.location.href = 'https://www.digitallibrary.digited.it/';
  };

  return(
  <SelectedWrapper className="ml-0" catalogModal={catalogModal}>
    <Container>
      {!catalogModal ? (
          <AddBackSection>
            <BackButtonWrapper onClick={onClose}>
              <ProDisplay>
                <BackButton>
                  <IconBack className="icon-arrow-right"/>
                  Indietro
                </BackButton>
              </ProDisplay>
            </BackButtonWrapper>
            <ButtonWrapper>
              <Button
                  type={Button.TYPE.PRIMARY}
                  disabled={!onAdd}
                  onClick={onAdd}
              >
                {onAdd && <IconBack className="icon-plus"/>}
                {isLocked
                    ? onAdd
                        ? 'Segnala'
                        : 'Segnalato'
                    : onAdd
                        ? 'Aggiungi'
                        : 'Aggiunto'}
              </Button>
            </ButtonWrapper>
          </AddBackSection>
      ) :
      <ButtonWrapper className={'buynow'}>
        <Button
            name="acquista ora"
            text={'Acquista ora'}
            onClick={handleClickOnSignup}
            type="PRIMARY"
            hasIcon
        />
      </ButtonWrapper>
      }
      <CoverSection cover={item.cover} catalogModal={catalogModal}>
        <ImageOverlay/>
        <TitleDetailWrapper>
          <ProDisplay>
            <TitleDetail>{item.title}</TitleDetail>
          </ProDisplay>
        </TitleDetailWrapper>
      </CoverSection>
      <DescriptionSection>
        <ProText type="label">
          <HtmlWrapper
            dangerouslySetInnerHTML={{
              __html: item.body
            }}
          />
        </ProText>
      </DescriptionSection>
      <ContentsDetailSection>
        <ProDisplay>
          <TitleContentsDetail>DETTAGLIO CONTENUTI</TitleContentsDetail>
        </ProDisplay>
        <FlexContentSection>
          {(item?.response?.listOfLo || []).map(lo => (
              <ContentItem key={lo.itemId || lo.id}>
                <ProText>
                  <ContentItemTitle
                      dangerouslySetInnerHTML={{
                        __html: lo.title
                      }}
                  />
                </ProText>
                <ProText>
                  <ContentItemDuration>
                    <IconPlay className="icon-icona-play"/>
                    {Math.round(lo.duration / 60)} min
                  </ContentItemDuration>
                </ProText>
              </ContentItem>
          ))}
        </FlexContentSection>
      </ContentsDetailSection>
    </Container>
  </SelectedWrapper>
  )
};

DetailSection.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  isLocked: PropTypes.bool,
  catalogModal: PropTypes.bool,
  pushUrl: PropTypes.func.isRequired,
};


const composed = compose(
    connect(
        () => ({}),
        dispatch => ({
          pushUrl: url => dispatch(push(url))
        })
    ),
    withMediaQueries
)(DetailSection);
export default composed;
