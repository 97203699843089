import styled, { css } from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

export const OnBoardingWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  position: relative;
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: 100%;
  }
`;

export const ListSection = styled.div`
  padding: 1rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  box-shadow: 5px 24px 30px 0px ${props => props.theme.colors.platinum};
  height: 100vh;
  @media ${props => props.theme.device.laptop} {
    padding: 1rem;

    padding-top: 46px;
    box-shadow: none;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5rem;
  }
`;

export const SubTitleList = styled.h3`
  color: ${props => props.theme.colors.mineShaft};
`;

export const List = styled.div`
  overflow-y: auto;
  margin-top: 3rem;
  @media ${props => props.theme.device.mobile} {
    margin-top: 1rem;
    height: calc(100vh - 130px);
  }
`;

export const GroupWrapper = styled.div``;

export const ListItem = styled.div`
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: 3rem 1fr 2rem;
  max-width: 420px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  box-shadow: ${props =>
    props.selected ? '0 0 40px 0 rgba(0, 0, 0, 0.18)' : 'none'};
  @media ${props => props.theme.device.mobile} {
    max-width: initial;
    overflow: hidden;
  }
`;
export const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.brightGray2};
  opacity: 0.5;
`;

export const PlusMinor = styled.div`
  color: ${props => props.theme.colors.primary.base};
`;
export const InfoItem = styled.div`
  cursor: pointer;
  > * {
    display: block;
  }
`;

export const Arrow = styled.div`
  cursor: pointer;
`;

export const Icon = styled.span`
  font-size: 35px;
`;

export const IconContents = styled.span``;

export const Layout = styled.div`
  opacity: ${props => (props.selected ? 0.2 : 1)};
`;

export const TitleList = styled.div`
  margin-bottom: 5px;
  > span {
    display: block;
  }

  @media ${props => props.theme.device.mobile} {
    margin-top: 20px;
  }
`;

export const ContentsSection = styled.div`
  color: ${props => props.theme.colors.slateGray};
`;

export const GridSection = styled.div`
  padding-top: 60px;
  margin-left: 15px;
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`;

export const TitleGridSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.mineShaft};
`;

export const NumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 130px;
  justify-content: space-around;
`;

export const Numbers = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.slateGray};
`;

export const SelectedNumber = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary.base};
`;

export const Grid = styled.div`
  overflow-y: auto;
  display: grid;
  column-gap: 12px;
  row-gap: 40px;
  grid-template-columns: repeat(4, 195px);
  padding-top: 20px;

  @media ${props => props.theme.device.laptop} {
    grid-template-columns: repeat(3, 195px);
  }
  @media ${props => props.theme.device.tablet} {
    grid-template-columns: repeat(2, 195px);
  }
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: repeat(1, 195px);
  }
`;

export const ConfiguredWrapper = styled.div`
  position: relative;
  background: url(${props => props.image});
  background-size: cover;
  border-radius: 14px;
  height: 210px;
`;

export const InfoConfigured = styled.div`
  position: absolute;
  bottom: 13px;
  left: 20px;
  width: 80%;
`;

export const TitleConfigured = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
  letter-spacing: -0.2px;
  color: ${props => props.theme.colors.white};
  padding-bottom: 10px;
`;

export const ContentsConfigured = styled.div`
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`;

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(4, 4, 15, 0), ${props => props.theme.colors.black});
  border-radius: 14px;
`;

export const IconRemove = styled.div`
  position: absolute;
  top: -15px;
  right: -15px;
  color: ${props => props.theme.colors.primary.base};
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ButtonDiv = styled.div`
  width: 177px;
  padding-top: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 170px;
  &.buynow{
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    position: absolute;
    top: 2%;
    left: 0;
  }

  @media ${props => props.theme.device.mobile} {
    justify-content: flex-start;

    button {
      padding: 30px;
    }
  }
`;

export const SelectedWrapper = styled.div`
  overflow: auto;
  background-color: ${props =>
    props.catalogModal
      ? props.theme.colors.white
      : props.theme.colors.ghostWhite};
  padding: 50px 26px;
  margin-left: 15px;

  ${props => props.catalogModal && customScrollbar()};

  @media ${props => props.theme.device.mobile} {
    padding: 26px 0 0 0;
    //padding: 26px;
    //position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const AddBackSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const IconBack = styled.div`
  transform: rotate(180deg);
  margin-right: 10px;
  display: inline-block;
  font-size: 30px;
`;

export const CoverSection = styled.div`
  position: relative;
  background: url(${props => props.cover});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 170px;
  border-radius: 14px;
  margin-top: 20px;
  ${props => {
    if (props.catalogModal)
      return css`
        @media ${props.theme.device.mobile} {
          width: 100%;
          margin-top: 60px;
        }
      `;
    return null;
  }};
`;

export const TitleDetailWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 100%;
`;

export const TitleDetail = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.white};
`;

export const DescriptionSection = styled.div`
  padding: 20px 0;
`;

export const ContentsDetailSection = styled.div``;

export const TitleContentsDetail = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  letter-spacing: 0.13px;
`;

export const FlexContentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ContentItem = styled.div`
  flex: 1 0 45%;
  height: 70px;
  padding: 9px 0;
  border-bottom: solid 1px ${props => props.theme.colors.gray};
  margin-right: 10px;
  @media ${props => props.theme.device.mobile} {
    flex: 1 0 100%;
  }
`;
export const ContentItemTitle = styled.div`
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.1px;
  margin-bottom: 5px;
`;

export const ContentItemDuration = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.19px;
  color: ${props => props.theme.colors.slateGray};
`;

export const IconPlay = styled.span`
  margin-right: 5px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.slateGray};
  border-radius: 50%;
  width: 14px;
  height: 14px;
`;

export const NumberWrapperMobile = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 46px;
  background-color: ${props => props.theme.colors.primary.darker};
  * {
    color: ${props => props.theme.colors.white};
  }
  @media ${props => props.theme.device.mobile} {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding-left: 1rem;
    padding-top: 0.5rem;
  }
`;

export const ButtonWrapperMobile = styled.div`
  display: none;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 46px;
  padding: 0 1rem;

  @media ${props => props.theme.device.mobile} {
    display: block;
  }
`;
