import { takeLatest, put, call, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import {
  ADD_BOOKMARK,
  ADD_FOLLOW,
  GET_BOOKMARKS,
  GET_FOLLOWED_INFO,
  GET_FOLLOWERS,
  GET_FOLLOW_COUNT,
  HANDLE_FEEDBACK,
  REMOVE_BOOKMARK,
  REMOVE_BOOKMARK_BY_ID,
  REMOVE_FOLLOW,
  UPDATE_SOCIAL,
  UPDATE_SOCIAL_EVENT,
} from 'redux/actions/social';
import social from 'api/social';
import playlist from 'api/playlist';
import { detailType } from 'utils/common';

function* updateBookmark(value, itemId) {
  yield put({ type: UPDATE_SOCIAL, data: { bookmarked: value }, itemId });
}

function* updateFeedback(type, currentUserVote, positive, negative, itemId) {
  if (type.toLowerCase() !== detailType.LIVE_EVENT) {
    yield put({
      type: UPDATE_SOCIAL,
      data: {
        feedback: { currentUserVote, positive, negative }
      },
      itemId
    });
  } else {
    yield put({
      type: UPDATE_SOCIAL_EVENT,
      data: {
        feedback: { currentUserVote, positive, negative }
      },
      itemId
    });
  }
}

function* getBookmarks() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { bookmarks: { bookmarklist: bookmarks } = {} } = yield select(
      state => state.social
    );
    if (bookmarks.length > 0) {
      const data = { bookmarklist: bookmarks };
      yield put({
        type: GET_BOOKMARKS._SUCCESS,
        data
      });
    } else {
      const { data } = yield call(social.getBookmarks, usrId);
      yield put({
        type: GET_BOOKMARKS._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > getBookmarks', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getBookmarksWatch() {
  yield takeLatest(GET_BOOKMARKS._REQUEST, getBookmarks);
}

function* addBookmark({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId: usrId } = {} } = yield select(
      //   state => state.authentication
    // );
    // payload.userId = usrId;
    const { status, data } = yield call(social.addBookmark, payload);
    if (status === 200 || data.resultcode.toString() === '200') {
      yield updateBookmark(true, payload.itemId);
      yield put({
        type: ADD_BOOKMARK._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > addBookmark', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addBookmarkWatch() {
  yield takeLatest(ADD_BOOKMARK._REQUEST, addBookmark);
}

function* removeBookmark({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { status, data } = yield call(
      social.removeBookmark,
      payload.itemId,
      usrId
    );
    if (status === 200 || data.resultcode.toString() === '200') {
      yield updateBookmark(false, payload.itemId);
      yield put({
        type: REMOVE_BOOKMARK._SUCCESS,
        data: payload.itemId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > removeBookmark', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* removeBookmarkWatch() {
  yield takeLatest(REMOVE_BOOKMARK._REQUEST, removeBookmark);
}

function* removeBookmarkById({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { status, data } = yield call(
      social.removeBookmarkById,
      payload.bookId
    );
    if (status?.toString() === '200' || data.resultcode?.toString() === '200') {
      //const { bookmarklist } = yield select(state => state.social.bookmarks);
      //const bookToDelete = bookmarklist.find(b => b.bookId === payload.bookId);
      yield updateBookmark(false, payload.itemId);
      yield put({
        type: REMOVE_BOOKMARK_BY_ID._SUCCESS,
        data: payload.bookId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > removeBookmarkById', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* removeBookmarkByIdWatch() {
  yield takeLatest(REMOVE_BOOKMARK_BY_ID._REQUEST, removeBookmarkById);
}

function* handleFeedback({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { status, data } = yield call(
      social.handleFeedback,
      payload.itemId,
      usrId,
      payload.feedback,
      payload.reset
    );
    if (status === 200 || data.resultcode.toString() === '200') {
      yield updateFeedback(
        payload.itemType,
        data.currentuservote,
        data.positive,
        data.negative,
        payload.itemId
      );
      yield put({
        type: HANDLE_FEEDBACK._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > handleFeedback', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* handleFeedbackWatch() {
  yield takeLatest(HANDLE_FEEDBACK._REQUEST, handleFeedback);
}

function* getFollowCount() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { data } = yield call(social.getFollowCount, usrId);
    yield put({
      type: GET_FOLLOW_COUNT._SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > getFollowCount', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFollowCountWatch() {
  yield takeLatest(GET_FOLLOW_COUNT._REQUEST, getFollowCount);
}

function* getFollowers() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const { status, data } = yield call(social.getFollowers, usrId);
    if (status?.toString === '200' || data.resultcode?.toString() === '200') {
      yield put({
        type: GET_FOLLOWERS._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > getFollowers', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFollowersWatch() {
  yield takeLatest(GET_FOLLOWERS._REQUEST, getFollowers);
}

function* addFollow({ followId }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId: usrId } = {} } = yield select(
      //   state => state.authentication
    // );
    const body = { followId /*,  userId: usrId */};
    const { status, data } = yield call(social.addFollow, body);
    if (status?.toString() === '200' || data.resultcode?.toString() === '200') {
      yield put({
        type: ADD_FOLLOW._SUCCESS,
        data: followId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > addFollow', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addFollowWatch() {
  yield takeLatest(ADD_FOLLOW._REQUEST, addFollow);
}

function* removeFollow({ followId }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId: usrId } = {} } = yield select(
      //   state => state.authentication
    // );
    const body = { followId /*, userId: usrId */};
    const { status, data } = yield call(social.removeFollow, body);
    if (status?.toString() === '200' || data.resultcode?.toString() === '200') {
      yield put({
        type: REMOVE_FOLLOW._SUCCESS,
        data: followId
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > removeFollow', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* removeFollowWatch() {
  yield takeLatest(REMOVE_FOLLOW._REQUEST, removeFollow);
}

function* getFollowedInfo({ userId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { users = [] } = yield select(state => state.social);
    if (users.length > 0) {
      const followUser = users.find(u => u.userId === userId);
      const { data: followCount } = yield call(social.getFollowCount, userId);
      const { data: followPath } = yield call(playlist.pathSelection, userId);
      yield put({
        type: GET_FOLLOWED_INFO._SUCCESS,
        followUser,
        followCount,
        followPath
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > social > getFollowedInfo', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFollowedInfoWatch() {
  yield takeLatest(GET_FOLLOWED_INFO._REQUEST, getFollowedInfo);
}

export default [
  getBookmarksWatch(),
  addBookmarkWatch(),
  removeBookmarkWatch(),
  removeBookmarkByIdWatch(),
  handleFeedbackWatch(),
  getFollowCountWatch(),
  getFollowersWatch(),
  addFollowWatch(),
  removeFollowWatch(),
  getFollowedInfoWatch()
];
