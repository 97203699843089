import app from './app';
import authentication from './authentication';
import landingPage from './landingPage';
import modal from './modal';
import profile from './profile';
import signup from './signup';
import subscriptions from './subscriptions';
import teams from './teams';
import registration from './registration';
import billings from './billings';
import configuration from './configuration';
import domain from './domain';
import playlist from './playlist';
import sidebar from './sidebar';
import social from './social';
import notes from './notes';
import fiveMinutes from './fiveMinutes';
import onBoarding from './onBoarding';
import tracking from './tracking';
import workouts from './workouts';

export default {
  app,
  authentication,
  landingPage,
  modal,
  profile,
  signup,
  subscriptions,
  teams,
  registration,
  billings,
  configuration,
  domain,
  playlist,
  sidebar,
  social,
  notes,
  fiveMinutes,
  onBoarding,
  tracking,
  workouts
};
