import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProDisplay from 'ui/typography/proDisplay';
import { Button, CustomInput } from 'ui/atoms';
import { Container } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Head, HeadWrapper, Logo } from 'pages/LandingPage/style';
import validator from 'utils/regex-validator';
import { CompanyDataInterface } from 'interface/profile';
import { PROFILE_DATA_COMPANY_UPDATE } from 'redux/actions/profile';
import {SIGNUP_STEP_RESET} from 'redux/actions/signup'
import { push } from 'redux-first-history';
import routes from 'routes';
import {
  ContentWrapper,
  Title,
  Subtitle,
  InputContainer,
} from './style';

const newField = (types = ['string'], value = undefined, valid = true) => ({
  value,
  valid,
  types
});
const fields = [
  { name: 'piva', types: ['piva'] },
  { name: 'cf_piva', types: ['fiscalCode'] }
];
const initialForm = {};
fields.forEach(f => (initialForm[f.name] = newField(f.types)));
initialForm.country = { ...initialForm.country, value: 'it' };

const SetFiscalCode = ({
  updateProfileCompany,
  domain,
  company,
  organizationType,
  pushUrl
}) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    let acceptedTypes = ['fiscalCode'];
    if (organizationType === 'persona_giuridica') acceptedTypes = ['piva'];

    setForm(s => {
      const newState = { ...s };
      newState.cf_piva.types = acceptedTypes;
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (field, newValue) => {
    const { types } = form[field];
    setForm(s => {
      const newState = { ...s };

      newState[field].value = newValue;
      newState[field].valid = types.reduce((acc, t) => {
        const valid = validator[t](newValue);
        return typeof acc === 'boolean' ? acc || valid : valid;
      }, false);

      return newState;
    });
  };

  const redirectHomepage =()=>{
    //resetSignupStep();
    pushUrl(routes.landingPage.path);
    window.location.reload();
  }

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo img={domain?.brand?.logo_white} onClick={redirectHomepage}/>
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <ContentWrapper>
          <Title>
            <ProDisplay type="modalTitle">
              Imposta il codice fiscale della tua azienda
            </ProDisplay>
          </Title>
          {organizationType === 'persona_giuridica' && (
            <Subtitle>
              <ProDisplay configuration={{ lineHeight: 1.63 }}>
                Se la tua azienda non possiede un codice fiscale, inserisci la
                P.IVA
              </ProDisplay>
            </Subtitle>
          )}
          <InputContainer>
            <CustomInput
              type="text"
              placeholder="P.IVA"
              disabled
              value={company?.vatnumber}
              forceUppercase
            />
            <CustomInput
              type="text"
              placeholder="Codice Fiscale *"
              value={form.cf_piva.value}
              forceUppercase
              onChange={val => handleOnChange('fiscalCode', val.toUpperCase())}
              error={
                typeof form.cf_piva.valid === 'string'
                  ? form.cf_piva.valid
                  : !form.cf_piva.valid
              }
            />
          </InputContainer>
          <Button
            type={Button.TYPE.PRIMARY}
            onClick={() => {
              updateProfileCompany({ orgFiscalCode: form.cf_piva.value });
            }}
            disabled={
              !(form.cf_piva.value?.length > 0 && form.cf_piva.valid === true)
            }
          >
            Conferma
          </Button>
        </ContentWrapper>
      </Container>
    </>
  );
};

SetFiscalCode.propTypes = {
  // HOC withMediaQueires

  // HOC (connect, state)
  domain: PropTypes.object,
  company: CompanyDataInterface,
  organizationType: PropTypes.string,
  updateProfileCompany: PropTypes.func.isRequired
};

export default withMediaQueries(
  connect(
    state => {
      const {
        company,
        privacy: { organizationType }
      } = state.profile;
      return {
        domain: state.domain,
        company,
        organizationType
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      updateProfileCompany: form => dispatch({ type: PROFILE_DATA_COMPANY_UPDATE._REQUEST, form, goToBillings: true })
    })
  )(SetFiscalCode)
);
