  import React, { useEffect } from 'react';
  import { useParams } from 'react-router-dom';
  import { connect } from 'react-redux';
  import { push } from 'redux-first-history';
  import PropTypes from 'prop-types';
  import { withMediaQueries } from 'hoc/withMediaQueries';
  import AddToCalendar from 'react-add-to-calendar';
  import { getPreviousPath } from 'utils/router';
  import { unescape } from 'utils/common.js';

  import 'react-multi-carousel/lib/styles.css';

  import { Col, Container, Row } from 'ui/gridSystem';
  import { Footer } from 'ui/components';

  // import AssessmentModal from 'ui/components/Modals/AssessmentModal';

  import { MODAL_OPEN } from 'redux/actions';
  import { GET_EVENT } from 'redux/actions/playlist';
  import { HANDLE_FEEDBACK } from 'redux/actions/social';

  import ProDisplay from 'ui/typography/proDisplay';
  import ProText from 'ui/typography/proText';
  import TYPES from 'ui/typography/pro.types';
  import { Spinner } from 'ui/components';

  import MainContainer from '../style';
  import {
    EventHeader,
    FakeLink,
    Link,
    EventTitle,
    EventText,
    Section,
    TeachersList,
    EventCoreRow,
    VideoRowAsk,
  } from './style';
  import { Button, Tooltip } from 'ui/atoms';
  import Pdf from 'ui/atoms/IconFiles/Pdf';
  import Excel from 'ui/atoms/IconFiles/Excel';
  import Word from 'ui/atoms/IconFiles/Word';
  import Powerpoint from 'ui/atoms/IconFiles/Powerpoint';
  import {
  VideoRow,
  VideoCol,
  Like,
  LikeWrapper,
  Dislike,
  HeaderWrapper,
  FirstHalfWrapper,
  FirstHalf,
  HeaderIcon,
  Completed,
  IconInfo,
  Text,
  TitleMaterials,
  Title,
  HtmlWrapper
} from 'pages/CollectionDetails/style';
import { getDates } from 'utils/date-format';
import ReviewModal from 'ui/components/Modals/ReviewModal';
import NotesModal, { NotesModalId } from 'ui/components/Modals/NotesModal';
import WorkoutModal from 'ui/components/Modals/WorkoutModal';
import { detailType } from 'utils/common';
import { NoMaterials } from 'pages/PackageDetails/style';
  const EventDetails = ({
    // HOC store
    event,
    packageLabels,
    router,
    // HOC dispatch
    openModal,
    getEvent,
    handleFeedback,
    // HOC media queries
    currentViewport,
    pushUrl,
    viewportTypes,
  }) => {
    const { id } = useParams();

    useEffect(() => {
      // TODO: prefetch from home?
      getEvent(id);
    }, [id, getEvent])

    const isEventJoinable = (event) => {
      const anHourMillisec = 1 * 60 * 60 * 1000;
      const startMilliSec = new Date(event?.session?.startDate).getTime() - (anHourMillisec * 2);
      const endMilliSec = new Date(event?.session?.endDate).getTime() + anHourMillisec;
      const now = Date.now();

      return (startMilliSec < now) && (now < endMilliSec);
    };

    const handleLike = value => {
      const payload = {
        itemType: event.type,
        itemId: event.itemId,
        feedback: false,
        reset: false
      };
      if (event.feedback.currentUserVote >= 0) {
        event.feedback.currentUserVote === value
          ? (payload.reset = true)
          : (payload.feedback = value !== 0);
      } else {
        payload.feedback = value !== 0;
      }
      handleFeedback(payload);
    };

    if (!event) return <Spinner />;

    return (
      <>
        <MainContainer>
        <HeaderWrapper>
          <FirstHalfWrapper>
            <Container>
              <FirstHalf>
                <HeaderIcon
                  className="icon-arrow-right first"
                  onClick={() => pushUrl(getPreviousPath(router))}
                />
                  {packageLabels.title ? (
                    <Title id="playlist-title">
                      <Text>
                        <ProText
                          type="playlistOvertitle"
                          configuration={{ color: 'white' }}
                        >
                          Pacchetto
                        </ProText>
                      </Text>
                      <Text className="flex-start">
                        <ProText
                          type="playlistTitle"
                          configuration={{ color: 'white' }}
                        >
                          {packageLabels.title}
                        </ProText>
                        {packageLabels.description && (
                          <Tooltip text={packageLabels.description}>
                            <IconInfo className="icon-uniE940" />
                          </Tooltip>
                        )}
                      </Text>
                    </Title>
                  ) : (
                    <EventTitle id="playlist-title" className='mb-0'>
                      <EventText className="flex-start">
                        <ProText
                          type="playlistTitle"
                          configuration={{ color: 'white' }}
                        >
                          Torna alla Home
                        </ProText>
                      </EventText>
                    </EventTitle>
                  )}
              </FirstHalf>
            </Container>
          </FirstHalfWrapper>
        </HeaderWrapper>
        <EventHeader bg={event.cover} className='py-5 py-lg-4'>
            <Container>
              <span className={event.session?.sessionType === detailType.VIRTUAL ? 'labelEvent Virtual' : 'labelEvent Live'}>
                <ProDisplay>
                  {event.session?.sessionType === detailType.VIRTUAL ? 'Virtual Classroom' : 'Live Classroom'}
                </ProDisplay>
              </span>

              {/* in linea con box in dettaglio evento */}
              <ProDisplay
                className='mt-5'
                type={TYPES.DISPLAY.PACKAGE_DETAIL_TITLE}
                currentViewport={currentViewport}
              >
                {event.title}
              </ProDisplay>
              {/* <ProText
                type={TYPES.TEXT.PACKAGE_DETAIL_SUBTITLE}
                currentViewport={currentViewport}
                className={'mt-3'}
              >
                {event.body}
              </ProText> */}
              <ProText
                className={'mt-5'}
                configuration={{
                  fontSize: 18,
                  lineHeight: 1.86,
                  fontWeight: 500
                }}
              >{getDates(event.session)}</ProText>
              <ProText
              configuration={{
                fontSize: 18,
                lineHeight: 1.86,
                fontWeight: 500
              }}
              >
                {event?.session?.locations?.length > 0 &&
                  `${event.session.locations[0].city} | ${event.session.locations[0].address}`
                }
              </ProText>
              {event.session?.sessionType === detailType.VIRTUAL ? (
                isEventJoinable(event) ? (
                  <Link className='event-btn map-btn' href={event?.session?.link ? event.session.link : ''} target="_blank">
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      PARTECIPA
                    </ProDisplay>
                  </Link>
                ) : (
                  <FakeLink className='event-btn map-btn' disabled>
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      PARTECIPA
                    </ProDisplay>
                  </FakeLink>
                )
              ) : (
                <Link className='event-btn map-btn'
                  href={event?.session?.locations
                    ? `https://maps.google.com/?q=${event.session.locations[0].lat},${event.session.locations[0].lon}`
                    : ''}
                  target="_blank">
                  <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >VEDI SULLA MAPPA
                      </ProDisplay>
                  </Link>
              )}
              <AddToCalendar
                buttonLabel="SALVA IN AGENDA"
                listItems={[{ apple: "Apple Calendar" }, { google: "Google" }, { outlook: "Outlook" }]}
                event={{
                  title: event.title,
                  description: event?.session?.link ? (event.body + ' Link per partecipare: ' + event.session.link) : event.body ,
                  location: event?.session?.locations?.length > 0
                    ? `${event.session.locations[0].city } ${event.session.locations[0].address}`
                    : '',
                  startTime: event.session.startDate,
                  endTime: event.session.endDate
                }}
              />
            </Container>
          </EventHeader>
          <Container>
            <EventCoreRow>
            <Row className='py-5'>
              <Col className='description-col' xs={12} sm={12} md={7} lg={8}>
                <Title>
                  <Text>
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 20,
                        lineHeight: 1.86
                      }}
                    >
                      Descrizione
                    </ProDisplay>
                  </Text>
                </Title>
                <Text>
                  <ProDisplay
                    configuration={{
                      lineHeight: 1.3
                    }}
                  >
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{ __html: event.body }}
	                />
                  </ProDisplay>
                </Text>
              </Col>
              <Col className='teachers-col' xs={12} sm={5} lg={4}>
                {event?.session?.theachers ? <Title>
                  <Text>
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 20,
                        lineHeight: 1.86
                      }}
                    >
                      Docenti
                    </ProDisplay>
                  </Text>
                </Title> : ''}
                <TeachersList>
                  {event?.session?.teachers ? (
                    event?.session?.teachers.map((teach, idx) =>
                      <li key={`${teach.name}-${teach.lastName}-${idx}`}>
                        <div className={`avatar ${!teach.cover ? 'no-cover' : ''}`}>
                          {teach.cover
                            ? <img alt={`${teach.name} ${teach.lastName}`} src={teach.cover}/>
                            : <ProText>{teach.name.charAt(0) + teach.lastName.charAt(0)}</ProText>
                          }
                        </div>
                        <div className='teach-info'>
                          <ProDisplay
                            type="5"
                            configuration={{
                              fontSize: 18,
                              fontWeight: 500,
                              color: '#5C5C5'
                            }}
                          >
                            {new DOMParser().parseFromString(teach.name, "text/html").documentElement.textContent} {new DOMParser().parseFromString(teach.lastName, "text/html").documentElement.textContent}
                          </ProDisplay>
                          <ProDisplay className='description'
                            type="5"
                            ellipsis={2}
							              tooltip={unescape(teach.info)}
                            configuration={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: '#5C5C5',
                              lineHeight: 1.8
                            }}
                          >
                            {unescape(teach.info)}
                          </ProDisplay>
                        </div>
                      </li>
                    )
                  ) : null}
                </TeachersList>
              </Col>
              <Col  className='feedback-col mt-4' xs={12} sm={7} md={7} lg={8}>

                <VideoRow className='like-row icons-container'>
                  {event.completed ?
                      <Completed className={'unset-grow completedLabel'}>
                        <ProText type="completed">COMPLETATO</ProText>
                      </Completed>
                      : null }
                  <Like
                    className={
                      event?.feedback?.currentUserVote === 1
                        ? 'icon-icona-like selected unset-grow'
                        : 'icon-icona-like unset-grow'
                    }
                    onClick={() => handleLike(1)}
                  />
                  <LikeWrapper>
                    <ProText type="label" configuration={{ lineHeight: 1.43 }}>
                      {event?.feedback?.positive}
                    </ProText>
                  </LikeWrapper>
                  <Dislike
                    className={
                      event?.feedback?.currentUserVote === 0
                        ? 'icon-icona-like selected selected'
                        : 'icon-icona-like'
                    }
                    onClick={() => handleLike(0)}
                  />
                  <LikeWrapper>
                    <ProText type="label" configuration={{ lineHeight: 1.43 }}>
                      {event?.feedback?.negative}
                    </ProText>
                  </LikeWrapper>
                </VideoRow>
                {event.askTheExpert ? (
                  <>
                    <VideoRowAsk>
                      <VideoCol>
                        <ProDisplay
                          type="5"
                          configuration={{
                            fontWeight: 500
                          }}
                        >
                          Ask the expert
                        </ProDisplay>
                        <ProText className='mt-1'>
                          Carica il tuo documento compilato da sottoporre al docente
                        </ProText>
                      </VideoCol>
                      <Link className='event-btn ask-btn' href={event.askTheExpertLinks[0]?.uri} target="_blank">
                        <ProText>
                          DOWNLOAD TEMPLATE
                        </ProText>
                      </Link>
                      <Button
                        text="CARICA"
                        type={Button.TYPE.UPLOAD}
                        hover
                        height={35}
                        fontSize={10}
                        onClick={() => openModal(WorkoutModal.id)}
                      />
                    </VideoRowAsk>
                    <WorkoutModal course = {{
                      id: event.session?.sessionId,
                      title: event.title,
                      uploadType: 'ASK_THE_EXPERT'
                    }}/>
                  </>
                ) : null}
                {event.completed ? (
                  <>
                    <VideoRowAsk>
                    {/* TODO: check disponibilita review */}
                      <ProDisplay
                        type="5"
                        configuration={{
                          fontWeight: 500
                        }}
                      >
                        Lascia una recensione
                      </ProDisplay>
                      <Button
                        text="INIZIA"
                        type={Button.TYPE.UPLOAD}
                        hover
                        height={35}
                        fontSize={10}
                        onClick={() => openModal(ReviewModal.id)}
                      />
                    </VideoRowAsk>
                    <ReviewModal itemId={event.itemId} objectType='PKG'/>
                  </>
                ) : null}
                <VideoRowAsk>
                  <VideoCol>
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontWeight: 500
                      }}
                    >
                      Note
                    </ProDisplay>
                    <ProText className='mt-1'>Cattura i tuoi pensieri, idee e domande</ProText>
                  </VideoCol>
                  <Button
                    text="AGGIUNGI"
                    type={Button.TYPE.UPLOAD}
                    hover
                    height={35}
                    fontSize={10}
                    onClick={() => openModal(NotesModalId)}
                  />
                </VideoRowAsk>
              </Col>
            </Row>
            </EventCoreRow>
          </Container>
          <Section className='gray-bg'>
              <Container>
              <TitleMaterials>
                  <Text>
                    <ProDisplay
                      type="5"
                      configuration={{
                        fontSize: 20,
                        lineHeight: 1.86
                      }}
                    >
                      Materiali didattici
                    </ProDisplay>
                  </Text>
                </TitleMaterials>
                {event?.teachingMaterial?.documents ?
                <Row className='link-doc-row'>
							{event?.teachingMaterial?.documents?.map((item, index) =>
                    <Col key={`${item.fileName}-${index}`} xs={12} sm={6} className="link-doc-row__docs">
                      <div className='item'>
                          <span className='icon'>
                            {item.fileExtension === 'pdf' ? <Pdf/>
                            : item.fileExtension === 'xls' || item.fileExtension === 'xlsx' || item.fileExtension === 'csv'  ? <Excel/>
                            : item.fileExtension === 'doc' || item.fileExtension === 'docx' ? <Word/>
                            : item.fileExtension === 'ppt' || item.fileExtension === 'pptx' ? <Powerpoint/>
                            : <span className='icon-icona-allega'></span>}
                            </span>
                          <div className='value'>
                            <Link href={item.fileUri} target="_blank">
                              <ProText
                                type="playlistTitle"
                                configuration={{ fontWeight: 'bold' }}
                              >
                              {new DOMParser().parseFromString(item.fileName, "text/html").documentElement.textContent}
                              </ProText>
                            </Link>
                          </div>
                          <Link href={item.fileUri} target="_blank" className='icon-download-link'>
                            <span className='icon-download'></span>
                          </Link>
                      </div>
                    </Col>
                    )}
                </Row>
                :
                  <Row>
                    <Col>
                      <NoMaterials>
                        <ProText>Non sono al momento disponibili materiali di approfondimento.</ProText>
                      </NoMaterials>
                    </Col>
                </Row>
                  }
              </Container>
            </Section>
            <Container>

            </Container>
          <NotesModal />
        </MainContainer>
        <Footer />
      </>
    );
  };

  Event.defaultProps = {
    event: {},
  };

  EventDetails.propTypes = {
    // HOC (connect, state)
    event: PropTypes.object,
    packageLabels: PropTypes.object,
    router: PropTypes.object,

    // HOC (connect, dispatch)
    openModal: PropTypes.func,
    pushUrl: PropTypes.func,
  };

  export default withMediaQueries(
    connect(
      state => {
        const {
          events: { selectedEvent: event },
          packages: { packageBreadcrumb: { description: packageDescription, title: packageTitle } },
        } = state.playlist;
        const { router } = state;
        return {
          event,
          packageLabels: { description: packageDescription, title: packageTitle },
          router,
        };
      },
      dispatch => ({
        getEvent: itemId => dispatch({ type: GET_EVENT._REQUEST, itemId }),
        handleFeedback: payload => dispatch({ type: HANDLE_FEEDBACK._REQUEST, payload }),
        openModal: id => dispatch({ type: MODAL_OPEN, id }),
        pushUrl: url => dispatch(push(url)),
      })
    )(EventDetails)
  );
