import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';

import { Button, Tooltip } from 'ui/atoms';
import Slider from 'ui/components/Slider';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { Container } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
// import { useAnalytics } from 'providers/analyticsProvider';
import { CustomRightArrow, CustomLeftArrow } from 'ui/atoms/CarouselArrows';
import { responsiveLandingCards } from 'utils/carousel';
import routes from 'routes';
import {
  LandingPageContainer,
  SubTitle,
  Title,
  TitleWrapper,
  Body,
  ButtonWrapper,
  Image,
  BodyContainer,
  HtmlWrapper,
  OverTitle,
  CtasWrapper,
  ImageWrapper,
  Placeholder,
  Layout,
  VideoTitle,
  VideoSubtitle,
  IconPlay,
  LandingCard,
  CardTitle,
  CardBody,
  CoursesContainer,
  CourseWrapper,
  Duration,
  IconPlaySmall,
  Dot,
  DotsWrapper,
  VideoContainer,
  TextBox,
  VideoBox,
  BackgroungImg,
  TextBoxImg,
  Overlay,
  CarouselWrapper,
  BodyWrapper,
  ImageContainer,
  BodiesContainer,
  SubTitleWrapper,
  Link,
  IconArrow,
} from './style';
import VideoPlayer from '../VideoPlayer';
import CarouselSDS from './CarouselSDS';

const RenderLandingParagraph = ({
  item,
  itemIndex,
  carousels,
  mediaIsPhone,
  mediaIsTablet,
  onClick,
  pushUrl,
}) => {
  const [showOver, setShowOver] = useState();
  const [size, setSize] = useState();

  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();

  useLayoutEffect(() => {
    function updateSize() {
      setSize(document.getElementsByClassName('container')[0]?.offsetWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const carousel =
    carousels && carousels.length > 0
      ? carousels?.find(car => car.id === `carousel-${itemIndex}`)
      : null;

  switch (item.type) {
    case 'Primo slider':
      return <Slider cards={item?.cards_primo_slider} onClick={onClick} alt isAutoPlay={true} />;
    case 'Carousel SDS':
      return <CarouselSDS item={item} cards={item?.cards_sds} />;
    case 'Video text overlay':
      return (
        <LandingPageContainer>
          {item.video_position === 'background' ? (
            <Placeholder image={item.image}>
              <Layout>
                <VideoTitle>
                  <ProDisplay
                    type="landingtitle"
                    configuration={{ color: 'white' }}
                  >
                    {item.title}
                  </ProDisplay>
                </VideoTitle>
                <VideoSubtitle>
                  <ProDisplay type="4" configuration={{ fontSize: '16' }}>
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </ProDisplay>
                </VideoSubtitle>
                <IconPlay className="icon-play" />
              </Layout>
            </Placeholder>
          ) : (
            <Container className={mediaIsPhone ? 'p-0' : null}>
              <VideoContainer mediaIsLeft={item.video_position === 'left'}>
                <TextBox bgColor={item?.color}>
                  <Title>
                    <ProDisplay
                      type="landingtitle"
                      configuration={mediaIsPhone ? { fontSize: 32 } : null}
                    >
                      {item.title}
                    </ProDisplay>
                  </Title>
                  {item?.description && (
                    <ProDisplay type="bodyLanding">
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </ProDisplay>
                  )}
                </TextBox>
                <VideoBox>
                  {/* <IconPlay className="icon-play" /> */}
                  <VideoPlayer
                    videoId={item.video_id}
                    skipTraking
                    image={item.image}
                  />
                </VideoBox>
              </VideoContainer>
            </Container>
          )}
        </LandingPageContainer>
      );
    case 'File Article':
      return (
        <LandingPageContainer bgColor={item?.color}>
          <Body>
            <Container className={mediaIsPhone ? 'p-0' : null}>
              {item.image_position === 'background' ? (
                <>
                  <BackgroungImg
                    image={item.image}
                    tbIsLeft={item.textbox_position === 'left'}
                  >
                    {!mediaIsPhone && (
                      <TextBoxImg>
                        <Title>
                          <ProDisplay
                            type="landingtitle"
                            configuration={{ fontSize: 26 }}
                          >
                            {item.title}
                          </ProDisplay>
                        </Title>
                        {item?.description && (
                          <ProDisplay type="bodyLanding">
                            <HtmlWrapper
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </ProDisplay>
                        )}
                        <CtasWrapper>
                          {item?.cta_1_title && (
                            <ButtonWrapper>
                              {item.cta_1_type === 'apple' ? (
                                <Button
                                  name="download"
                                  text="Scarica"
                                  onClick={() => onClick(item?.cta_1_url)}
                                  target="_blank"
                                  type={Button.TYPE.STORE}
                                  isDownload
                                />
                              ) : (
                                <Button
                                  name={item?.cta_1_title}
                                  text={item?.cta_1_title}
                                  onClick={() => onClick(item?.cta_1_url)}
                                  type={Button.TYPE.PRIMARY}
                                />
                              )}
                            </ButtonWrapper>
                          )}
                          {item?.cta_2_title && (
                            <ButtonWrapper>
                              {item.cta_2_type === 'apple' ? (
                                <Button
                                  name="download"
                                  text="Scarica"
                                  onClick={() => onClick(item?.cta_2_url)}
                                  type={Button.TYPE.STORE}
                                  isDownload
                                />
                              ) : (
                                <Button
                                  name={item?.cta_2_title}
                                  text={item?.cta_2_title}
                                  onClick={() => onClick(item?.cta_2_url)}
                                  type={Button.TYPE.LANDING}
                                />
                              )}
                            </ButtonWrapper>
                          )}
                        </CtasWrapper>
                      </TextBoxImg>
                    )}
                  </BackgroungImg>
                  {mediaIsPhone && (
                    <TextBoxImg>
                      <Title>
                        <ProDisplay
                          type="landingtitle"
                          configuration={{ fontSize: 26 }}
                        >
                          {item.title}
                        </ProDisplay>
                      </Title>
                      {item?.description && (
                        <ProDisplay type="bodyLanding">
                          <HtmlWrapper
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </ProDisplay>
                      )}
                      <CtasWrapper>
                        {item?.cta_1_title && (
                          <ButtonWrapper>
                            {item.cta_1_type === 'apple' ? (
                              <Button
                                name="download"
                                text="Scarica"
                                onClick={() => onClick(item?.cta_1_url)}
                                target="_blank"
                                type={Button.TYPE.STORE}
                                isDownload
                              />
                            ) : (
                              <Button
                                name={item?.cta_1_title}
                                text={item?.cta_1_title}
                                onClick={() => onClick(item?.cta_1_url)}
                                type={Button.TYPE.PRIMARY}
                              />
                            )}
                          </ButtonWrapper>
                        )}
                        {item?.cta_2_title && (
                          <ButtonWrapper>
                            {item.cta_2_type === 'apple' ? (
                              <Button
                                name="download"
                                text="Scarica"
                                onClick={() => onClick(item?.cta_2_url)}
                                type={Button.TYPE.STORE}
                                isDownload
                              />
                            ) : (
                              <Button
                                name={item?.cta_2_title}
                                text={item?.cta_2_title}
                                onClick={() => onClick(item?.cta_2_url)}
                                type={Button.TYPE.PRIMARY}
                              />
                            )}
                          </ButtonWrapper>
                        )}
                      </CtasWrapper>
                    </TextBoxImg>
                  )}
                </>
              ) : (
                <BodyContainer movedLeft={!(item?.image_position === 'left')}>
                  <BodyWrapper>
                    {item?.title && (
                      <TitleWrapper>
                        <OverTitle>
                          <ProDisplay
                            type="bodyLanding"
                            configuration={{ fontSize: 14, color: 'mineShaft' }}
                          >
                            <HtmlWrapper
                              dangerouslySetInnerHTML={{
                                __html: item?.body_2,
                              }}
                            />
                          </ProDisplay>
                        </OverTitle>
                        <Title>
                          <ProDisplay
                            type="landingtitle"
                            configuration={
                              mediaIsPhone ? { fontSize: 36 } : null
                            }
                          >
                            {item.title}
                          </ProDisplay>
                        </Title>
                        <SubTitle>
                          <ProDisplay
                            type="title"
                            configuration={{
                              fontWeight: 700,
                              fontSize: mediaIsPhone && '20',
                            }}
                          >
                            <HtmlWrapper
                              dangerouslySetInnerHTML={{ __html: item?.body }}
                            />
                          </ProDisplay>
                        </SubTitle>
                      </TitleWrapper>
                    )}
                    {item?.description && (
                      <ProDisplay type="bodyLanding">
                        <HtmlWrapper
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      </ProDisplay>
                    )}
                    <CtasWrapper>
                      {item?.cta_1_title && (
                        <ButtonWrapper>
                          {item.cta_1_type === 'apple' ? (
                            <Button
                              name="download"
                              text="Scarica"
                              onClick={() => onClick(item?.cta_1_url)}
                              type={Button.TYPE.STORE}
                              isDownload
                            />
                          ) : (
                            <Button
                              name={item?.cta_1_title}
                              text={item?.cta_1_title}
                              onClick={() => onClick(item?.cta_1_url)}
                              type={Button.TYPE.PRIMARY}
                            />
                          )}
                        </ButtonWrapper>
                      )}
                      {item?.cta_2_title && (
                        <ButtonWrapper>
                          {item.cta_2_type === 'apple' ? (
                            <Button
                              name="download"
                              text="Scarica"
                              onClick={() => onClick(item?.cta_2_url)}
                              type={Button.TYPE.STORE}
                              isDownload
                            />
                          ) : (
                            <Button
                              name={item?.cta_2_title}
                              text={item?.cta_2_title}
                              onClick={() => onClick(item?.cta_2_url)}
                              type={Button.TYPE.PRIMARY}
                            />
                          )}
                        </ButtonWrapper>
                      )}
                    </CtasWrapper>
                  </BodyWrapper>
                </BodyContainer>
              )}
            </Container>
          </Body>
          {item.image_position !== 'background' && (
            <ImageContainer stayLeft={item?.image_position === 'left'}>
              <ImageWrapper>
                <Image alt={item?.title} src={item?.image} />
              </ImageWrapper>
            </ImageContainer>
          )}
        </LandingPageContainer>
      );
    case 'Contents Carousel':
      return (
        <LandingPageContainer bgColor={item?.color}>
          <Body>
            <Container id="carousel-container">
              <Title style={{ paddingTop: 20 }}>
                <ProDisplay
                  type="landingtitle"
                  configuration={mediaIsPhone ? { fontSize: 36 } : null}
                >
                  {item.title}
                </ProDisplay>
              </Title>
              <SubTitleWrapper>
                {item?.subtitle && (
                  <ProDisplay type="bodyLanding">
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{ __html: item.subtitle }}
                    />
                  </ProDisplay>
                )}
                <Link onClick={() => {
                  // ga4IsInitialized && sendGA4Event({
                  //   eventCategory: 'homepage',
                  //   eventAction: 'click',
                  //   eventLabel: 'go_to_catalog',
                  // });
                  pushUrl(routes.catalog.path);
                }}
                >
                  <ProDisplay type="link">TUTTO IL CATALOGO</ProDisplay>
                  <IconArrow className="icon-btn-arrow" />
                </Link>
              </SubTitleWrapper>

              <CarouselWrapper>
                {carousel?.data?.length > 0 && (
                  <Carousel
                    swipeable
                    responsive={responsiveLandingCards(size)}
                    draggable={mediaIsPhone || mediaIsTablet}
                    customRightArrow={
                      mediaIsPhone || mediaIsTablet ? (
                        <></>
                      ) : (
                        <CustomRightArrow size={window.innerWidth - size} />
                      )
                    }
                    customLeftArrow={
                      mediaIsPhone || mediaIsTablet ? (
                        <></>
                      ) : (
                        <CustomLeftArrow size={window.innerWidth - size} />
                      )
                    }
                    containerClass="react-multi-carousel-list container pl-0 pb-4 "
                  >
                    {carousel?.data.map(card => (
                      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                      <LandingCard
                        image={card.image}
                        key={card.id}
                        onMouseOver={() => {
                          setTimeout(
                            () => setShowOver(`c${itemIndex}-${card.id}`),
                            150
                          );
                        }}
                        onMouseLeave={() => {
                          setTimeout(() => setShowOver(null), 150);
                        }}
                      >
                        <Overlay>
                          {showOver !== `c${itemIndex}-${card.id}` && (
                            <>
                              <CardTitle>
                                <ProDisplay
                                  type="4"
                                  configuration={{ color: 'white' }}
                                >
                                  {card.title}
                                </ProDisplay>
                              </CardTitle>
                              <CardBody>
                                <ProDisplay
                                  type="label"
                                  configuration={{ color: 'white' }}
                                >
                                  <HtmlWrapper
                                    dangerouslySetInnerHTML={{
                                      __html: card.body,
                                    }}
                                  />
                                </ProDisplay>
                              </CardBody>
                            </>
                          )}
                          {showOver === `c${itemIndex}-${card.id}` && (
                            <>
                              <CardTitle className="top">
                                <ProDisplay
                                  type="4"
                                  configuration={{ color: 'white' }}
                                >
                                  {card.title}
                                </ProDisplay>
                              </CardTitle>
                              <CoursesContainer>
                                {card.courses.length > 0 &&
                                  card.courses
                                    .slice(0, 5)
                                    .map((course, index) => (
                                      <CourseWrapper key={`course-${index}`}>
                                        {course.title.length > 30 ? (
                                          <Tooltip
                                            text={course.title
                                              .replace(/&#039;/g, "'")
                                              .replace(/&quot;/g, '"')
                                              .trim()}
                                            customProps={{
                                              className: 'landing-carousel',
                                            }}
                                          >
                                            <ProText
                                              type="courseTitle"
                                              configuration={{
                                                cursor: 'default',
                                              }}
                                            >
                                              {course.title
                                                .replace(/&#039;/g, "'")
                                                .replace(/&quot;/g, '"')
                                                .substr(0, 30)
                                                .trim()}
                                              ...
                                            </ProText>
                                          </Tooltip>
                                        ) : (
                                          <ProText
                                            type="courseTitle"
                                            configuration={{
                                              cursor: 'default',
                                            }}
                                          >
                                            {course.title
                                              .replace(/&#039;/g, "'")
                                              .replace(/&quot;/g, '"')
                                              .trim()}
                                          </ProText>
                                        )}
                                        <Duration>
                                          <IconPlaySmall className="icon-play" />
                                          <ProText type="courseDuration">
                                            {Math.ceil(
                                              course.video_duration / 60
                                            )}{' '}
                                            min
                                          </ProText>
                                        </Duration>
                                      </CourseWrapper>
                                    ))}
                              </CoursesContainer>
                              {card.courses.length > 5 && (
                                <DotsWrapper>
                                  <Dot />
                                  <Dot />
                                  <Dot />
                                </DotsWrapper>
                              )}
                            </>
                          )}
                        </Overlay>
                      </LandingCard>
                    ))}
                  </Carousel>
                )}
              </CarouselWrapper>
            </Container>
          </Body>
        </LandingPageContainer>
      );

    case 'Body':
    case 'Double Body':
      return (
        <LandingPageContainer bgColor={item?.color} className="body-only">
          <Container>
            <Title style={{ paddingTop: 20 }} className="lateral-padding">
              <ProDisplay
                type="landingtitle"
                configuration={mediaIsPhone ? { fontSize: 36 } : null}
              >
                {item.title}
              </ProDisplay>
            </Title>
            <BodiesContainer isDouble={item.type === 'Double Body'}>
              {item?.body && (
                <Body className="lateral-padding">
                  <ProDisplay type="bodyLanding">
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{ __html: item.body }}
                    />
                  </ProDisplay>
                </Body>
              )}
              {item?.body_2 && (
                <Body className="lateral-padding">
                  <ProDisplay type="bodyLanding">
                    <HtmlWrapper
                      dangerouslySetInnerHTML={{ __html: item.body_2 }}
                    />
                  </ProDisplay>
                </Body>
              )}
            </BodiesContainer>
          </Container>
        </LandingPageContainer>
      );
    default:
      return <></>;
  }
};

RenderLandingParagraph.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  carousels: PropTypes.array,
  
  item: PropTypes.object,
  itemIndex: PropTypes.number,
  onClick: PropTypes.func,
  pushUrl: PropTypes.func,
};

export default connect(
  state => {
    const { contentsCarousel: carousels } = state.landingPage;
    return {
      carousels,
    };
  },
  () => ({})
)(withMediaQueries(RenderLandingParagraph));
