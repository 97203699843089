import styled, { css } from 'styled-components/macro';
import { customScrollbar } from 'utils/common';

const dateTileEvent = css`
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 0;
  border-radius: 50%;
`;

const calendar = css`
  & > .react-multi-carousel-list {
    position: static;
    justify-content: ${props => (props.center ? 'center' : 'normal')};
  }

  & > .react-calendar {
    max-width: 556px;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    max-height: 305px;
    min-height: 305px;
    margin: ${props => props.vertical ? '0 auto 30px' : '0 30px 0 0'};
    padding: 18px 45px;
    border-radius: 20px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);

    @media ${props => props.theme.device.mobile} {
      padding: 18px
    }

    & .react-calendar__navigation {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;

      & .react-calendar__navigation__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
      }

      & .react-calendar__navigation__prev-button {
        right: 75%;
      }

      & .react-calendar__navigation__next-button {
        left: 75%;
      }
    }

    & .react-calendar__navigation__label,
    & .react-calendar__navigation__arrow,
    & .react-calendar__month-view__weekdays__weekday,
    & .react-calendar__tile {
      background-color: transparent;
      color: ${props => props.theme.colors.darkCharcoal};
      border: 0;
      font-family: ${props => props.theme.fontset.prodisplay};
    }

    & .react-calendar__navigation__label,
    & .react-calendar__month-view__weekdays__weekday,
    & .react-calendar__tile {
      font-size: 18px;
    }

    & .react-calendar__navigation__arrow {
      font-size: 30px;
      transform: translateY(-6%);
    }

    & .react-calendar__navigation__label {
      margin: 0 45px;
      flex-grow: 0 !important;
      font-weight: 700;
      text-transform: capitalize;
    }

    & .react-calendar__month-view__weekdays__weekday {
      position: relative;

      & abbr {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-decoration: none;
      }
    }

    & .react-calendar__month-view__weekdays__weekday,
    & .react-calendar__tile {
      width: 30px !important;
      height: 33px !important;
      flex-grow: 0 !important;
      font-weight: 800;

      &:disabled {
        font-weight: 600;
        opacity: 0.2;
      }
    }

    & .event-date-live,
    & .event-date-virtual {
      position: relative;
    }

    & .event-date-live {
      &:before {
        ${dateTileEvent}
        left: 25px;
        background-color: #BB21EA;
      }
    }

    & .event-date-virtual {
      &:after {
        ${dateTileEvent}
        right: 25px;
        background-color: #5F2ED3;
      }
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};

  ${calendar}
`;


export const CategoryWrapper = styled.div`
  padding: 70px 0;
  @media ${props => props.theme.device.mobile} {
    padding: 0;
  }
`;

export const FiltersWrapper = styled.div`
  padding-bottom: 20px;
  white-space: nowrap;
  overflow-x: auto;

  button {
    display: inline-block;
    .num{
      margin-left: 5px;
      display: inline-block;
    }
  }

  button:not(:last-child) {
    margin-right: 20px;
  }

  @media ${props => props.theme.device.mobile} {
    display:flex;
    flex-direction: column;
    overflow: unset;

    button {
      display: flex;
      width: 100%;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      justify-content: space-between;
      color: ${props => props.theme.colors.greenDark};
      border-bottom: 1px solid ${props => props.theme.colors.primary.base};
      padding: 20px 0;
      background: transparent;
      .num{
        margin-right: 10px;
        color: ${props => props.theme.colors.philippineGray};
      }
    }
  }

  ${customScrollbar()};
`;

export const Section = styled.div`
  padding-top: 25px;
  position: relative;
  &.gray-bg{
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-50vw);
      right: calc(-50vw);

      background: ${props => props.theme.colors.cultured};;
    }
    * {
      z-index: 1;
    }
  }
`;