import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import { push } from 'redux-first-history';
import routes from 'routes';
import { detailType } from 'utils/common';
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from 'redux/actions/social';
import { SET_PLAYLIST_HISTORY, SET_SECTION } from 'redux/actions/playlist';
import { GridContainer, ShowMore } from './style';
import ContentCardUser from '../ContentCardUser';
import RecommendModal from '../Modals/RecommendModal';

const Grid = ({
  elements,
  ellipsis,
  location,
  section,
  // HOC (connect, state)
  bookmarks,
  router,
  // HOC (connect, dispatch)
    addBookmark,
  pushUrl,
  removeBookmark,
  setPlaylistHistory,
  setSection,
  // HOC media queries

  mediaIsPhone,
  mediaIsTablet,
}) => {
  const [showNumber, setShowNumber] = useState(12);
  const [showMore, setShowMore] = useState(0);

  useEffect(() => {
    if (mediaIsPhone || mediaIsTablet) setShowMore(12);
    else setShowMore(20);
  }, [mediaIsPhone, mediaIsTablet]);

  const onChangeLocation = item => {
    setSection(section);
    setPlaylistHistory(router.location.pathname);
    switch (item.type.toLowerCase()) {
      case 'news':
        pushUrl(`${routes.detail.path}/${detailType.NEWS}/${item.itemId}`);
        break;
      case 'training':
      case 'learning_object':
        pushUrl(`${routes.detail.path}/${detailType.COURSE}/${item.itemId}`);
        break;
      case 'collection':
      case 'selection': {
        const itemId = item.id ? item.id : item.itemId;
        if (item.asset) {
          pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
        } else pushUrl(`${routes.lockedDetails.path}/${itemId}`);
        break;
      }
      default:
        break;
    }
  };

  const handleBookmark = data => {
    const body = {
      itemId: data.itemId,
      type: data.type,
      title: data.title,
      imageUrl: data.cover,
      duration: 0,
      format: '',
      completed: data.completed,
      coursesCount: data.coursesCount
        ? data.coursesCount
        : parseInt(data.progress?.split('/')[1])
    };
    if (bookmarks.findIndex(b => b.itemId === data.itemId) >= 0) { removeBookmark({ itemId: body.itemId, itemType: body.type }); } else addBookmark(body);
  };

  return (
    <>
      <GridContainer location={location}>
        {elements?.slice(0, showNumber).map(item => (
          <ContentCardUser
            key={item.itemId}
            type={location}
            data={{
              ...item,
              bookmarked:
                bookmarks.findIndex(
                  bm => bm.itemId?.toString() === item.itemId?.toString()
                ) >= 0
            }}
            onClick={() => onChangeLocation(item)}
            handleBookmark={e => {
              handleBookmark(item);
              e.stopPropagation();
            }}
            ellipsis={ellipsis}
          />
        ))}
      </GridContainer>
      {showNumber < elements?.length && (
        <ShowMore onClick={() => setShowNumber(showNumber + showMore)}>
          <ProText type="showMore">Mostra altri</ProText>
        </ShowMore>
      )}
      <RecommendModal />
    </>
  );
};

Grid.propTypes = {
  elements: PropTypes.array,
  ellipsis: PropTypes.number,
  location: PropTypes.string,
  section: PropTypes.string,

  // HOC (connect, state)
  bookmarks: PropTypes.array,
  router: PropTypes.object,

  // HOC (connect, dispatch)
  addBookmark: PropTypes.func,
  pushUrl: PropTypes.func,
  removeBookmark: PropTypes.func,
  setPlaylistHistory: PropTypes.func,
  setSection: PropTypes.func,

  // HOC (MediaQueries)
  mediaIsPhone: PropTypes.bool,
  mediaIsTablet: PropTypes.bool
};

export default withMediaQueries(
  connect(
    state => {
      const { bookmarklist: bookmarks } = state.social.bookmarks;
      const { router } = state;
      return { bookmarks, router };
    },
    dispatch => ({
      addBookmark: payload =>
        dispatch({ type: ADD_BOOKMARK._REQUEST, payload }),
      pushUrl: url => dispatch(push(url)),
      removeBookmark: payload =>
        dispatch({ type: REMOVE_BOOKMARK._REQUEST, payload }),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
      setSection: section => dispatch({ type: SET_SECTION, section }),
    })
  )(Grid)
);
