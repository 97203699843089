import produce from 'immer';
import {
  AUTHENTICATION_SIGNIN,
  AUTHENTICATION_SIGNOUT,
  RESET_DATA_TREATMENT
} from 'redux/actions/authentication';
import {
  SIGNUP_SET_MANAGER_INFO,
  SIGNUP_SET_MANAGER_PAYMENT_INFO
} from 'redux/actions/signup';
import {
  PROFILE_DATA_ACCOUNT_GET,
  PROFILE_USER_SET
} from 'redux/actions/profile';

const initialState = {
  user: {}
};

const authenticationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case AUTHENTICATION_SIGNIN._SUCCESS: {
        const { user } = action;
        draft.user = user;
        break;
      }

      case AUTHENTICATION_SIGNOUT._SUCCESS: {
        draft.user = initialState.user;
        break;
      }

      case SIGNUP_SET_MANAGER_INFO._SUCCESS: {
        const { response: { userId, userid } = {} } = action;

        draft.user = {
          ...state.user,
          userId: userId || userid
        };
        break;
      }

      case SIGNUP_SET_MANAGER_PAYMENT_INFO._SUCCESS: {
        const { response: { organizationId, organizationid, zuoraAccountNumber, zuoraAccountId } = {} } = action;

        draft.user = {
          ...state.user,
          organizationId: organizationId || organizationid,
          zuoraAccountNumber,
          zuoraAccountId
        };
        break;
      }

      case PROFILE_DATA_ACCOUNT_GET._SUCCESS: {
        const { response } = action;

        draft.user = {
          ...state.user,
          ...response
        };
        break;
      }

      case PROFILE_USER_SET: {
        const { userId } = action;
        draft.user = {
          ...state.user,
          userId
        };

        break;
      }
      case RESET_DATA_TREATMENT: {
        draft.user = {
          ...state.user,
          sendDataTreatment: false
        };
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default authenticationReducer;
