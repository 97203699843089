import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Footer, Grid } from 'ui/components';
import { push } from 'redux-first-history';
import ProDisplay from 'ui/typography/proDisplay';
import routes from 'routes';
import { GET_CATEGORY_PLAYLIST, GET_PATH } from 'redux/actions/playlist';
import { detailType } from 'utils/common';
import { Head, IconBack, Title, Text, BtnContainer, LockedEvents } from './style';
import MainContainer from '../style';
import { PlaylistTypes } from 'const';
import { Button } from 'ui/atoms';

const AllPlaylists = ({
  pushUrl,
  getMyPlaylists,
  collections = [],
  selections,
  categoriesPills,
  filterId,
  name,
  filteredCollections,
  type,
  playlistCategories,
  categoryPlaylistNext,
  getCategories
}) => {
  const [playlists, setPlaylists] = useState([]);

  const [buttonVisible, setbuttonVisible] = useState(false);
  const [categoryPlaylistId, setCategoryPlaylistId] = useState('');

  useEffect(() => {
    if(!categoryPlaylistId && Boolean(categoriesPills)) {
      const categoryPlaylistId = categoriesPills[0]?.needId;
      setCategoryPlaylistId(categoryPlaylistId)
    }
  }, [categoriesPills]);

  const dispatch = useDispatch();

  const getNextCategoryList = useCallback((categoryPlaylistId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId: categoryPlaylistId }), [dispatch]);
  
  useEffect(() => {
    if (!filterId) {
      // getAllCollections();
      getMyPlaylists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterId]);

  useEffect(() => {
    if(!Boolean(type)) {
      if (filterId && filterId > 0) {
        setPlaylists(filteredCollections);
      } else {
        setPlaylists([...collections, ...selections]);
      }
    }
  }, [collections, selections, filteredCollections, filterId, type]);

  useEffect(() => {
    if (Boolean(type) && Boolean(playlistCategories)) {
      switch (type) {
        case PlaylistTypes.CATEGORIES:
          setPlaylists(playlistCategories);
          setbuttonVisible(categoryPlaylistNext);
          break;
        default:
          break;
      }
    }
  }, [type, playlistCategories, categoryPlaylistNext]);

  function getNextPlaylist(type) {
    switch (type) {
      case PlaylistTypes.CATEGORIES:
        getNextCategoryList(categoryPlaylistId);
        break;

      default:
        break;
    }
  }

  function getPageTitle(type) {
    switch (type) {
      case PlaylistTypes.CATEGORIES:
        const categoryTitle = categoriesPills.find(pill => playlistCategories.find(category => category?.needId === pill?.needId))?.title;

        return {
          title: categoryTitle,
        }
    
      default:
        break;
    }
  }

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">
                { type ? getPageTitle(type)?.title : name || 'La tua formazione' }
              </ProDisplay>
              <Text>
                <ProDisplay
                  type="tableLabel"
                  configuration={{ color: 'placeholder' }}>
                  { type ? getPageTitle(type)?.subtitle : null }
                </ProDisplay>
              </Text>
            </Title>
          </Head>
          {playlists?.length > 0 ? (
            <>
              <Grid
                elements={playlists}
                location={detailType.PLAYLIST}
                section="playlist"
              />
              { buttonVisible && <BtnContainer>
                <Button
                  text="Mostra altri"
                  type="PRIMARY"
                  height={35}
                  fontSize={16}
                  onClick={() => getNextPlaylist(type)}
                />
              </BtnContainer> }
            </>
          ) : (
            <Text>
              <ProDisplay type="1">
                Non ci sono collections associate a questa categoria.
              </ProDisplay>
            </Text>
          )}
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

AllPlaylists.propTypes = {
  // HOC (connect, state)
  collections: PropTypes.array,
  selections: PropTypes.array,
  playlistCategories: PropTypes.array,
  categoriesPills: PropTypes.array,
  filterId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]
  ),
  name: PropTypes.string,
  filteredCollections: PropTypes.array,
  categoryPlaylistNext: PropTypes.bool,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func,
  // getAllCollections: PropTypes.func,
  getMyPlaylists: PropTypes.func
};

export default withMediaQueries(
  connect(
    state => {
      const {
        selection: { items: selections },
        collection: { items: collections }
      } = state.playlist.playlists.yourpathprogress;
      // const { allCollections: collections } = state.playlist.playlists;
      const playlistCategories = state.playlist.categoryPlaylist.items;
      const categoriesPills = state.playlist.categoriesPills;
      const categoryPlaylistNext = Boolean(state.playlist.categoryPlaylist.nextPage);

      const { filterId, name, filteredCollections } = state.playlist.filters;
      return {
        selections,
        collections,
        filterId,
        name,
        filteredCollections,
        playlistCategories,
        categoriesPills,
        categoryPlaylistNext
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getMyPlaylists: () =>
        dispatch({
          type: GET_PATH._REQUEST
        })
    })
  )(AllPlaylists)
);
