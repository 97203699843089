const MainTitle = 'Skill 4 Business';

const ENV_BASE_PATH = {
  DEV: null,
  SIT: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};

const basePath =
  ENV_BASE_PATH[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const baseRoutes = {
  auth: {
    path: `${basePath}auth`,
    title: `Auth - ${MainTitle}`
  },
  home: {
    path: `${basePath}test-components`,
    title: `Home - ${MainTitle}`
  },
  maintenance: {
    path: `${basePath}`,
    title: `${MainTitle}`,
    showNavbar: false
  },
  notFound: {
    path: `${basePath}404`,
    title: 'Not Found'
  },
  landingPage: {
    path: `${basePath}`,
    title: `${MainTitle}`,
    showNavbar: false
  },
  pricedCatalog: {
    // path: `${basePath}priced-catalog`,
    path: 'https://www.digitallibrary.digited.it/', //feat redirect nuova piattaforma 20240524
    title: `${MainTitle} - Priced catalog`,
    showNavbar: false
  },
  catalog: {
    // path: `${basePath}catalog`,
    path: 'https://www.digitallibrary.digited.it/', //feat redirect nuova piattaforma 20240524
    title: `${MainTitle} - Catalog`,
    showNavbar: false
  },
  signup: {
    path: `${basePath}signup`,
    title: `${MainTitle} - Iscriviti`
  },

  access: {
    path: `${basePath}access`,
    title: 'Landing Page - Access',
    showNavbar: false
  },
  resetPassword: {
    path: `${basePath}reset-password`,
    title: 'Landing Page - Reset Password',
    showNavbar: false
  },
  changePassword: {
    path: `${basePath}change-password`,
    title: 'Landing Page - Change Password',
    showNavbar: false
  },
  confirmPassword: {
    path: `${basePath}confirm-password`,
    title: 'Landing Page - Confirm Password',
    showNavbar: false
  },
  confirmChangedPassword: {
    path: `${basePath}confirm-changed-password`,
    title: 'Landing Page - Confirm Changed Password',
    showNavbar: false
  },

  setFirstPassword: {
    path: `${basePath}set-first-password`,
    title: 'Iscriviti - New Password',
    showNavbar: false
  },
  setFirstPasswordFromBank: {
    path: `${basePath}set-first-password-from-bank`,
    title: 'Landing Page - Confirm Password',
    showNavbar: false
  },
  sessionExpired: {
    path: `${basePath}session-expired`,
    title: 'Sessione Scaduta',
    showNavbar: false
  },
  setFiscalCode: {
    path: `${basePath}set-fiscal-code`,
    title: `${MainTitle} - Set Fiscal Code`,
    showNavbar: false
  }
};

const signupRoutes = {
  subscription: {
    // path: `${baseRoutes.signup.path}/subscriptions`,
    path: 'https://www.digitallibrary.digited.it/', //feat redirect nuova piattaforma 20240524
    title: 'Iscriviti - Subscriptions',
    showNavbar: false
  },
  userAccount: {
    path: `${baseRoutes.signup.path}/user-account`,
    title: 'Iscriviti - User Account',
    showNavbar: false
  },
  billings: {
    path: `${baseRoutes.signup.path}/billings`,
    title: 'Iscriviti - Billings',
    showNavbar: false
  },
  confirm: {
    path: `${baseRoutes.signup.path}/confirm`,
    title: 'Iscriviti - Confirm',
    showNavbar: false
  },
  confirmFromBank: {
    path: `${baseRoutes.signup.path}/confirm-from-bank`,
    title: 'Iscriviti - Confirm',
    showNavbar: false
  },
  confirmOtp: {
    path: `${baseRoutes.signup.path}/confirm-otp`,
    title: 'Iscriviti - Confirm OTP',
    showNavbar: false
  }
};

const commonRoutes = {
  profile: {
    path: `${basePath}profile`,
    title: `${MainTitle} - Profile`
  },
  choosePortal: {
    path: `${basePath}choose-portal`,
    title: 'Landing Page - Choose Portal',
    showNavbar: false
  }
};

const managerRoutes = {
  teamsActivity: {
    path: `${basePath}teams-activity`,
    title: `${MainTitle} - Teams Activity`
  },
  allContent: {
    path: `${basePath}teams-activity/all-content`,
    title: `${MainTitle} - All Content`
  },
  allActivity: {
    path: `${basePath}teams-activity/all-activity`,
    title: `${MainTitle} - All Activity`
  },

  mySubscription: {
    path: `${basePath}my-subscription`,
    title: `${MainTitle}`
  },
  allSubsriptions: {
    path: `${basePath}my-subscription/all-subscriptions`,
    title: 'My Subscriptions - Subscriptions'
  },
  teamsInterest: {
    path: `${basePath}my-subscription/teams-interest`,
    title: 'My Subscriptions - Teams Interest'
  },
  recommended: {
    path: `${basePath}my-subscription/recommended`,
    title: 'My Subscriptions - Recommended'
  },
  s4bCatalog: {
    path: `${basePath}my-subscription/catalog`,
    title: 'My Subscriptions - S4B Catalog'
  },
  premiumPurchase: {
    path: `${basePath}premium-purchase`,
    title: `${MainTitle} - Premium Purchase`
  },
  premiumSubscriptions: {
    path: `${basePath}premium-purchase/subscription`,
    title: 'Premium Purchase - Subscription',
    showNavbar: false
  },
  editData: {
    path: `${commonRoutes.profile.path}/edit-data`,
    title: `${MainTitle} - Edit Data`,
    showNavbar: false
  },
  editCards: {
    path: `${commonRoutes.profile.path}/edit-cards`,
    title: `${MainTitle} - Edit Cards`,
    showNavbar: false
  },
  lastPayments: {
    path: `${commonRoutes.profile.path}/last-payments`,
    title: `${MainTitle} - Last Payments`
  },
  managerProfile: {
    path: `${commonRoutes.profile.path}/manager`,
    title: `${MainTitle} - Manager`
  },
  followProfileManager: {
    path: `${commonRoutes.profile.path}/manager/follow`,
    title: `${MainTitle} - Follow Profile Manager`
  }
};
const userRoutes = {
  userProfile: {
    path: `${commonRoutes.profile.path}/user`,
    title: `${MainTitle} - User`
  },
  FollowProfileUser: {
    path: `${commonRoutes.profile.path}/follow`,
    title: `${MainTitle} - Follow`
  },
  homepage: {
    path: `${basePath}homepage`,
    title: 'Homepage - User'
  },
  search: {
    path: `${basePath}search`,
    title: 'Ricerca - User'
  },
  collectionDetails: {
    path: `${basePath}playlist`,
    title: 'Playlist - Details'
  },
  lockedDetails: {
    path: `${basePath}locked`,
    title: 'Locked - Details'
  },
  allFiveMinutes: {
    path: `${basePath}five-minutes-all`,
    title: 'Five Minutes - View all'
  },
  allPlaylists: {
    path: `${basePath}playlists-all`,
    title: 'Playlist - View all'
  },
  allPlaylistsCategories: {
    path: `${basePath}playlists-category`,
    title: 'Playlist categories - View all'
  },
  allCourses: {
    path: `${basePath}courses-all`,
    title: 'Courses - View all'
  },
  notes: {
    path: `${basePath}notes`,
    title: `${MainTitle} - Note`
  },
  package: {
    path: `${basePath}package`,
    title: `${MainTitle} - Product`
  },
  detail: {
    path: `${basePath}detail`,
    title: `${MainTitle} - Detail`
  },
  event: {
    path: `${basePath}event`,
    title: `${MainTitle} - Event`
  },
  createUser: {
    path: `${basePath}create-user`,
    title: 'Landing Page - Create User',
    showNavbar: false
  },
  confirmCreateUser: {
    path: `${basePath}confirm-create-user`,
    title: 'OnBoarding -  Confirm Create User',
    showNavbar: false
  },
  drawProfile: {
    path: `${basePath}draw-profile`,
    title: 'OnBoarding - Draw Profile',
    showNavbar: false
  },
  compileProfile: {
    path: `${basePath}compile-profile`,
    title: 'OnBoarding -  Compile Profile',
    showNavbar: false
  },
  compileManagerProfile: {
    path: `${basePath}compile-manager-profile`,
    title: 'OnBoarding -  Compile Manager Profile',
    showNavbar: false
  }
};

const routes = {
  ...baseRoutes,
  ...signupRoutes,
  ...commonRoutes,
  ...managerRoutes,
  ...userRoutes
};

export const listPaths = {
  user: [...Object.values(userRoutes)].map(r => r.path),
  manager: [...Object.values(managerRoutes)].map(r => r.path)
};

export default routes;
