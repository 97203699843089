import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { Button } from 'ui/atoms';
import { Title, Subtitle, ContentWrapper, Icon, ButtonWrapper } from './style';

import { ModalWrapper } from '../InviteFriendModal/style';

const modalId = 'DownloadAppModal';

const DownloadAppModal = ({ onClose }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          <ContentWrapper>
            <Icon className="icon-close-x" onClick={onClose} />
            <Title>
              <ProDisplay type="modalTitle">Attenzione</ProDisplay>
            </Title>
            <Subtitle>
              <ProDisplay
                configuration={{
                  lineHeight: 1.5,
                  letterSpacing: '0.25px',
                  fontSize: '18'
                }}
              >
                Per continuare a fruire dei contenuti, è necessario scaricare
                l'app!
              </ProDisplay>
            </Subtitle>
            <ButtonWrapper>
              <Button
                name="download"
                text="Scarica"
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/us/app/skills4capital/id1575702730',
                    '_blank'
                  );
                }}
                type="STORE"
                isDownload
              />
            </ButtonWrapper>
          </ContentWrapper>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

DownloadAppModal.propTypes = {
  // HOC (connect, state)

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

DownloadAppModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(DownloadAppModal);
