import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  CATALOG_PRODUCT_GET,
  GET_CATEGORIES,
  GET_FILTERED_CATALOG,
} from 'redux/actions/subscriptions';

import { Container } from 'ui/gridSystem';
import { ContentCard } from 'ui/components/index';
import { withMediaQueries } from 'hoc/withMediaQueries';

import { CustomInput } from 'ui/atoms';
import {
  CatalogContainer,
  SelectWrapper,
  SearchWrapper,
  IconSearch,
  SearchContainer,
} from './style';
import MainContainer from '../../../style';

const S4BCatalog = ({
  getProductCatalog,
  catalog,
  filteredCatalog,
  getCategories,
  categoriesOptions,
  getFilteredCatalog,
}) => {
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
    getProductCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFilteredCatalog(0);
  }, [catalog, getFilteredCatalog]);

  const handleOnChange = value => {
    getFilteredCatalog(value);
  };

  const applySearch = plan =>
    plan.product_title?.toLowerCase().includes(search.toLowerCase());

  useEffect(() => {
    const orderedCategories = categoriesOptions
      .map(o => ({
        value: o.id,
        label: o.name,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));

    const index = orderedCategories.findIndex(c => c.value === 0);
    const all = orderedCategories.splice(index, 1);

    setCategories([...all, ...orderedCategories]);
  }, [categoriesOptions]);

  return (
    <MainContainer noMenu>
      <Container>
        <SearchWrapper>
          {' '}
          <SelectWrapper>
            <CustomInput
              type="select"
              placeholder="Categorie"
              options={categories}
              onChange={handleOnChange}
              // value={o.id}
            />
          </SelectWrapper>
          <IconSearch
            className="icon-ricerca"
            onClick={() => setShowSearch(!showSearch)}
          />
          {showSearch && (
            <SearchContainer>
              <CustomInput
                type="text"
                placeholder="Cerca"
                value={search}
                onChange={val => setSearch(val)}
              />
            </SearchContainer>
          )}
        </SearchWrapper>
        <CatalogContainer>
          {filteredCatalog.filter(applySearch).map((plan, index) => (
            <ContentCard
              key={`content-card-${plan.content_id}-${index}`}
              plan={{
                ...plan,
                num_content: plan.learning_contents.length,
                cover_image: plan.cover,
                price: plan.price_number || plan.price || 0.0,
                name: plan.product_title,
              }}
            />
          ))}
        </CatalogContainer>
      </Container>
    </MainContainer>
  );
};

S4BCatalog.propTypes = {
  //HOC (connect, state)
  categoriesOptions: PropTypes.array,
  //HOC (connect, dispatch)
  catalog: PropTypes.array,
  filteredCatalog: PropTypes.array,
  getProductCatalog: PropTypes.func,
  getCategories: PropTypes.func,
  getFilteredCatalog: PropTypes.func,
};

export default connect(
  state => {
    const {
      catalog,
      filteredCatalog,
      filters,
      categoriesOptions,
    } = state.subscriptions;
    return {
      catalog,
      filteredCatalog,
      filters,
      categoriesOptions,
    };
  },
  dispatch => ({
    getProductCatalog: () =>
      dispatch({
        type: CATALOG_PRODUCT_GET._REQUEST,
      }),
    getCategories: () => dispatch({ type: GET_CATEGORIES._REQUEST }),
    getFilteredCatalog: filterId =>
      dispatch({ type: GET_FILTERED_CATALOG._REQUEST, filterId }),
  })
)(withMediaQueries(S4BCatalog));
