import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { GET_FIVE_MINUTES } from 'redux/actions/fiveMinutes';
import {
  GET_CATEGORIES_USER,
  GET_COLLECTIONS,
  GET_EVENTS,
  GET_KEEP_WATCHING,
  GET_PACKAGES,
  GET_RELATED_COLLECTIONS,
  GET_SELECTIONS,
  SET_CATEGORIES_FILTER,
  SET_PLAYLIST_HISTORY,
  SET_SECTION,
  GET_CATEGORIES_PILLS,
  GET_CATEGORY_PLAYLIST,
  RESET_PLAYLIST
} from 'redux/actions/playlist';
import routes from 'routes';
import { detailType } from 'utils/common';
import { getFirstLastDayOfMonth } from 'utils/date-format';

/**
 * custom hook to fetch the config objects for the sections of the homepage
 * @param currentViewport the current viewport key
 * @param viewportTypes the map of available viewports
 * @returns a config object with a list of sections to display.
 */
const useSections = (currentViewport, viewportTypes) => {
  // HOC, state
  const categories = useSelector(state => state.playlist.categories);
  const categoriesPills = useSelector(state => state.playlist.categoriesPills);
  const collections = useSelector(state => state.playlist.collections);
  const recommendedCollections = useSelector(state => state.playlist.recommendedCollections);
  const domainId = useSelector(state => state.domain.id);
  const domainTitle = useSelector(state => state.domain.title);
  const events = useSelector(state => state.playlist.events.plannedEvents);
  const fiveMinutes = useSelector(state => state.fiveMinutes.fiveMinutes);
  const keepWatching = useSelector(state => state.playlist.keepWatching);
  const packages = useSelector(state => state.playlist.packages.packageList);
  const router = useSelector(state => state.router);
  const selections = useSelector(state => state.playlist.selections);
  // HOC, dispatch
  const dispatch = useDispatch();
  const getCategories = useCallback(() => dispatch({ type: GET_CATEGORIES_USER._REQUEST }), [dispatch]);
  const getCategoriesPills = useCallback(() => dispatch({ type: GET_CATEGORIES_PILLS._REQUEST }), [dispatch]);
  const getCategoryPlaylist = useCallback((filterId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId }), [dispatch]);
  const getCollections = useCallback(() => dispatch({ type: GET_COLLECTIONS._REQUEST }), [dispatch]);
  const getRelatedCollections = useCallback(() => dispatch({ type: GET_RELATED_COLLECTIONS._REQUEST }), [dispatch]);
  const getEvents = useCallback(({ startDate, endDate }) => dispatch({ type: GET_EVENTS._REQUEST, startDate, endDate }), [dispatch]);
  const getFiveMinutes = useCallback(() => dispatch({ type: GET_FIVE_MINUTES._REQUEST, forceReload: true }), [dispatch]);
  const getKeepWatching = useCallback(() => dispatch({ type: GET_KEEP_WATCHING._REQUEST }), [dispatch]);
  const getPackages = useCallback(() =>  dispatch({ type: GET_PACKAGES._REQUEST }), [dispatch]);
  const getSelections = useCallback(() => dispatch({ type: GET_SELECTIONS._REQUEST }), [dispatch])
  const pushUrl = (url) => dispatch(push(url));
  const setFilters = useCallback((filters) => dispatch({ type: SET_CATEGORIES_FILTER, filters }), [dispatch]);
  const setPlaylistHistory = (source) => dispatch({ type: SET_PLAYLIST_HISTORY, source });
  const setSection = (section) => dispatch({ type: SET_SECTION, section });
  const resetPlaylist = useCallback(() => dispatch({ type: RESET_PLAYLIST._SUCCESS }), [dispatch]);

  useEffect(() => {
    switch(domainId) {
      //Prelios
      case 301:
      case 2485:
        {
          getSelections();
          getCollections();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getRelatedCollections();
          getFiveMinutes();
          getKeepWatching();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
        break;
      default:
        {
          resetPlaylist(); //clear playlists
          getFiveMinutes();
          getPackages();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getKeepWatching();
          getCategoriesPills();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
    }
  }, [
    domainId,
    getCategories,
    getCollections,
    getEvents,
    getFiveMinutes,
    getKeepWatching,
    getPackages,
    getRelatedCollections,
    getSelections,
    setFilters,
  ]);

  const bannerSection = {
    bannerImage: null,
    bannerText: 'Master English: unlock your language potential!',
    carouselCardClickHandler: null,
    carouselType: null,
    data: null,
    description: null,
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: null,
    type: 'banner',
    vertical: null,
  };

  const categoriesSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: categoriesPills,
    description: null,
    getData: null,
    grayBg: null,
    link: null,
    pillsClickHandler: (pill) => {
      getCategoryPlaylist(pill)
      /*setFilters({
        filterId: pill.id,
        name: pill.title,
        filteredCollections: pill.items.map(i => ({ ...i, itemId: i.id })),
      });*/
      pushUrl(routes.allPlaylistsCategories.path);
    },
    shouldHide: !(Array.isArray(categoriesPills) && categoriesPills.length > 0),
    showCalendar: null,
    title: null,
    type: 'pills',
    vertical: null,
  };

  const collectionsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: collections,
    description: 'Approfondisci tematiche cross rispetto al tuo ruolo e sviluppa le Soft Skill',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: 'Formazione trasversale',
    type: 'carousel',
    vertical: null,
  };

  const eventsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: events,
    description: null,
    link: null,
    getData: ({ action, activeStartDate, value, view }) => {
      const { firstDay, lastDay } = getFirstLastDayOfMonth(activeStartDate);
      getEvents({
        // startDate: getIsoDateStringFromDate(firstDay),
        // endDate: getIsoDateStringFromDate(lastDay),
        startDate: firstDay.getTime(),
        endDate: lastDay.getTime(),
      });
    },
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(packages) && packages.some(pkg => pkg.packageType.toLowerCase() === 'premium')),
    showCalendar: (viewportTypes[currentViewport] > viewportTypes.mediaIsTablet),
    title: 'Eventi pianificati',
    type: 'calendar',
    vertical: viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop,
  };

  const keepWatchingSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        type: detailType.COURSE,
        section: 'keepWatching',
      });
    },
    carouselType: detailType.COURSE,
    data: keepWatching,
    description: 'Resta aggiornato con le più importanti novità',
    link: {
      text: 'Vedi tutti',
      to: `${routes.allCourses.path}`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(keepWatching) && keepWatching.length > 0),
    showCalendar: null,
    title: 'Continua a guardare',
    type: 'carousel',
    vertical: null,
  };

  const newsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'fiveMinutes',
        type: card.type === detailType.RELATED_COURSE
          ? detailType.COURSE
          : card.type,
        }
      )
    },
    carouselType: 'news',
    data: fiveMinutes,
    description: 'Resta aggiornato con le più importanti novità',
    link: {
      text: 'Vedi tutti',
      to: `${routes.allFiveMinutes.path}`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: 'News',
    type: 'carousel',
    vertical: null,
  };

  const packagesSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      pushUrl(`${routes.package.path}/${card.packageId}`)
    },
    carouselType: detailType.PACKAGE,
    data: packages,
    description: null,
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: 'La tua formazione',
    type: 'carousel',
    vertical: null,
  };

  const recommendedCollectionsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: recommendedCollections,
    description: 'Non accontentarti!\nPerfeziona la tua formazione: scopri nuovi contenuti per irrobustire la tua  conoscenza',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: 'Suggeriti per te',
    type: 'carousel',
    vertical: null,
  };

  const selectionsSections = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: selections,
    description: 'Qui puoi trovare i corsi obbligatori che dovrai svolgere',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: 'Formazione obbligatoria',
    type: 'carousel',
    vertical: null,
  };

  const preliosSections = [
    { ...selectionsSections, grayBg: true },
    eventsSection,
    collectionsSection,
    bannerSection,
    recommendedCollectionsSection,
    newsSection,
    keepWatchingSection,
    categoriesSection,
  ];

  const s4cSections = [
    newsSection,
    { ...packagesSection, grayBg: true },
    { ...eventsSection, grayBg: true },
    { ...keepWatchingSection, grayBg: true },
    { ...categoriesSection },
  ];

  const sections = {
    Prelios: preliosSections,
    Skills4Capital: s4cSections,
  };

  switch(domainId) {
    case 301:
    case 2485:
      return sections['Prelios'];
    default:
      return sections['Skills4Capital'];
  }

  function openDetails({id, type, section}) {
    setSection(section);
    setPlaylistHistory(router.location.pathname);
    pushUrl(`${routes.detail.path}/${type}/${id}`);
  };
};

export default useSections;