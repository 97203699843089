import React from "react";
import PropTypes from 'prop-types';
import ProText from "ui/typography/proText";
import { Section } from 'ui/components/Homepage/Section/style';
import {
  Banner,
  BannerLogo,
  BannerText,
} from "./style";

const SectionBanner = ({ bannerImage, bannerText }) => {
  return (
    <Section>
      <Banner bg={bannerImage} height={330}>
        <BannerText>
          <ProText
            configuration={{
              fontSize: 56,
              fontWeight: 700,
            }}
            ellipsis={2}
          >
            {bannerText}
          </ProText>
        </BannerText>
        <BannerLogo src='' alt='banner-logo' />
      </Banner>
    </Section>
  );
};

SectionBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerText: PropTypes.string,
}

export default SectionBanner;