import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from 'routes';
import { push } from 'redux-first-history';

import { Col, Container, Row } from 'ui/gridSystem';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_UPDATE_PRODUCTS_CART,
} from 'redux/actions/signup';

import { Button } from 'ui/atoms';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  LandingPageInterface,
  ProductVariationInterface,
} from 'interface/signup';
import ProductSubscription from 'ui/components/ProductSubscription';
import { Footer, HtmlRaw } from 'ui/components';
import { Head, HeadWrapper, Logo } from 'pages/LandingPage/style';
import { Close, CloseWrapper, IconBack } from '../Catalog/style';
import { ActionsWrapper, BackWrapper, Body, ButtonWrapper, SubTitle, Title, TitleContainer } from './style';

const PricedCatalog = ({
  updateCart,
  mediaIsPhone,
  mediaIsDesktop,
  mediaIsLaptop,
  landingProducts,
  products,
  step,
  setStepSubscriptions,
  domain,
  pushUrl,
  cta_subscribe_title,
}) => {
  const [mappedProds, setMappedProds] = useState([]);

  const updateVal = (num, pack) => {
    updateCart({ ...pack, num });
  };

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };

  useEffect(() => {
    const flatProduct = ({ variations, ...props }) =>
      variations.map(v => ({ ...props, ...v }));
    const allProducts = products.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        ...flatProduct(currentValue),
      ],
      []
    );
    const outArray = [];

    allProducts.forEach(product => {
      if (Array.isArray(product.cards)) {
        const ref = product.cards.find(
          c => c.card_type === 'card_product_variation_relation'
        );
        if (ref) {
          const father = allProducts.find(
            f => f.variation_id.toString() === ref.product_variation_ref
          );
          if (father) {
            const fatherIndex = outArray.findIndex(
              o =>
                o.product_id === father.product_id &&
                o.variation_id === father.variation_id
            );
            if (fatherIndex > -1) {
              outArray[fatherIndex].child_products = [
                ...(outArray[fatherIndex].child_products || []),
                product,
              ];
            } else {
              father.child_products = [product];
              outArray.push(father);
            }
          }
        } else {
          outArray.push(product);
        }
      } else {
        const fatherIndex = outArray.findIndex(
          o =>
            o.product_id === product.product_id &&
            o.variation_id === product.variation_id
        );
        if (!(fatherIndex > -1)) outArray.push(product);
      }
    });
    setMappedProds(outArray);
  }, [products]);

  useEffect(() => {
    if (step.num !== 1) {
      setStepSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={domain?.brand?.logo_white} />
            <CloseWrapper onClick={() => pushUrl(routes.landingPage.path)}>
              <Close className="icon-close-x" />
            </CloseWrapper>
          </Head>
        </Container>
      </HeadWrapper>
      <Body>
        <Container>
          <ActionsWrapper>
              <BackWrapper onClick={() => pushUrl(routes.catalog.path)}>
                <IconBack className="icon-arrow-right" />
                <ProDisplay type="link">Torna al catalogo</ProDisplay>
              </BackWrapper>
              <ButtonWrapper>
                <Button
                  name="iscriviti"
                  text={cta_subscribe_title || 'Iscriviti ora'}
                  onClick={() => window.location.href = 'https://www.digitallibrary.digited.it/'}
                  type="PRIMARY"
                  hasIcon
                />
              </ButtonWrapper>
          </ActionsWrapper>
            <TitleContainer>
              <Title>
                <ProDisplay
                  type="modalTitle"
                  configuration={
                    mediaIsPhone
                      ? { letterSpacing: 'normal' }
                      : { fontWeight: 'normal' }
                  }
                >
                  {landingProducts?.title}
                </ProDisplay>
              </Title>
              <SubTitle>
                <ProText type="description">
                  <HtmlRaw html={landingProducts?.subtitle} />
                </ProText>
              </SubTitle>
            </TitleContainer>
            <Row>
              {mappedProds.map(pack => (
                <ProductSubscription
                  key={pack.product_id}
                  product={pack}
                  onChangeQuantity={(num, product) => updateVal(num, product)}
                  isCatalog
                />
              ))}
            </Row>
        </Container>
      </Body>
      {mediaIsDesktop || mediaIsLaptop ? <Footer /> : null}
    </>
  );
};

PricedCatalog.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsDesktop: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,

  // HOC (connect, state)
  products: ProductVariationInterface,
  landingProducts: LandingPageInterface,
  step: PropTypes.object,
  domain: PropTypes.object,
  cta_subscribe_title: PropTypes.string,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  setStepSubscriptions: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { products, step } = state.signup;
    const { data: { cta_subscribe_title } = {}, landingProducts } = state.landingPage;
    return {
      landingProducts,
      products,
      step,
      domain: state.domain,
      cta_subscribe_title,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    updateCart: product =>
      dispatch({ type: SIGNUP_UPDATE_PRODUCTS_CART, product }),
    setStepSubscriptions: () =>
      dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 }),
  })
)(withMediaQueries(PricedCatalog));
