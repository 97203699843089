import {
  getPlaylistMock,
  getPlaylistsMock,
  getCourseMock,
  shouldUseMock,
  getRelatedMock,
  addSurveyMock,
  getQuestionsSurveyMock,
  addReviewMock,
  setRecommendedMock,
  getKeepWatchingMock,
  getPackagesMock,
  getPackageMock,
  getEventsMock,
  getEventMock,
  getSelectionsMock,
  getCollectionsMock,
  getRecommendedCollectionsMock,
  categoriesPillsMock,
  categoryPlaylistMock
} from './mock';
import {
  apiCall,
  baseUrl,
  extendUrl,
  catalogPrefix,
  assetPrefix
} from './config';

export default {
  pathSelection: userId => {
    if (shouldUseMock()) {
      return getPlaylistsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}pathselection?userId=${userId}`,
      'GET'
    );
  },
  getPlaylist: ({ id, userId, locked }) => {
    if (shouldUseMock()) {
      return getPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail?itemId=${id}&locked=${locked}&htmlEnabled=true`,
      'GET'
    );
  },
  getCourse: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getCourseMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail/course?itemId=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getRelated: ({ userId, needId, itemId }) => {
    if (shouldUseMock()) {
      return getRelatedMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}relatedlos?needId=${needId}&excludeLo=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getQuestions: surveyId => {
    if (shouldUseMock()) {
      return getQuestionsSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}getsurvey/${surveyId}?htmlEnabled=true`,
      'GET'
    );
  },
  addSurvey: (playlistId, surveyId, body) => {
    if (shouldUseMock()) {
      return addSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}addsurvey/${playlistId}/${surveyId}`,
      'POST',
      body
    );
  },
  addReview: body => {
    if (shouldUseMock()) {
      return addReviewMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}review`,
      'POST',
      body
    );
  },
  setRecommended: body => {
    if (shouldUseMock()) {
      return setRecommendedMock();
    }
    return apiCall(
      `${assetPrefix}${baseUrl}${extendUrl}reccomendation`,
      'POST',
      body
    );
  },
  getKeepWatching: ({ userId }) => {
    if (shouldUseMock()) {
      return getKeepWatchingMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}lo/getallinprogress?htmlEnabled=true`,
      'GET'
    );
  },
  getPackages: ({ userId }) => {
    if (shouldUseMock()) {
      return getPackagesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages`,
      'GET'
    );
  },
  getPackage: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getPackageMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages/${itemId}`,
      'GET'
    );
  },
  getEventsByDate: ({ userId, startDate, endDate }) => {
    if (shouldUseMock()) {
      return getEventsMock({ startDate, endDate });
    }
    return apiCall(
      /* date format: YYYY-MM-DD */
      `${catalogPrefix}${baseUrl}${extendUrl}live_event/planned?start_date=${startDate}&end_date=${endDate}&htmlEnabled=true`,
      'GET'
    );
  },
  getEvent: ({ userId, itemId }) => {
    if (shouldUseMock()) {
      return getEventMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}live_event?itemId=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getSelections: ({ appName, userId }) => {
    if (shouldUseMock()) {
      return getSelectionsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_selections`,//&app_name=${appName}`,
      'GET'
    );
  },
  getCollections: ({ appName, crossContent, userId }) => {
    if (shouldUseMock()) {
      return getCollectionsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_collections?cross_content=${crossContent}`,//&app_name=${appName}`,
      'GET'
    );
  },
  getRecommendedCollections: ({ userId }) => {
    if (shouldUseMock()) {
      return getRecommendedCollectionsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}?userId=${userId}`,
      'GET'
    );
  },
  getCategoriesPills: () => {
    if (shouldUseMock()) {
      return categoriesPillsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}needs`,
      'GET'
    );
  },
  getCategoryPlaylist: ({ userId, filterId }) => {
    if (shouldUseMock()) {
      return categoryPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/learning?filterBy=NEED&filterValue=${filterId}`,
      'GET'
    );
  },
};
