import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';

import { MODAL_CLOSE } from 'redux/actions';
import { Button, Checkbox, RadioButton } from 'ui/atoms';
import { AGREEMENTS_UPDATE, USERTYPE_UPDATE } from 'redux/actions/signup';
import { AgreementsContentsInterface } from 'interface/signup';
import HtmlRaw from 'ui/components/HtmlRaw';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { Icon } from '../ModalResetPassword/style';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  ContentWrapper,
  Title,
  Text,
  CheckboxDescription,
  CheckboxWrapper,
  Radio,
  RadioDescription,
  ButtonWrapper,
  ChooseType
} from './style';

const modalId = 'AgreementsCheckModal';

const AgreementsCheckModal = ({
  conditions,
  onClose,
  agreements,
  updateAgreements,
  validator,
  updateUserType,
  userType,
  firstCheck,
  sendDataTreatment,
  updateConsents
}) => {
  const [form, setForm] = useState({});
  const [tempForm, setTempForm] = useState({});

  useEffect(() => {
    setTempForm({});
  }, [userType]);

  const isValid = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      Object.keys(agreements.user_clauses).forEach(k => {
        if (conditions.consents && conditions.consents?.find(c => c.id === k)) {
          if (tempForm[k]) {
            valid = valid && tempForm[k].valid;
          } else if (agreements.user_clauses[k].mandatory) {
            valid = valid && agreements.user_clauses[k].valid;
          }
        }
      });
    }
    return valid;
  };

  const handleOnChange = (newValue, clause) => {
    const { id, type, mandatory = '', code } = clause;
    let control;

    const val = newValue;

    switch (type) {
      case 'checkbox':
        control = {
          ...tempForm[id],
          authorize: val,
          type: code,
          mandatory: mandatory === 'True',
          valid: mandatory === 'True' ? val : true
        };

        break;
      case 'radio':
        control = {
          ...tempForm[id],
          authorize: Boolean(Number(val)),
          type: code,
          mandatory: mandatory === 'True',
          valid: mandatory === 'True' ? Boolean(Number(val)) : true
        };
        break;
      default:
    }
    setTempForm({ ...tempForm, [id]: control });
  };

  useEffect(() => {
    if (validator && Object.keys(form).length) updateAgreements(form, updateConsents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const updateAndClose = () => {
    setForm(tempForm);
    onClose();
  };

  const getClauseByType = clause => {
    const { id, description, type } = clause;
    const agreementValue = tempForm[id]
      ? tempForm[id]?.authorize
      : agreements?.user_clauses[id]?.authorize || false;

    let clauseBody;

    switch (type) {
      case 'checkbox':
        clauseBody = (
          <CheckboxWrapper>
            <CheckboxDescription>
              <HtmlRaw html={description} />
            </CheckboxDescription>
            <Checkbox
              value={agreementValue}
              checked={agreementValue}
              label="ho letto attentamente il contenuto"
              onChange={value => handleOnChange(value, clause)}
            />
          </CheckboxWrapper>
        );
        break;
      case 'radio':
        clauseBody = (
          <Radio key={`personal-data-${id}`}>
            <RadioDescription>
              <HtmlRaw html={description} />
            </RadioDescription>
            <RadioButton
              label="presto il consenso"
              value={1}
              name={`personal-data-${id}`}
              checked={agreementValue}
              onChange={value => {
                handleOnChange(value, clause);
              }}
            />
            <RadioButton
              label="nego il consenso"
              value={0}
              name={`personal-data-${id}`}
              checked={!agreementValue}
              onChange={value => {
                handleOnChange(value, clause);
              }}
            />
          </Radio>
        );
        break;
      default:
    }
    if (!firstCheck || !clause.user_type || clause.user_type === userType)
      return <React.Fragment key={id}>{clauseBody}</React.Fragment>;
    return null;
  };

  const filterByType = elm => {
    if (!userType) return true;

    return elm.user_type === userType || elm.user_type === '';
  };

  return (
    <Modal id={modalId} onClose={updateAndClose}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            onMouseDown={stopEvent}
            onClick={stopEvent}
            width="860px"
            height="600px"
            widthtablet={600}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={updateAndClose} />
              {/*{!sendDataTreatment && conditions.isConsents && firstCheck && (*/}
              {/*  <ChooseType>*/}
              {/*    <ProText*/}
              {/*      type="title"*/}
              {/*      configuration={{ color: 'primary', colorType: 'base' }}*/}
              {/*    >*/}
              {/*      Seleziona la tipologia di azienda:*/}
              {/*    </ProText>*/}
              {/*    <Radio className="inline">*/}
              {/*      <RadioButton*/}
              {/*        label="Persona giuridica"*/}
              {/*        value="persona_giuridica"*/}
              {/*        name="userType"*/}
              {/*        checked={userType === 'persona_giuridica'}*/}
              {/*        onChange={val => {*/}
              {/*          updateUserType(val);*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <RadioButton*/}
              {/*        label="Ditta individuale"*/}
              {/*        value="ditta_individuale"*/}
              {/*        name="userType"*/}
              {/*        checked={userType === 'ditta_individuale'}*/}
              {/*        onChange={val => {*/}
              {/*          updateUserType(val);*/}
              {/*        }}*/}
              {/*      />*/}
              {/*    </Radio>*/}
              {/*  </ChooseType>*/}
              {/*)}*/}
              <Title>
                <ProDisplay
                  type="modalTitle"
                  configuration={{ color: 'black' }}
                >
                  {conditions?.title}
                </ProDisplay>
              </Title>
              <Text>
                {(conditions?.consents || [])
                  .filter(filterByType)
                  .map(clause => getClauseByType(clause))}
                <ButtonWrapper>
                  <Button
                    text="Conferma"
                    type={Button.TYPE.SECONDARY}
                    disabled={
                      !isValid() ||
                      (conditions.isConsents && firstCheck && !userType)
                    }
                    onClick={updateAndClose}
                  />
                </ButtonWrapper>
              </Text>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

AgreementsCheckModal.propTypes = {
  conditions: PropTypes.object,
  firstCheck: PropTypes.bool,
  sendDataTreatment: PropTypes.bool,
  updateConsents: PropTypes.bool,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  updateAgreements: PropTypes.func.isRequired,
  validator: PropTypes.func,
  updateUserType: PropTypes.func,

  // HOC (connect, state)
  agreements: AgreementsContentsInterface,
  userType: PropTypes.string
};

AgreementsCheckModal.id = modalId;

export default connect(
  state => {
    const { agreements } = state.signup;
    const {
      user: { sendDataTreatment }
    } = state.authentication;
    return {
      agreements,
      userType: agreements.userType,
      sendDataTreatment
    };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    updateAgreements: (agreements, updateConsents) =>
      dispatch({ type: AGREEMENTS_UPDATE, agreements, privacyData: updateConsents }),
    updateUserType: userType => dispatch({ type: USERTYPE_UPDATE, userType })
  })
)(AgreementsCheckModal);
