import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import { getToken, StorageName } from 'utils/token';

import navbarMenu from 'utils/navbar-menu';
import { MenuButton, SearchBar } from 'ui/atoms';

import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import routes, { listPaths } from 'routes';
import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';
import { MODES } from 'const';
import { SIDEBAR_CLOSE, SIDEBAR_OPEN } from 'redux/actions';
import ProText from 'ui/typography/proText';
import { isManager, isSimpleUser } from 'utils/roles';
import { ProfilePicture } from 'ui/components';
import { detailType } from 'utils/common';
import { GET_PACKAGES, GET_PATH, GET_PLAYLIST, SET_PLAYLIST_HISTORY } from 'redux/actions/playlist';
import { useLocation } from 'react-router';
import { apiHost } from 'api/config.js';
import {
  NavBarContainer,
  LogoContainer,
  MenuWrapper,
  HeaderContainer,
  LinkMenu,
  IconWrapper,
  IconContainer,
  Icon,
  Links,
  SwitchButton,
  IconMenu,
  IconBookmark,
  ProPicWrapper,
  IconArrow,
  IconSend,
  Domains,
  DomainWrapper,
  DomainsContainer,
  SearchContainer,
  IconSearch,
  ResultSearch,
  ResultOption,
  SearchLoader
} from './style';

const NavBar = ({
   // HOC (connect, state)
  brand = '',
  domains,
  domainId,
  email,
  isEditSubscriptions,
  menuToggleOpen,
  opened,
  packages,
  playlists,
  profile,
  router,
  showNavbar,
  sidebarId,
  // HOC (connect, dispatch)
  cleanPlaylist,
  getPackages,
  getPath,
  logout,
  onClose,
  openInviteFriendModal,
  openSidebar,
  pushUrl,
  setPlaylistHistory,
  // HOC media queries
  mediaIsDesktop,
  mediaIsLargeDesktop,
  mediaIsLaptop,
}) => {
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const [showDomains, setShowDomains] = useState(false);
  const [search, setSearch] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchOptionsNumbers, setSearchOptionsNumbers] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchNoResult, setSearchNoResult] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [mode, setMode] = useState();
  const location = useLocation();
  const mediaIsLargeFormat = mediaIsLargeDesktop | mediaIsDesktop | mediaIsLaptop;

  useEffect(() => {
    playlists.length || getPath();
    packages.length || getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPath])

  useEffect(() => {
    if (listPaths.manager.find(path => location.pathname.indexOf(path) > -1)) {
      setMode(MODES.MANAGER);
    }
    if (listPaths.user.find(path => location.pathname.indexOf(path) > -1)) {
      setMode(MODES.USER);
    }
    setActiveMenu(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sendSearch = (search = null) => {
  setSearchNoResult(false)
    if (search) {

      const xhr = new XMLHttpRequest();
      //xhr.open('GET', 'http://localhost:3001/search');
      xhr.open('GET', `${apiHost}/search/api/v1/public/catalog?news=true&userId=` + profile.userId + '&search=' + search + '&items_per_page=5');
      setSearchLoading(true);
      xhr.setRequestHeader('Authorization', getToken().token);
      xhr.setRequestHeader('Domain', domainId);
      xhr.onload = function() {
        if (xhr.status === 200) {
          const results = JSON.parse(xhr.responseText);
          setSearchOptionsNumbers(results.catalog.length);
          setSearchOptions(results.catalog);
          setSearchLoading(false);
          setHasNextPage(results.pageInfo.hasNextPage);
          if(results.catalog.length === 0 ){
          setSearchNoResult(true)
          }
        }
      };
      xhr.send();

      /*const playlistResults = playlists
        .filter(p => p.title && p.title.toLowerCase().includes(search.toLowerCase()))
        .map(p => ({ id: p.itemId, title: p.title, type: 'playlist' }));

      const packagesResults = packages
        .filter(p => p.title && p.title.toLowerCase().includes(search.toLowerCase()))
        .map(p => ({ id: p.packageId, title: p.title, type: 'package' }));

      setSearchOptions(
        [...playlistResults]
          .sort(p => p.title)
      );*/
    }
    }

  const changeLocation = (url, closeSearch = false) => {
    if(closeSearch){
    setSearch('');
    setSearchOptions([]);
    setSearchOptionsNumbers(0);
    }
    if (!isEditSubscriptions) {
      pushUrl(url);
      setActiveMenu(url);
    }
  };

  const redirectHomepage = () => {
    if (mode === MODES.MANAGER) {
      changeLocation(routes.mySubscription.path);
    } else {
      changeLocation(routes.homepage.path);
    }
  };

  const switchButtonHandler = () => {
    if (mode === MODES.MANAGER) {
      pushUrl(routes.homepage.path);
    } else {
      pushUrl(routes.mySubscription.path);
      onClose();
    }
  };

  const manageSidebar = newSidebarId => {
    if (opened && sidebarId === newSidebarId) onClose();
    else openSidebar(newSidebarId);
  };

  const sendMailTo = () => {
    window.location.href = `mailto:${email}`;
    // window.open(`mailto:${email}`, '_blank');
  };

  const toggleDomains = () => {
    setShowDomains(!showDomains);
  };

  const getItemType = ( type) => {
          switch(type.toLowerCase()) {
            case 'news':
              return 'NEWS';
              break;
            case 'training':
              return 'TRAINING';
              break;
            case 'lo':
            case 'learning_object':
              return 'LEARNING OBJECT';
              break;
            case 'collection':
            case 'selection': {
              return 'PLAYLIST';
              break;
            }
            case 'live_event':
              return 'EVENTO LIVE';
              break;
            default:
          }
        };

  const handleClickSearch = ( item, type) => {
    setSearch('');
    cleanPlaylist();
    switch(type.toLowerCase()) {
      case 'news':
        pushUrl(`${routes.detail.path}/${detailType.NEWS}/${item.itemId}`);
        break;
      case 'training':
      case 'lo':
      case 'learning_object':
        pushUrl(`${routes.detail.path}/${detailType.COURSE}/${item.itemId}`);
        break;
      case 'collection':
      case 'selection': {
        const itemId = item.id ? item.id : item.itemId;
        if (item.locked) {
          pushUrl(`${routes.lockedDetails.path}/${itemId}`);
        } else {
          pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${itemId}`);
        }
        break;
      }
      case 'live_event': {

          const itemId = item.id ? item.id : item.itemId;
          const productId= item.productItemId;
          if (item.locked) {
            pushUrl(`${routes.eventLockedDetail.path}/${itemId}/${productId}`)
          } else {
            pushUrl(`${routes.event.path}/${itemId}`);
          }
        break;
        }
      default:
    }
  };

  const handleLogout = () => {
    logout(routes.landingPage.path);
  };


  useEffect(() => {
    const getData = setTimeout(() => {
    if(search){
      sendSearch(search);
    }

    }, 1000)
    return () => clearTimeout(getData)
  }, [search])

  const firstMainContainer = document.querySelectorAll("[class*=MainContainer]");
  firstMainContainer.forEach(el => {
  
    if(opened) {
      return el?.classList?.add('fixed');
    } else {
      return el?.classList?.remove('fixed');
    }
  })

  const isPreliosAppIOS = navigator.userAgent.includes('DigitedGuscio_iOS');
  const isPreliosAppAndroid = navigator.userAgent.includes('DigitedGuscio_Android');


  if (!showNavbar || !mode) return null;
  return (
    <NavBarContainer className={menuToggleOpen ? 'open' : ''}>
      <Container className="container">
        <HeaderContainer isUser={mode === MODES.USER}>
          <LogoContainer onClick={redirectHomepage} img={brand.logo_white} />
          {domains && domains.length > 1 && (
            <IconArrow
              className={showDomains ? 'icon-btn-arrow open' : 'icon-btn-arrow'}
              onClick={toggleDomains}
              open={showDomains}
            />
          )}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {showDomains && (
              <DomainsContainer onClick={toggleDomains}>
                <Container>
                  <Domains onClick={e => e.stopPropagation()}>
                    {domains &&
                      domains.map(d => (
                        <DomainWrapper
                          key={`domain-${d.url}`}
                          logo={d.brand.logo}
                          onClick={() => {
                            window.location.href = d.url;
                          }}
                        />
                      ))}
                  </Domains>
                </Container>
              </DomainsContainer>
            )}
            {mode === MODES.MANAGER && (
              <MenuWrapper
                userMode={mode === MODES.USER}
                isUser={isSimpleUser(profile.clientEnabled)}
                isManager={isManager(profile.clientEnabled)}
                className={menuToggleOpen ? 'open' : ''}
              >
                {isManager(profile.clientEnabled) &&
                  isSimpleUser(profile.clientEnabled) &&
                  !mediaIsLargeFormat && (
                    <SwitchButton onClick={switchButtonHandler}>
                      <ProText
                        type="courseTitle"
                        configuration={{ color: 'black' }}
                      >
                        Vai a corsi
                      </ProText>
                    </SwitchButton>
                  )}
                <Links>
                  {(navbarMenu || []).map((menu = {}) => (
                    <LinkMenu
                      key={menu.label}
                      onClick={() => changeLocation(menu.link)}
                      className={`${!activeMenu.indexOf(menu.link) &&
                        'active'} ${isEditSubscriptions && 'disabled'}`}
                      stringLength={menu.label.length}
                    >
                      <ProDisplay
                        type="label"
                        configuration={
                          activeMenu.indexOf(menu.link)
                            ? { fontWeight: 400 }
                            : { fontWeight: 500 }
                        }
                      >
                        {menu.label}
                      </ProDisplay>
                    </LinkMenu>
                  ))}
                  <LinkMenu
                    key="Supporto"
                    onClick={sendMailTo}
                    className={`${isEditSubscriptions && 'disabled'}`}
                    stringLength={'Supporto'.length}
                  >
                    <ProDisplay type="label">Supporto</ProDisplay>
                  </LinkMenu>
                </Links>
                <IconWrapper>
                  <IconContainer onClick={handleLogout}>
                    <Icon className="icon-exit" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay
                        type="label"
                        configuration={{
                          fontWeight: 500,
                          color: 'primary',
                          colorType: 'base'
                        }}
                      >
                        Logout
                      </ProDisplay>
                    )}
                  </IconContainer>
                </IconWrapper>
              </MenuWrapper>
            )}
            {mode === MODES.USER && (
              <MenuWrapper
                userMode={mode === MODES.USER}
                isUser={isSimpleUser(profile.clientEnabled)}
                isManager={isManager(profile.clientEnabled)}
                className={menuToggleOpen ? 'open' : ''}
              >
                <SearchContainer>
                  <SearchBar
                    placeholder="cerca contenuto"
                    onChange={value => {
                    setSearchOptions([]);
                    setSearchOptionsNumbers(0);
                    setSearchNoResult(false);
                    setSearch(value)}}
                    value={search}
                    disabled={searchLoading}
                    enterKeyDisabled={true}
                    hideIcon
                    hidden
                  />
                  {searchLoading ?
                    <SearchLoader>
                    <div className="loader">Loading...</div>
                    </SearchLoader>

                   : <IconSearch className="icon-ricerca"/>}


                  <ResultSearch>
                    {search &&
                      searchOptions.map(el => (
                        <ResultOption
                          key={el.itemId}
                          onClick={() => handleClickSearch(el, el.itemType)}
                        >
                          <ProText type="link">{el.title} <span>{getItemType(el.itemType)}</span></ProText>
                        </ResultOption>
                      ))}
                      {(search && searchOptionsNumbers > 0 && hasNextPage) ?
                        <ResultOption
                          onClick={() => changeLocation(routes.search.path + '?string=' + search, true)}
                        >
                          <ProText className="viewAll" type="link">Vedi tutti i risultati di ricerca</ProText>
                        </ResultOption>
                      : null}
                      {(search && searchNoResult ) ?
                        <ResultOption>
                          <ProText type="link">Nessun risultato trovato</ProText>
                        </ResultOption>
                      : null}
                  </ResultSearch>
                </SearchContainer>
                <Links>
                  {/*<LinkMenu onClick={() => changeLocation(routes.commentsBoard.path)}>
                    <IconMenu className="icon-commenti margin" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay type="menuLabel">BACHECA</ProDisplay>
                    )}
                    </LinkMenu>*/}
                  <LinkMenu>
                    <IconSend href={`mailto:${email}`} target="_blank" className="icon-send">
                    {!mediaIsLargeFormat && (
                      <ProDisplay type="menuLabel">SUPPORTO</ProDisplay>
                    )}
                    </IconSend>
                  </LinkMenu>

                  <LinkMenu onClick={() => manageSidebar('Bookmark')}>
                    <IconBookmark className="icon-icona-salva-active" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay type="menuLabel">BOOKMARK</ProDisplay>
                    )}
                  </LinkMenu>

                  <LinkMenu onClick={() => changeLocation(routes.notes.path)}>
                    <IconMenu className="icon-note margin" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay type="menuLabel">NOTE</ProDisplay>
                    )}
                  </LinkMenu>
                  <ProPicWrapper
                    onClick={() => changeLocation(routes.userProfile.path)}
                  >
                    <ProfilePicture
                      profile={profile}
                      location={mediaIsLargeFormat && 'navbar'}
                      noAddPic={mediaIsLargeFormat}
                      heightContainer="35px"
                      widthContainer="35px"
                      pictureChange={false}
                      fontSizeInitials={16}
                    />
                    {!mediaIsLargeFormat && (
                      <ProDisplay type="userName">
                        {profile.firstname} {profile.lastname}
                      </ProDisplay>
                    )}
                  </ProPicWrapper>
                </Links>
                {isManager(profile.clientEnabled) &&
                  isSimpleUser(profile.clientEnabled) &&
                  !mediaIsLargeFormat && (
                    <SwitchButton
                      onClick={switchButtonHandler}
                      className="fixed-height"
                    >
                      <IconMenu className="icon-manager" />
                      <ProDisplay type="menuLabel">AMMINISTRAZIONE</ProDisplay>
                    </SwitchButton>
                  )}
                <IconWrapper>
                  {/* <IconContainer
                    className="user"
                    onClick={() => manageSidebar('Notification')}
                  >
                    <Icon className="icon-notifica" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay
                        type="label"
                        configuration={{
                          fontWeight: 500,
                          color: 'primary',
                          colorType: 'base'
                        }}
                      >
                        NOTIFICHE
                      </ProDisplay>
                    )}
                  </IconContainer> */}
                  <IconContainer className="user" onClick={handleLogout}>
                    <Icon className="icon-exit" />
                    {!mediaIsLargeFormat && (
                      <ProDisplay
                        type="label"
                        configuration={{
                          fontWeight: 500,
                          color: 'primary',
                          colorType: 'base'
                        }}
                      >
                        LOGOUT
                      </ProDisplay>
                    )}
                  </IconContainer>
                </IconWrapper>
              </MenuWrapper>
            )}

            {isManager(profile.clientEnabled) &&
              isSimpleUser(profile.clientEnabled) &&
              mediaIsLargeFormat && (
                <SwitchButton onClick={switchButtonHandler}>
                  <ProText type="courseTitle">
                    {mode === MODES.MANAGER ? 'Vai a corsi' : 'Amministrazione'}
                  </ProText>
                </SwitchButton>
              )}
            <MenuButton />
          </div>
        </HeaderContainer>
      </Container>
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  brand: PropTypes.object,
  domains: PropTypes.array,
  email: PropTypes.string,
  isEditSubscriptions: PropTypes.bool.isRequired,
  menuToggleOpen: PropTypes.bool.isRequired,
  opened: PropTypes.bool,
  packages: PropTypes.array,
  playlists: PropTypes.array,
  profile: PropTypes.object,
  router: PropTypes.object,
  showNavbar: PropTypes.bool.isRequired,
  sidebarId: PropTypes.string,
  // HOC (connect, dispatch)
  cleanPlaylist: PropTypes.func,
  getPackages: PropTypes.func,
  getPath: PropTypes.func,
  logout: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  // openInviteFriendModal: PropTypes.func,
  openSidebar: PropTypes.func,
  pushUrl: PropTypes.func.isRequired,
  setPlaylistHistory: PropTypes.func,
  // HOC media queries
  mediaIsDesktop: PropTypes.bool.isRequired,
  mediaIsLaptop: PropTypes.bool.isRequired,
  mediaIsLargeDesktop: PropTypes.bool.isRequired,
};

export default withMediaQueries(
  connect(
    state => {
      const {
        editSubscriptions: isEditSubscriptions,
        menuToggleOpen,
        showNavBar: showNavbar
      } = state.app;
      const {
        collection: { items: collections },
        selection: { items: selections }
      } = state.playlist.playlists.yourpathprogress;
      const { packageList } = state.playlist.packages;

      const { user: profile = {} } = state.authentication;
      const { domainList: domains } = state.authentication.user;
      const { open: opened, id: sidebarId } = state.sidebar;
      const { brand, email, id: domainId } = state.domain;
      const { router } = state;

      return {
        brand,
        domains,
        email,
        domainId,
        isEditSubscriptions,
        menuToggleOpen,
        opened,
        packages: packageList,
        playlists: [...collections, ...selections],
        profile,
        showNavbar,
        sidebarId,
        router,
      };
    },
    dispatch => ({
      cleanPlaylist: () => dispatch({ type: GET_PLAYLIST._SUCCESS, data: {} }),
      getPackages: () =>  dispatch({ type: GET_PACKAGES._REQUEST }),
      getPath: () => dispatch({ type: GET_PATH._REQUEST, forceCall: true }),
      logout: goTo => dispatch({ type: AUTHENTICATION_SIGNOUT._REQUEST, goTo }),
      onClose: () => dispatch({ type: SIDEBAR_CLOSE }),
      // openInviteFriendModal: () => dispatch({ type: MODAL_OPEN, id: '../InviteFriendModal' }),
      openSidebar: id => dispatch({ type: SIDEBAR_OPEN, id }),
      pushUrl: url => dispatch(push(url)),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source }),
    })
  )(NavBar)
);
