import styled from 'styled-components/macro';
import { BackWrapper } from '../Catalog/style';

const SubTitle = styled.div`
  color: ${props => props.theme.colors.mineShaft};
`;

const Description = styled.div``;

const Label = styled.label``;

const Price = styled.div``;

const Span = styled.span``;

const Text = styled.label`
  margin-bottom: 5px;
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.primary.base};
`;

const Icon = styled.span`
  font-size: 15px;
  color: ${props => props.theme.colors.lightGreen};
  margin-right: 8px;
  margin-top: 2px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackWrapperExt = styled(BackWrapper)`
  align-self: start;

  @media ${props => props.theme.device.tablet} {
    align-self: center;
  }
`;

const ButtonWrapper = styled.div`
  min-width: 180px;
  max-width: 100%;

 
  
  
`;

const Body = styled.div`
  padding: 20px 0;
  min-height: calc(100vh - 70px - 126px);
  height: fit-content;

  @media ${props => props.theme.device.tablet} {
    min-height: calc(100vh - 70px);
    justify-content: center;
  }
  @media ${props => props.theme.device.mobile} {
    align-items: unset;
  }
`;

const Link = styled.div`
  color: ${props => props.theme.colors.primary.base};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 0 20px auto;

  &:hover {
    color: ${props => props.theme.colors.primary.darker};
  }
`;

export {
  Description,
  Price,
  Span,
  Label,
  Text,
  TitleContainer,
  Title,
  SubTitle,
  Icon,
  Body,
  Link,
  ActionsWrapper,
  ButtonWrapper,
  BackWrapperExt as BackWrapper,
};
