import styled from 'styled-components/macro';

export const SignupContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 93px - 87px);
  overflow: auto;
  position: relative;
  z-index: 0;
`;

export const FlowHeaderWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.base};
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
`;

export const FlowHeader = styled.div`
  background-color: ${props => props.theme.colors.primary.base};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 35px;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  width: auto;
  min-width: 200px;
  max-width: 260px;
`;
